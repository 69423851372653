<template>
	<div v-show="visible" class="popup-page">
		<div class="popup-wrapper" ref="card">
			<div class="title">
				{{ $t('invitation.awardRecord') }}	
			</div>
			<div v-if="invitedList.length > 0" class="invited-list-content-header">
				<div class="invited-list-content-header__item user-name">{{ $t('invitation.invitedUser') }}</div>
				<div class="invited-list-content-header__item">{{ $t('invitation.awardType') }}</div>
				<div class="invited-list-content-header__item">{{ $t('invitation.awardCount') }}</div>
				<div class="invited-list-content-header__item">{{ $t('invitation.awardTime') }}</div>
			</div>
			<div v-if="invitedList.length > 0" class="invited-list" ref="listView">
				<div class="list-wrap" ref="listWrap">
					<div 
						class="invited-list-content-value"  
						v-for="(item, index) in invitedList" 
						:key="item.user_id + index"
					>
						<div class="invited-list-content-value__item user-name">{{ item.invited_user }}</div>
						<div class="invited-list-content-value__item">{{ item.reward_type }}</div>
						<div class="invited-list-content-value__item" v-if="item.reward_days == 0">-</div>
						<div class="invited-list-content-value__item" v-else>{{ item.reward_days }}{{ $t('invitation.day') }}</div>
						<div class="invited-list-content-value__item">{{ item.timeString }}</div>
					</div>
				</div>
			</div>
			<div v-if="invitedList.length <= 0" class="empty-wrap">
				<div class="empty-box"></div>
				<div class="empty-title">{{ $t('invitation.noSuccessInvited') }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { isIOS } from '../../utils/util';

export default {
	name: 'InvitedList',
	props: {
		invitedList: {
			type: Array,
			default: () => ([]),
		}
	},
	data() {
		return {
			visible: false,
			requestListCount: 1,
		}
	},
	watch: {
		visible(val) {
			if (val && isIOS) {
				document.body.style.position = 'fixed';
				document.body.style.top = 0;
				document.body.style.left = 0;
				document.body.style.width = '100vw';
			} else {
				document.body.style.position = 'static';
			}
		}
	},
	computed: {
		invitedListCopy() {
			return this.invitedList;
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			const infoList = this.$refs['listView'];
			window.dom = infoList;
			infoList?.addEventListener('scroll', () => {
				const height = this.$refs['listWrap'].offsetHeight;
				const scrollTop = infoList.scrollTop;
				if (this.invitedList.length >= 30 && height - scrollTop < 400) {
					if (Math.ceil(this.invitedList.length / 30) < this.requestListCount) {
						return;
					}
					this.requestListCount++;
					this.$emit('requestList', this.invitedList[this.invitedList.length - 1].time);
				}
			});
		},
		show() {
			this.visible = true;
			this.$nextTick(() => {
				this.listenSlideToClose();
				this.$parent.$el.style.overflow = 'hidden';
			});
		},
		hide() {
			this.$parent.$el.style.overflow = 'scroll';
			this.visible = false;
		},
		listenSlideToClose() {
			try {
				const card = this.$refs['card'];
				const cardTop = card.getBoundingClientRect().top;
				let touchStart = cardTop;	
				const handleStart = (e) => {
					const pointY = e.touches[0].pageY;
					if (pointY - (screen.height - card.getBoundingClientRect().height) > 5) {
						card.removeEventListener('touchmove', handleMove);
						card.removeEventListener('touchend', handleEnd);
						return;
					} else {
						card.addEventListener('touchmove', handleMove);
						card.addEventListener('touchend', handleEnd);
					}
					if (touchStart === 0) {
						touchStart = card.getBoundingClientRect().top;
					}
				};
				const handleMove = (e) => {
					const { touches } = e;
					const nowY = touches[0].pageY;
					const distance = nowY - touchStart;
					const newTop = Math.max(cardTop, cardTop + distance);
					card.style.transition = null;
					card.style.top = `${newTop}px`;
					e.stopPropagation();
				};
				const handleEnd = () => {
					const rect = card.getBoundingClientRect();
					if (rect.top - touchStart <= rect.height / 2) {
						card.style.top = `${touchStart}px`;
						card.style.transition = 'top 0.3s cubic-bezier(0.680, -0.030, 0.000, 1.650)';
					} else {
						card.style.transition = 'top 0.3s ease-in';
						card.style.top = `${screen.height}px`;
						let timer = setTimeout(() => {
							this.hide();
							card.style.top = `${cardTop}px`;
							clearTimeout(timer);
							timer = null;
						}, 500);
					}
					touchStart = 0;
				};
				// 触摸屏幕时触发
				card.addEventListener('touchstart', handleStart);
				// 在屏幕上滑动时触发
				card.addEventListener('touchmove', handleMove);
				// 离开屏幕时触发
				card.addEventListener('touchend', handleEnd);
			} catch(err) {
				console.error('[listenSlideToClose:]', err);
			}
		}
	}
}
</script>

<style lang="less">
.popup-page {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 10;
	background: rgba(0, 0, 0, 0.7);
	user-select: none;
}
.popup-wrapper {
	position: absolute;
	width: 100vw;
	max-width: 1200px;
	height: 972px;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	background: #fff;
	border-radius: 48px 48px 0 0;
	font-family: PingFang SC;
	font-style: normal;
	font-weight: normal;
	font-size: 51px;
	line-height: 72px;
	text-align: center;
	box-sizing: border-box;
	padding: 120px 48px 72px 48px;

	&::before {
		content: '';
		position: absolute;
		width: 120px;
		height: 12px;
		background: #A8B2BD;
		border-radius: 13.5px;
		top: 36px;
		left: 50%;
		transform: translateX(-50%);
	}

	.invited-list-content-header {
		width: 100%;
		display: flex;
		justify-content: space-between;
		font-weight: 600;
		font-size: 39px;
		line-height: 45px;
		margin-top: 51px;
		padding-bottom: 30px;

		&__item {
			flex: 1;
			flex-shrink: 0;
		}	
	}

	.invited-list {
		width: 100%;
		height: 700px;
		overflow-x: hidden;
		overflow-y: scroll;
		font-size: 39px;
		line-height: 45px;
		color: #98A2AD;
		padding-bottom: 40px;
		box-sizing: border-box;

		&-content-value {
			display: flex;
			justify-content: space-between;

			&__item {
				flex: 1;
				flex-shrink: 0;
				margin: 36px 0;
				word-break: break-all;
			}
		}
	}

	.empty-wrap {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-content: center;
		margin-top: 115px;
		
		.empty-box {
			width: 450px;
			height: 345px;
			background: url('../../assets/images/invitation/empty-list.png') no-repeat center/cover;
		}

		.empty-title {
			font-size: 45px;
			color: #C8CFDD;
			text-align: center;
		}
	}
}
</style>