<template>
	<div class="invite-pipeline">
		<div 
			v-for="item, index in pipelineStages"
			class="invite-item" 
			:class="{ 'middle-item': index === 1 }"
			:key="index + item.iconName"
		>
			<div class="icon" :class="[item.iconName]"></div>
			<div class="item-title">{{ item.title }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "InvitePipeline",
	props: ['pipelineStages'],
}
</script>

<style lang="less" scoped>
.invite-pipeline {
	display: flex;
	justify-content: space-between;

	.invite-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 264px;
		min-width: 264px;
		font-size: 33px;
		color: #000;

		.icon {
			width: 180px;
			height: 180px;
		}

		.item-title {
			margin-top: 24px;
			text-align: center;
		}
	}

	.middle-item {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			width: 79px;
			height: 12px;
			top: 83px;
			left: -65px;
			background: url('../../assets/images/invitation/right-arrow.png') no-repeat center/cover;
		}

		&::after {
			content: '';
			position: absolute;
			width: 78px;
			height: 12px;
			top: 83px;
			right: -65px;
			background: url('../../assets/images/invitation/right-arrow.png') no-repeat center/cover;
		}
	}

	.invite-link {
		background: url('../../assets/images/invitation/invite-link.png') no-repeat center/cover;
	}

	.invite-share {
		background: url('../../assets/images/invitation/invite-share.png') no-repeat center/cover;
	}

	.invite-pikpak {
		background: url('../../assets/images/invitation/invite-pikpak.png') no-repeat center/cover;
	}

	.invite-code {
		background: url('../../assets/images/invitation/invite-code.png') no-repeat center/cover;
	}

	.invite-success {
		background: url('../../assets/images/invitation/invite-success.png') no-repeat center/cover;
	}

}
</style>