<template>
	<div class="wrapper">
		<div class="enter-invite-code" @click="handleEnterInviteCodePane">
			<div class="icon-message"></div>
			<div class="text">{{ $t('invitation.sgZ1opIxw0KhKoJsSsXx1') }}</div>
		</div>
		<EnterInviteCode 
			ref="enterInviteCode" 
			:autoInputCode="autoInputCode" 
			:userInfo="userInfo" 
			:inviterCode="inviterCode" 
		/>
	</div>
</template>

<script>
import EnterInviteCode from './enter-invite-code.vue';

export default {
	name: "ExchangeInviteCode",
	components: {
		EnterInviteCode,
	},
	props: ['userInfo', 'inviterCode', 'code', 'invitedNum', 'autoInputCode'],
	methods: {
		// 输入兑换码
		async handleEnterInviteCodePane() {
			try {
				// 数据上报
				this.$stat("external_js", "spread_invite_page_click", {
					button: 'redeem_invitation_code',
					invited_num: this.invitedNum || 0,
				});
				this.$refs['enterInviteCode'].changeStepFlow(this.code ? 'traded' : 'enterCode');
				this.$refs['enterInviteCode'].show();
			} catch(err) {
				console.error('[handleEnterInviteCodePane:]', err);
			}
		},	
	}
}
</script>

<style lang="less" scoped>
.enter-invite-code {
	position: absolute;
	display: inline-flex;
	align-items: center;
	top: 700px;
	right: 0;
	max-width: 320px;
	min-height: 108px;
	border-radius: 500px 0 0 500px;
	background: rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(10px);
	overflow: hidden;
	cursor: pointer;

	.icon-message {
		flex-grow: 2;
		width: 72px;
		height: 72px;
		margin-left: 40px;
		background: url('../../assets/images/invitation/message-icon.png') no-repeat center/cover;
	}

	.text {
		flex-shrink: 2;
		font-size: 33px;
		color: #313131;
		padding: 10px 20px 10px 10px;
		text-align: center;
	}
}
</style>