<template>
	<div v-if="visible" class="popup">
		<canvas id="canvas"></canvas>
		<div class="describe">{{ $t("middleware.qrCode") }}</div>
	</div>
</template>

<script>
import QRCode from 'qrcode';
import { isPC, isIPad } from '../../utils/util';
export default {
	name: 'popup',
	data() {
		return {
			visible: isPC() && !isIPad,
		}
	},
	mounted() {
		isPC() && this.renderQrCode();
	},
	methods: {
		renderQrCode() {
      QRCode.toCanvas(
        document.getElementById("canvas"),
        location.href,
        function (error) {
          error && console.error(error);
        }
      );
    },
	}
}
</script>

<style lang="less" scoped>
.popup {
	position: fixed;
	width: 180PX;
	top: 50%;
	right: 20PX;
	transform: translateY(-50%);
	font-size: 16PX;
	background: #fff;
	padding-bottom: 10PX;
	box-sizing: border-box;
	border-radius: 5PX;
	box-shadow: #ccc 3px 3px 20px;

	#canvas {
		display: block;
		width: 180PX!important;	
		height: 180PX!important;
		margin: 0 auto;
		border-radius: 5PX;
	}

	.describe {
		width: 180PX;	
		text-align: center;
		padding: 0 10PX;
		box-sizing: border-box;
	}
}
</style>
