<template>
	<div class="subscribe-banner">
		<div class="subscribe-box">
			<img :src="subscribeThumbnail" alt="subscribe">
			<div class="title-box">
				<div class="icon-telegram"></div>
				<div class="subscribe-title">{{ subscribeChannelName }}</div>
			</div>
		</div>
		<div class="saving-tips">
			<div>{{ $t('middleware.dzoKFoFFoi9vPqzRKcpj8') }}</div>
			<div>{{ $t('middleware.cuCfaTt2kCa6pWzCbkTvG') }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SubscribeBanner',
	props: {
		subscribeThumbnail: {
			type: String,
			required: true
		},
		subscribeChannelName: {
			type: String,
			required: true
		}
	},
}
</script>

<style lang="less" scoped>
.subscribe-banner {
	.subscribe-box {
		position: relative;
		max-width: 312PX;
		margin: 80PX auto;
		margin-top: 40PX;
		margin-bottom: 0;
		border-radius: 10PX;
		overflow: hidden;

		img {
			width: 100%;
		}

		.title-box {
			width: 100%;
			position: absolute;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			height: 60Px;
			background: linear-gradient(180deg,rgba(0,0,0,.3),rgba(0,0,0,.9));
			color: #fff;
			font-size: 20Px;
			border-radius: 0 0 10Px 10Px;
		}

		.icon-telegram {
			width: 40PX;
			height: 40PX;
			margin: 0 12PX;
			background: url('../../assets/images/toapp/telegram.png') no-repeat center/cover;
		}
	}

	.saving-tips {
		margin-top: 12Px;
		color: #2c2c2c;
		font-size: 14Px;
	}
}
</style>