<template>
	<div class="invite-info">
		<div class="invite-info__title">1. {{ $t('invitation.earnFromYourInvited') }}</div>
		<div class="invite-info__box">
			<div class="invite-info__box-left">
				<div class="invite-info__box-left__title">30%</div>
				<div>{{ $t('invitation.vipDay') }}</div>
			</div>
			<div class="split"></div>
			<div class="invite-info__box-right">
				<div class="invite-info__bot-left__friend">
					{{ $t('invitation.friendBuyVip') }}<span class="bold-blue"><countTo :startVal="startVipNumCount" :endVal="invitationInfo.join_vip_nums" /></span>{{ $t('invitation.times') }}
				</div>
				<div class="invite-info__bot-left__award">
					{{ $t('invitation.awardVipCount') }}<span class="bold-blue"><countTo :startVal="startVipDayCount" :endVal="invitationInfo.invited_join_days" /></span>{{ $t('invitation.day') }}
				</div>
			</div>
		</div>
		<div class="divide-line"></div>
		<div class="invite-info__title">2. {{ $t('invitation.continueInvite') }}</div>
		<div class="invite-info__box">
			<div class="invite-info__box-left">
				<div class="invite-info__box-left__title">2{{ $t('invitation.day') }}</div>
				<div>{{ $t('invitation.vip') }}</div>
			</div>
			<div class="split"></div>
			<div class="invite-info__box-right">
				<div class="invite-info__bot-left__friend">
					{{ $t('invitation.hadInvited') }}<span class="bold-blue"><countTo :startVal="startSuccessNumCount" :endVal="invitationInfo.invited_nums" /></span>{{ $t('invitation.people') }}
				</div>
				<div class="invite-info__bot-left__award">
					{{ $t('invitation.awardVipCount') }}<span class="bold-blue"><countTo :startVal="startSuccessDayCount" :endVal="invitationInfo.invited_days" /></span>{{ $t('invitation.day') }}
				</div>
			</div>
		</div>
		<div class="divide-line"></div>
		<div class="invite-info__title">3. {{ $t('invitation.bu1O6BrObADowmjocv3bC') }}</div>	
	</div>
</template>

<script>
import countTo from 'vue-count-to';

export default {
	name: "NotFirstInvite",
	components: {
		countTo,
	},
	props: [
		'startSuccessNumCount',
		'startVipDayCount',
		'startSuccessDayCount',	
		'startVipNumCount',
		'invitationInfo',
	],
}
</script>

<style lang="less" scoped>
.invite-info {
	width: 980px;
	border-radius: 48px;
	margin: 0 auto;
	margin-top: 30px;
	background: #fff;
	font-family: Noto, Roboto;
	font-style: normal;
	font-weight: 600;
	font-size: 45px;
	line-height: 75px;
	text-align: center;
	padding: 60px 0;
	text-align: left;
	overflow: hidden;

	&__title {
		padding: 0 84px;
		font-size: 39px;
		line-height: 60px;
	}

	.divide-line {
		position: relative;
		width: 840px;
		margin: 60px auto;
		border-top: 1px dashed rgba(0, 0, 0, 0.2);

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: -90px;
			transform: translateY(-50%);
			width: 36px;
			height: 36px;
			border-radius: 50%;
			background: #E0F0FF;
		}

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: -90px;
			transform: translateY(-50%);
			width: 36px;
			height: 36px;
			border-radius: 50%;
			background: #E0F0FF;
		}
	}

	&__subtitle {
		margin-top: 60px;
		font-family: Noto, Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 33px;
		line-height: 75px;
		text-align: center;
		color: #98a2ad;
	}

	&__box {
		display: flex;
		height: 150px;
		margin-top: 48px;
		padding: 0 80px;
		padding-right: 60px;
		justify-content: space-between;
		align-items: center;
		box-sizing: border-box;
		font-size: 38px;
		font-size: normal;
		line-height: 1.8em;
		font-weight: 400;

		.split {
			width: 112px;
			border-top: 0.5px dashed rgba(0, 0, 0, 0.2);
			transform: rotate(-60deg);
		}

		&-left {
			color: #98A2AD;
			flex-shrink: 0;
		}

		&-right {
			min-width: 450px;
		}

		&-left__title {
			font-size: 84px;
			margin-bottom: 8px;
			color: #306EFF;
		}

		.bold-blue {
			color: #306EFF;
		}

		& + .divide-line {
			margin: 60px auto;
		}
	}

	&__day {
		font-weight: 600;
		font-size: 96px;
		line-height: 75px;
		text-align: center;
		color: #306eff;
	}

	.invite-info-button {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-style: normal;
		width: 890px;
		height: 160px;
		border-radius: 24px;
		margin: 0 auto;
		background: linear-gradient(91.65deg, #306eff 5.59%, #7059ff 110.96%);
		padding: 24px 0;
		margin-top: 24px;
		box-sizing: border-box;

		&__title {
			font-weight: 600;
			font-size: 60px;
			line-height: 1em;
			text-align: center;
			color: #ffffff;
			margin-bottom: 10px;
		}

		&__subtitle {
			font-size: 33px;
			line-height: 1em;
			text-align: center;
			font-weight: normal;
			color: rgba(255, 255, 255, 0.6);
		}

		.lottie-point {
			pointer-events: none;
		}
	}
}
</style>