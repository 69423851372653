<template>
	<div class="payment-error">
			<div class="content-box">
					<img src="@/assets/images/notices/error-banner.png" alt="error-banner" class="banner">
	
					<div class="error-describe">
						{{ $t('notices.qgZpdxaAgz97QimA3oI9N') }}
					</div>
					<div class="error-describe">
						{{ $t('notices.i3BtgbbvyaHsNuY2NxRok') }}
					</div>
					<div class="error-describe">
						{{ $t('notices.xo14l1O8Er62jqav0ryWh') }}
					</div>
					<div class="error-describe">
						{{ $t('notices.nd2l5Z72H9yM3G7JiMnMs') }} <br/><br/>
						{{ $t('notices.9MrBvYso9NBJtk3xeOt') }}
					</div>
			</div>
			<div class="join-group-button" @click="handleJoinGroup">{{ $t('notices.epCovLaH2344wDdmyzen7') }}</div>
	</div>
</template>

<script>
export default {
	name: "PaymentError",
	mounted() {
			document.title = this.$t('notices.iVanoxY7ta8Jwo8cFhOm');
	},
	methods: {
			handleJoinGroup() {
					window.location.href = /PikPak/i.test(window.navigator.userAgent) ? 'pikpakapp://mypikpak.com/account/pay_activity?refer_from=privileged_price_notify' : 'https://mypikpak.com/drive/payment?refer_from=v_web_pikpak_privileged_price_notify&target=_blank';
			}
	}
}
</script>

<style lang="less" scoped>
.payment-error {
	display: flex; 
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow-y: scroll;
	height: calc(100vh - 120Px);

	.content-box {
		margin-top: 90Px;
	}

	.banner {
			display: block;
			width: 154Px;
			height: 110Px;
			margin: 32Px auto;
	}

	.error-describe {
			font-size: 17Px;
			padding: 0 16Px;
			margin-bottom: 24Px;
	}

	.join-group-button {
			position: fixed;
			bottom: 32Px;
			width: calc(100vw - 32Px);
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: 54Px;
			background: #306EFF;
			font-size: 17Px;
			color: #fff;
			cursor: pointer;
			border-radius: 8Px;
	}

	.date-info {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			padding-right: 16Px;
	}
}
</style>