import { render, staticRenderFns } from "./rules-describe-block.vue?vue&type=template&id=77a53202&scoped=true"
var script = {}
import style0 from "./rules-describe-block.vue?vue&type=style&index=0&id=77a53202&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77a53202",
  null
  
)

export default component.exports