import { render, staticRenderFns } from "./Forbidden.vue?vue&type=template&id=799831b4&scoped=true"
import script from "./Forbidden.vue?vue&type=script&lang=js"
export * from "./Forbidden.vue?vue&type=script&lang=js"
import style0 from "./Forbidden.vue?vue&type=style&index=0&id=799831b4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "799831b4",
  null
  
)

export default component.exports