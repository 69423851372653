<template>
	<div class="content-wrapper">
			<div class="content-box">
					<img src="@/assets/images/notices/purchase.png" alt="error-banner" class="banner">
	
					<div class="content-item">
						{{ $t('notices.txvuGsiXQmwiQcum_9b9j') }}
					</div>
					<div class="content-item">
						{{ $t('notices.xn5tjTpa7Dnb3x6zQnEuI') }} <br/>
						{{ $t('notices.rFvjhdvkbsxAimIoRjKlu') }} <br/>
						{{ $t('notices.xYycBoWmjuoeo10SIil1U') }} <br/>
						{{ $t('notices.a2PqBsUNbentR6QsFy9wR') }} <br/>
						{{ $t('notices.gG3kEPdGiqMlYv8F0') }} <br/>
					</div>
					<div class="content-item" v-html="$t('notices.4qXpPhcHNikXyKOxQjGe')"></div>
			</div>
			<!-- <div class="join-group-button" @click="handleButtonClick"></div> -->
	</div>
</template>

<script>
export default {
	mounted() {
			document.title = this.$t('notices.hWlVVzI96oJs0v9u_2xKx'); 
	},
	methods: {
			handleButtonClick() {
					window.location.href = 'pikpakapp://mypikpak.com/account/pay_activity?refer_from=ko-anniversary';
			}
	}
}
</script>

<style lang="less" scoped>
.content-wrapper {
	display: flex; 
	flex-direction: column;
	align-items: center;

	.content-box {
		// height: calc(100vh - 100Px);
		overflow-y: scroll;
	}

	.banner {
			display: block;
			height: 110Px;
			margin: 32Px auto;
	}

	.content-item {
			font-size: 17Px;
			padding: 0 16Px;
			margin-bottom: 24Px;
	}

	.join-group-button {
			position: fixed;
			bottom: 32Px;
			width: calc(100vw - 32Px);
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: 54Px;
			background: #306EFF;
			font-size: 17Px;
			color: #fff;
			cursor: pointer;
			border-radius: 8Px;
	}

	.date-info {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			padding-right: 16Px;
	}
}
</style>