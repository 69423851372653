<template>
	<div class="compensation">
		<main>
			<p class="title">{{ $t('compensation.title') }}</p>	
			<section>
				{{ $t('compensation.section1') }}	
			</section>
			<section>
				{{ $t('compensation.section2') }}
			</section>
			<section>
				{{ $t('compensation.section3') }}
			</section>
			<section>
				<ul>
					<li>
						{{ $t('compensation.section4') }}
					</li>
					<li>
						{{ $t('compensation.section5') }}
					</li>
				</ul>
			</section>
			<section>
				{{ $t('compensation.section6') }}
			</section>
			<section>
				{{ $t('compensation.section7') }}
			</section>
		</main>
		<a 
			href="pikpakapp://mypikpak.com/account/pay_activity?refer_from=v_an_pikpak_yyrk_home_page&aid_from=m20220118002&prompt_free_vip=false" 
			class="commit-button"
		>
			{{ $t('compensation.button') }}
		</a>
	</div>
</template>

<script>
export default {
	name: 'Compensation',
	beforeMount() {
		document.title = this.$t('compensation.pageTitle');
	}	
}
</script>

<style lang="less" scoped>
.compensation {
	main {
		padding: 120px 100px;
		font-size: 40px;
	}

	.title {
		font-weight: bold;
	}

	section {
		margin-top: 80px;
	}

	li {
		margin-left: 45px;
		list-style: disc;
	}

	.commit-button {
		display: block;
		width: 985px;
		height: 160px;
		line-height: 160px;
		margin: 0 auto;
		font-size: 54px;
		background: #306EFF;
		color: #fff;
		border-radius: 24px;
		text-align: center;
	}
}
</style>