import { render, staticRenderFns } from "./not-first-invite.vue?vue&type=template&id=994e72cc&scoped=true"
import script from "./not-first-invite.vue?vue&type=script&lang=js"
export * from "./not-first-invite.vue?vue&type=script&lang=js"
import style0 from "./not-first-invite.vue?vue&type=style&index=0&id=994e72cc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "994e72cc",
  null
  
)

export default component.exports