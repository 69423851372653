<template>
	<div v-if="visible" class="popup-page">
		<div class="popup-wrapper" ref="card">
			<div class="title">
				{{ $t("invitation.copyThat") }}
			</div>
			<div class="subtitle">
				{{ $t("invitation.techYouShare") }}
			</div>
			<div class="share-type">
				<div class="share-type-item">
					<div class="icon"></div>
					<div class="share-type-item__title">{{ $t("invitation.communityComment") }}</div>
				</div>
				<div class="share-type-item">
					<div class="icon"></div>
					<div class="share-type-item__title">{{ $t("invitation.friend") }}</div>
				</div>
				<div class="share-type-item">
					<div class="icon"></div>
					<div class="share-type-item__title">{{ $t("invitation.group") }}</div>
				</div>
			</div>
			<div class="button-box">
				<div 
					class="ok-button"
					@click="handleOk"
				>{{ $t("invitation.ok") }}</div>
				<div 
					v-if="showShareButton"
					class="share-button"
					@click="handleShareToFriend"
				>{{ $t('invitation.u_3DrnZw0z7CQo9xT7WDo') }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getUserInfo, callSystemShare } from "../../utils/jsBridge";
import { isIOS } from "../../utils/util";

export default {
	name: 'PopupWrapper',
	props: {
		shareH5Link: {
			type: String,
			default: '',
		}
	},
	data() {
		return {
			visible: false,
			showSHareButton: false,
			userInfo: {},
		}
	},
	watch: {
		visible(val) {
			if (val && isIOS) {
				document.body.style.position = 'fixed';
				document.body.style.top = 0;
				document.body.style.left = 0;
				document.body.style.width = '100vw';
			} else {
				document.body.style.position = 'static';
			}
		}
	},
	mounted() {
		if (isIOS) {
			this.showShareButton = true;
			return;
		}
		getUserInfo(null, (data) => {
			// 保存信息到data
			this.userInfo = typeof data === 'object' ? data : JSON.parse(data || '{}');
			// 判断是否展示分享给好友按钮
			const versionArray = this.userInfo.versionName?.split('.').slice(0, 3);
			if (versionArray[0] >= 1 && versionArray[1] >= 11) {
				this.showShareButton = true;
			}
		});
	},
	methods: {
		handleShareToFriend() {
			try {
				// 数据上报
				this.$stat("external_js", "spread_invite_link_pop_click", {
					button: 'share',
				});
				const message = this.$t('invitation.8xCu7TNslf2gBjIouq3az', [this.shareH5Link]);
				callSystemShare({
					msg: message
				});
				this.hide();
			} catch(err) {
				console.error('[handleShareToFriend:]', err);
				this.hide();
			}
		},
		handleOk() {
			// 数据上报
			this.$stat("external_js", "spread_invite_link_pop_click", {
				button: 'ok',
			});
			this.hide();
		},
		show() {
			this.visible = true;
			this.$nextTick(() => {
				this.listenSlideToClose();
				this.$parent.$el.style.overflow = 'hidden';
			});
		},
		hide() {
			this.$parent.$el.style.overflow = 'scroll';
			this.visible = false;
		},
		listenSlideToClose() {
			try {
				const card = this.$refs['card'];
				const cardTop = card.getBoundingClientRect().top;
				let touchStart = cardTop;	
				const handleStart = (e) => {
					const pointY = e.touches[0].pageY;
					if (pointY - (screen.height - card.getBoundingClientRect().height) > 5) {
						card.removeEventListener('touchmove', handleMove);
						card.removeEventListener('touchend', handleEnd);
						return;
					} else {
						card.addEventListener('touchmove', handleMove);
						card.addEventListener('touchend', handleEnd);
					}
					if (touchStart === 0) {
						touchStart = card.getBoundingClientRect().top;
					}
				};
				const handleMove = (e) => {
					const { touches } = e;
					const nowY = touches[0].pageY;
					const distance = nowY - touchStart;
					const newTop = Math.max(cardTop, cardTop + distance);
					card.style.transition = null;
					card.style.top = `${newTop}px`;
					e.stopPropagation();
				};
				const handleEnd = () => {
					const rect = card.getBoundingClientRect();
					if (rect.top - touchStart <= rect.height / 2) {
						card.style.top = `${touchStart}px`;
						card.style.transition = 'top 0.3s cubic-bezier(0.680, -0.030, 0.000, 1.650)';
					} else {
						card.style.transition = 'top 0.3s ease-in';
						card.style.top = `${screen.height}px`;
						let timer = setTimeout(() => {
							this.hide();
							card.style.top = `${cardTop}px`;
							clearTimeout(timer);
							timer = null;
						}, 500);
					}
					touchStart = 0;
				};
				// 触摸屏幕时触发
				card.addEventListener('touchstart', handleStart);
				// 在屏幕上滑动时触发
				card.addEventListener('touchmove', handleMove);
				// 离开屏幕时触发
				card.addEventListener('touchend', handleEnd);
			} catch(err) {
				console.error('[listenSlideToClose:]', err);
			}
		}
	}
}
</script>

<style lang="less" scoped>
.popup-page {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 10;
	background: rgba(0, 0, 0, 0.7);
	user-select: none;
}
.popup-wrapper {
	position: absolute;
	height: 990px;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	background: #fff;
	border-radius: 48px 48px 0 0;
	font-family: PingFang SC;
	font-style: normal;
	font-weight: normal;
	font-size: 51px;
	line-height: 72px;
	text-align: center;
	box-sizing: border-box;
	padding: 120px 48px 90px 48px;

	&::before {
		content: '';
		position: absolute;
		width: 120px;
		height: 12px;
		background: #A8B2BD;
		border-radius: 13.5px;
		top: 36px;
		left: 50%;
		transform: translateX(-50%);
	}

	.subtitle {
		font-size: 39px;
		line-height: 45px;
		color: #98A2AD;
		margin-top: 24px;
	}

	.share-type {
		display: flex;
		justify-content: space-around;
		margin-top: 66px;

		&-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 300px;

			&__title {
				margin-top: 50px;
				font-size: 39px;
				line-height: 45px;
			}
		}
		&-item:nth-child(1) {
			.icon {
				width: 180px;
				height: 180px;
				background: url('../../assets/images/invitation/message.png') no-repeat center/cover;
			}
		}
		&-item:nth-child(2) {
			.icon {
				width: 180px;
				height: 180px;
				background: url('../../assets/images/invitation/friend.png') no-repeat center/cover;
			}
		}
		&-item:nth-child(3) {
			.icon {
				width: 180px;
				height: 180px;
				background: url('../../assets/images/invitation/group.png') no-repeat center/cover;
			}
		}
	}
}
.button-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 80px;

	& > div {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 162px;
		border-radius: 18px;
		cursor: pointer;
	}

	.ok-button {
		flex: 1;
		border: 1PX solid #306EFF;
		color: #306EFF;
		box-sizing: border-box;
	}

	.share-button {
		flex: 1;
		background: #306EFF;
		margin-left: 48px;
		color: #fff;
		box-sizing: border-box;
	}
}
</style>