<template>
	<div v-if="visible" class="update-notify">
		<div class="main-content">
			<div class="icon-notify"></div>
			<div class="notify-content">
				<div class="notify-content__title">{{ $t('invitation.yMUxffrxJ6SXtLCoZh73S') }}</div>
				<div class="notify-content__subtitle">{{ $t('invitation.kG5XWhDkOsLkBiAcW7svK') }}</div>
			</div>
			<div class="button" @click="handleClick">{{ $t('invitation.bi3D6kOp71Rbp0sMn_9U9') }}</div>
		</div>
		<div class="icon-close" @click="hide"></div>
	</div>
</template>

<script>
export default {
	name: "UpdateNotify",
	data() {
		return {
			visible: false,
		}
	},
	methods: {
		handleClick() {
			this.$emit('click');
		},
		show() {
			this.visible = true;
		},
		hide() {
			this.visible = false;
		}
	}
}
</script>

<style lang="less" scoped>
.update-notify {
	position: fixed;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 20;
	width: calc(100vw - 72px);
	height: 180px;
	background: #fff;
	border-radius: 24px;
	margin: 0 36px;
	bottom: 48px;
	font-size: 39px;
	padding: 0 24px;
	box-sizing: border-box;
	box-shadow: 0px 6px 48px 12px rgba(0, 0, 0, 0.06);

	.main-content {
		display: flex;
		flex-grow: 1;

		.notify-content {
			margin-left: 24px;

			&__subtitle {
				font-size: 33px;
				color: #98A2AD;
			}
		}
		
		.button {
			display: flex;
			align-items: center;
			font-weight: 600;
			padding: 18px 36px;
			background: #306EFF;
			border-radius: 12px;
			text-align: center;
			color: #fff;
			margin-left: auto;
		}
	}	

	.icon-notify {
		width: 96px;
		height: 96px;
		background: url('../../assets/images/invitation/icon-notify.png') no-repeat center/cover;
	}

	.icon-close {
		width: 48px;
		height: 48px;
		margin-left: 24px;
		background: url('../../assets/images/invitation/close.png') no-repeat center/cover;
	}
}
</style>