<template>
	<div class="payment-status">
		<div class="title">{{ $t('payment.billing') }}</div>	
		<div v-if="!canCancel" class="now-scheme">
			<div class="scheme-title">{{ $t('payment.currentPlan') }}</div>
			<div class="wrap">
				<div class="scheme-content">
					<div class="scheme-content__title">PikPak</div>
					<div class="scheme-content__price">{{ subscribeInfo.unit }}{{subscribeInfo.unit_amount }} / {{ $t(`payment.${subscribeInfo.interval}`) }}</div>
					<div class="scheme-content__time">{{ $t('payment.yourPlan') }} {{ expireTime }} {{ $t('payment.continue')}}</div>
				</div>
				<div class="button-wrap">
					<van-button :disabled="canCancel" type="info" class="cancel-scheme" @click="handleCancelScheme">{{ $t('payment.cancelScheme') }}</van-button>
				</div>
			</div>
		</div>
		<div v-else class="no-scheme">
			<div class="scheme-title">{{ $t('payment.currentPlan') }}</div>
			<div class="no-scheme__title">{{ $t('payment.notScheme') }}</div>
		</div>
		<div class="payment-type">
			<div class="scheme-title">{{$t('payment.payType')}}</div>
			<div class="scheme-content">
				<div class="payment-type-item" v-for="v in subscribeInfo.payment_methods" :key="v.created">
					<van-icon name="credit-pay" class="card-icon" />
					<div v-if="v.type === 'card'" class="card-info">
						<div class="card-id">{{ v.card.brand }} *** {{ v.card.last4 }}</div>
						<div class="card-expires">{{ v.card.exp_month }}/{{ v.card.exp_year }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Dialog, Notify } from 'vant';
import { querySubscribeStatus, cancelSubscription } from '../../api/index';
import { getUserInfo } from '../../utils/jsBridge';
import Dayjs from 'dayjs';

export default {
	name: "PaymentStatus",
	data() {
		return {
			subscribeInfo: {
				subscription_id: '',
				payment_methods: [],
				status: '',
				period_end: '',
				unit: '',
				unit_amount: '',
				interval: '',
			},
			productid: null,
		}
	},
	async mounted() {
		document.querySelector('html').style.fontSize = window.innerWidth >= 650 ? '65px' : '10vw';
		window.addEventListener('resize', () => {
			document.querySelector('html').style.fontSize = window.innerWidth >= 650 ? '65px' : '10vw';
		});
		await this.init();
		if (this.subscribeInfo.interval === 'year') {
			this.productid = 'year_subscriber';
		}
		if (this.subscribeInfo.interval === 'month') {
			this.productid = 'month_subscriber'
		}
		this.$stat('android_vip_manage', 'manage_page_show', { productid: this.productid });
	},
	computed: {
		canCancel() {
			return !['trial', 'active', 'past_due'].includes(this.subscribeInfo.status);
		},
		/**
		 * 订阅到期时间, 服务端返回东八区时间
		 * 东八时间 --> UTC --> 用户当前时区
		 */
		expireTime() {
			// (new Date('2021-11-04T08:00:00.000+22:00')).toUTCString()
			const d8Times = (new Date(this.subscribeInfo.period_end)).getTime();
			const utcTimes = d8Times - (8 * 60 * 60 * 1000);
			// 获取当前用户所处时区与UTC时间间隔(ms)
			const userTimeZone = (new Date()).getTimezoneOffset() * 60 * 1000;
			// 返回当前用户所处时区下的对应过期时间
			return Dayjs(utcTimes - userTimeZone).format('YYYY-MM-DD');	
		}
	},
	methods: {
		async init() {
			this.subscribeInfo = {
				subscription_id: '',
				status: '',
				period_end: '',
				unit: '',
				unit_amount: '',
				interval: '',
			};
			await this.getSubscribeInfo();
		},
		async getAppInfo() {
			return new Promise(resolve => {
				getUserInfo(null, data => {
					data = JSON.parse(data || '{}');
					resolve(data);
				});
			});
		},
		async getSubscribeInfo() {
			try {
				const { accessToken: token } = await this.getAppInfo();
				document.title = this.$t('activity.pageTitle');
				const { data } = await querySubscribeStatus(token);
				this.subscribeInfo = data;
			} catch(err) {
				console.error('[getSubscribeInfo:]', err);
			}
		},
		async handleCancelScheme() {
			try {
				this.$stat('android_vip_manage', 'manage_page_cancel', { productid: this.productid });
				this.$stat('android_vip_manage', 'manage_page_cancel_pop_show', {});

				await Dialog.confirm({
					title: `${this.$t('payment.ensureScheme')}?`,
					message: `${this.$t('payment.serviceUntil')} ${this.subscribeInfo.period_end.substring(0, 10)}`,
					confirmButtonText: this.$t('payment.enter'),
					confirmButtonColor: '#1989fa',
					cancelButtonText: this.$t('payment.cancel'),
				});
				this.$stat('android_vip_manage', 'manage_page_cancel_pop_click', { button: 'confirm' });
				// 取消操作
				const { accessToken: token} = await this.getAppInfo();
				const { data: { canceled, error_description } } = await cancelSubscription(this.subscribeInfo.subscription_id, token);
				if (canceled) {
					// 取消订阅成功
					Notify({ type: 'success', message: 'success' });
					this.subscribeInfo = {};
				} else {
					Notify({ type: 'danger', message: error_description });
				}
			} catch(err) {
				console.error(err);
				this.$stat('android_vip_manage', 'manage_page_cancel_pop_click', { button: 'cancel' });
			}
		}
	}
}
</script>

<style lang="less" scoped>
.payment-status {
	padding: 0 30px;

	div {
		font-size: 20px;
	}

	.no-scheme__title {
		font-size: 50px;
		margin-top: 20px;
	}

	.title {
		font-size: 80px;
		margin-bottom: 50px;
		margin-top: 100px;
	}

	.scheme-title {
		height: 70px;
		font-size: 40px;
		border-bottom: 1px solid #dfd8d8;
	}

	.now-scheme {
		.wrap {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;

			.cancel-scheme {
				display: block;
				width: 400px;
				height: 120px;
				font-size: 35px;
				margin-top: 50px;
				margin-right: 10vw;
			}
		}
		.scheme-content {
			margin-top: 20px;
			&__title {
				font-weight: bold;
				font-size: 50px;
			}
			&__price {
				font-size: 45px;
				margin: 16px 0;
			}
			&__time {
				font-size: 40px;
			}
		}
	}

	.payment-type {
		margin-top: 100px;

		&-item {
			display: flex;	
			align-items: center;
			margin-bottom: 20px;
			padding-bottom: 20px;
			border-bottom: 1px dashed #ccc;
		}

		.scheme-content {
			margin-top: 20px;

			.payment-type-item {
				display: flex;
				align-items: center;
				.card-icon {
					font-size: 100px;
				}

				.card-info {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					margin-left: 40px;

					.card-id {
						font-size: 45px;
					}
					.card-expires {
						font-size: 40px;
						color: #333;
					}
				}
			}
		}
	}
}
</style>