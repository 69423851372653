<template>
	<div class="report-page">
		<div class="report-title">{{ $t('report.reportLink') }}</div>
		<div class="report-box">
			<textarea
				name="report-link"
				class="report-content"
				:placeholder="$t('report.enterAuditLink')"
				v-model="reportLink"
			></textarea>
			<div class="report-clear" @click="clearReportContent"></div>
		</div>
		<div class="report-title">{{ $t('report.reportCause') }}</div>
		<div class="report-select">
			<div
				class="report-select-item"
				v-for="(type) in reportTypes"
				@click="chooseType(type)"
				:class="{ 'report-select-item__checked': reportType === type }"
				:key="type"
			>
				<div class="report-select-item__icon"></div>
				<div class="report-select-item__title">{{ $t(`report.reportType_${type}`) }}</div>
			</div>
		</div>
		<div
			class="submit-button"
			@click="submitReport"
			:class="{ 'submit-button__ok': isCanSubmit }"
		>{{ $t('report.submit') }}</div>
	</div>
</template>

<script>
import { getUserInfo, reportByPikPak } from '../../utils/jsBridge';
import { reportLink } from '../../api/index';
export default {
	name: "Report",
	data() {
		return {
			reportTypes: [
				'porn', // 色情
				'political', // 政治
				'violence', // 暴力
			],
			reportType: '',
			reportLink: '',
		}
	},
	beforeCreate() {
		document.title = this.$t('report.reportPageTitle');
	},
	computed: {
		isCanSubmit() {
			return this.reportLink.trim() !== '' && this.reportType !== '';
		},
		isValidLink() {
			return /(magnet:\\?|\:\/\/)/i.test(this.reportLink.trim());
		}
	},
	methods: {
		resetData() {
			this.reportType = '';
			this.reportLink = '';
		},
		chooseType(type) {
			this.reportType = type;
		},
		submitReport() {
			if (!this.isCanSubmit) { return }
			if (!this.isValidLink) {
				this.$toast(this.$t('report.invalidLink'));
				return;
			}
			try {
				getUserInfo(null, async (userInfo) => {
					try {
						await reportLink(this.reportLink, this.reportType, userInfo);
						this.$toast(this.$t('report.reportSuccess'));
						reportByPikPak({
							hubbleEventId: 'android_my_tab',
							hubbleAttribute: 'url_report_page_submission',
							hubbleExData: {
								report_reason: this.reportType,
								url: this.reportLink,
							},
						});
						this.resetData();
					} catch (err) {
						this.$toast(this.$t('report.reportFailed'));
						console.error('[submitReport-getUserInfo:]', err);
					}
				});
			} catch (err) {
				console.error('[submitReport:]', err);
				this.$toast(this.$t('report.reportFailed'));
			}
		},
		clearReportContent() {
			this.reportLink = '';
		}
	}
}
</script>

<style lang="less" scoped>
.report-page {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;

	.report-title {
		font-size: 45px;
		line-height: 54px;
		color: #000;
		margin: 36px auto 36px auto;
		width: 985px;
	}

	.report-box {
		position: relative;
		.report-clear {
			position: absolute;
			right: 100px;
			bottom: 50px;
			width: 60px;
			height: 60px;
			background: url("../../assets/images/report/clear-content.webp") no-repeat
				center/cover;
		}
	}
	.report-content {
		position: relative;
		display: block;
		width: 985px;
		height: 540px;
		border-radius: 48px;
		background: #f2f4f6;
		margin: 0 auto;
		font-size: 45px;
		padding: 50px 30px;
		box-sizing: border-box;
		border: none;
		resize: none;
		margin-bottom: 60px;

		&::placeholder {
			color: #8098a2ad;
		}
	}

	.report-select {
		width: 985px;
		margin: 0 auto;

		&-item {
			display: flex;
			align-items: center;
			padding: 54px 0;
			border-bottom: 1px solid #efefef;

			&:nth-child(1) {
				padding-top: 0;
			}

			&__icon {
				width: 50px;
				height: 50px;
				flex-shrink: 0;
				background: url("../../assets/images/report/check.webp") no-repeat
					center/contain;
			}

			&__title {
				font-size: 48px;
				margin-left: 36px;
			}
		}
	}

	.report-select {
		&-item__checked {
			.report-select-item__icon {
				background: url("../../assets/images/report/checked.webp") no-repeat
					center/cover;
			}
		}
	}

	.submit-button {
		width: 985px;
		height: 165px;
		line-height: 165px;
		border-radius: 24px;
		font-size: 51px;
		text-align: center;
		color: #fff;
		background: linear-gradient(0deg, #306eff, #306eff),
			linear-gradient(270deg, #7b38ff 0%, #3d6eff 99.93%);
		margin: 0 auto;
		opacity: 0.5;
		margin-top: 185px;
	}
	.submit-button__ok {
		opacity: 1;
	}
}
</style>