<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getAppMetaData } from './utils/jsBridge.js';
import initVconsole from './utils/initVconsole';
import webDeepLinkInterceptor from './utils/webDeepLinkInterceptor.js'

export default {
  beforeCreate() {
    document.title = '';
  },
  methods: {
    initLanguage() {
      // 使用查询参数 ?locale=zh-CN 设置语言
      const localeInSearch = (() => {
        try {
          return new URLSearchParams(window.location.search).get('locale')
        } catch (error) {
          return undefined
        }
      })()
      if (localeInSearch) {
        this.$i18n.locale = localeInSearch
        return
      }
      if (/PikPak/i.test(window.navigator.userAgent)) {
        getAppMetaData({}, (data) => {
          const metaData = typeof data === 'string' ? JSON.parse(data) : data;
          console.log('meta data: ', metaData, metaData?.language);
          this.$i18n.locale = metaData?.language || 'en-US';
        });
      }
    }
  },
  mounted() {
    initVconsole();
    this.initLanguage();
    window.addEventListener('click', webDeepLinkInterceptor)
  },
  beforeDestroy() {
    window.removeEventListener('click', webDeepLinkInterceptor)
  }
}
</script>

<style lang="less">
body {
  margin: 0;
}
</style>
