<template>
  <div class="policy">
    <h1><strong>PikPak Privacy Policy</strong></h1>
    <p>Update date: 2021-11-08</p>
    <br>
    <p>
      This Privacy Policy outlines how PikPak collects, protects and uses user information, how users manage their own
      personal information, how does PikPak protects minors&#39; privacy rights through the usage of PikPak software,
      websites and services (Collectively as "Services"), and the application and update of this Privacy Policy. It is
      recommended that users carefully read and understand all the contents of this policy, especially those with
      conspicuous marks reminding you to pay special attention.
    </p>
    <p>Content</p>
    <p>
      I.What data do we collect? <br>II. How do we collect your data? <br>III. How will we use your data? <br>IV.
      How do we store and protect your data? <br>V. Marketing <br>VI. What are your data protection rights? <br>VII.
      Cookies and similar technologies <br>VIII. Privacy policy for minors <br>IX. Do not sell your personal
      information <br>X. Transfer of personal information outside of your country <br>XI. Privacy policies of other
      websites <br>XII. Application and Renewal of this Policy <br>XIII. How to contact us
    </p>
    <p>Please read and understand this privacy policy carefully before using our Services.</p>
    <h1>I.What data do we collect?</h1>
    <p>When PikPak provides Services, it may collect, store and use the following information:</p>
    <ul>
      <li>
        <strong>Account Information</strong>: PikPak collects, and associate with your account, the information you
        provide to PikPak when you do things such as sign up for your account,
        upgrade to a paid service, and set up
        authentication (like your contact address, telephone number, e-mail, and
        user name).
      </li>
      <li>
        <strong>Device Information</strong>: PikPak also collects information from and about the devices you use to
        access the Services, which may include IP addresses, the type of browser and
        device you use, and identifiers
        associated with your devices. For example, PikPak uses device information to
        detect abuse and identify and
        troubleshoot bugs.
      </li>
      <li>
        <strong>Contact Information</strong>: You may choose to give us access to your contacts to make it easy for you
        to do things like send messages, and invite others to use the Services. If
        you do, PikPak will store those
        contacts on PikPak servers for you to use.
      </li>
      <li>
        <strong>Payment Information</strong>: You may choose to pay PikPak for specific services, in such case,
        information about your billing address and method of payment, such as bank
        details, credit, debit, or other
        payment card information will be collected.
      </li>
      <li>
        <strong>Fraud Prevention Information</strong>: PikPak needs to collect your relevant information to help
        identify and prevent fraud, including a device trust score.
      </li>
      <li>
        <strong>Usage Information</strong>: PikPak collects information related to how you use the Services, including
        actions you take in your account (like sharing, editing, viewing, creating and
        moving files or folders). PikPak
        uses these information to provide, improve, and promote our Services, and
        protect PikPak users. These information
        will include, log information, browsing history, search history, product
        interaction, crash data, performance and
        other diagnostic data, and other usage data.
      </li>
      <li>
        <strong>Location Information</strong>: To provide you with better experiences, PikPak may collect your
        geographical location. Your devices (depending on their settings) may also
        transmit geographic location
        information to the Services.
      </li>
      <li>
        <strong>Government ID Information</strong>: In certain jurisdictions, PikPak may ask for a government-issued ID
        in limited circumstances, including when setting up a wireless account
        and activating your device, for the purpose
        of extending commercial credit, managing reservations, or as required
        by law.
      </li>
      <li>
        <strong>Other Information</strong>: You may at your sole discretion to provide other information to PikPak.
      </li>
    </ul>
    <h1>II. How do we collect your data? </h1>
    <p>
      You directly provide PikPak with most of your personal information PikPak collects. We collect data and process
      data when you:
    </p>
    <ul>
      <li>Register as a user or place an order for any of our privileged services.</li>
      <li>Voluntarily complete a customer survey or provide feedback on any of our message boards or via email.</li>
      <li>Use or view our Services via your cookies.</li>
    </ul>
    <p>PikPak may also receive your personal information indirectly from the following sources:</p>
    <ul>
      <li>
        <strong>Individuals</strong>: PikPak may collect information about you from other individuals — for example, if
        that individual has invited you to use PikPak Services.
      </li>
      <li>
        <strong>At Your Direction</strong>: You may direct other individuals or third parties to share information with
        PikPak. For example, log in to PikPak through platforms such as Google and
        Facebook.
      </li>
      <li>
        <strong>PikPak Partners</strong>: We may also validate the information you provide — for example, when creating
        a PikPak account, with a third party for security, and for fraud-prevention
        purposes.
      </li>
    </ul>
    <h1>III. How will we use your data?</h1>
    <p>PikPak collects your personal information so that we can:</p>
    <ul>
      <li>
        Provide services to you, such as verification, upgrade, synchronization, and/or advertising services and
        Services.
      </li>
      <li>Conduct user authentication.</li>
      <li>Conduct account retrieval.</li>
      <li>Archive and backup purposes.</li>
      <li>Improve the user&#39;s information safety and provide customer support.</li>
      <li>Protect security and prevent fraud.</li>
      <li>Provide specific functions of the software or other functions based on your request.</li>
      <li>Comply with law.</li>
    </ul>
    <p>
      PikPak may share personal information with service providers who act on our behalf, our partners, or others at
      your
      direction. Further, PikPak does not share personal data with third parties for their own marketing purposes.
    </p>
    <ul>
      <li>
        <strong>Service Providers</strong>: PikPak may engage third parties to act as its service providers and perform
        certain tasks on our behalf, such as processing or storing information,
        including personal information, in
        connection with your use of PikPak Services. PikPak's service providers are
        obligated to handle personal
        information consistent with this Privacy Policy and according to our
        instructions. They cannot use the personal
        information we share for their own purposes and must delete or return the
        personal information once they&#39;ve
        fulfilled our request.
      </li>
      <li>
        <strong>Partners</strong>: At times, PikPak may partner with third parties to provide services or other
        offerings. For example, when PikPak processes your order, it may send your personal
        information to, and also use
        the resulting information from, credit reference agencies to prevent fraudulent
        purchases. PikPak requires its
        partners to protect your personal information.
      </li>
      <li>
        <strong>Others</strong>: PikPak may share personal infromation with others at your direction or with your
        consent. PikPak may also disclose information about you if PikPak determines that for
        purposes of national
        security, law enforcement, or other issues of public importance, disclosure is necessary
        or appropriate. PikPak
        may also disclose information about you where there is a lawful basis for doing so, if
        PikPak determines that
        disclosure is reasonably necessary to enforce PikPak's terms of services or to protect
        PikPak's operations or
        users, or in the event of a reorganization, merger, or sale.
      </li>
    </ul>
    <h1>IV. How do we store and protect your data?</h1>
    <p>
      We store your personal data and content on our servers and the servers of our service providers. Because we and
      our
      service providers maintain servers in global locations, your personal data may be transferred across national
      borders and stored on the servers outside of your country or region.
    </p>
    <p>
      The duration of the storage of personal information is determined by the respective legal retention period (e.g.
      commercial and tax retention periods). After expiry of this period, the corresponding information will be
      routinely
      deleted, provided they are no longer necessary for the performance or initiation of the contract and/or there is
      no
      longer any legitimate interest on our part in the further storage.
    </p>
    <p>
      PikPak will protect the personal information provided by you with safety protection measures conforming to
      industry
      standards to prevent unauthorized access, unauthorized public disclosure, unauthorized use, unauthorized
      modification, damage or loss of data.
    </p>
    <p>
      The Internet is not absolutely safe, and e-mail, instant messaging, and communication with other users are not
      encrypted. We strongly recommend that you should not send personal information through such means. Please use a
      complex password to help us ensure the security of your account.
    </p>
    <p>
      After an unfortunate personal information security incident occurs, we will inform you in a timely manner in
      accordance with the requirements of laws and regulations about: the basic situation and possible impacts of the
      security incident, the disposal measures we have taken or will take, suggestions that you can independently
      prevent
      and reduce risks, remedial measures for you, etc.
    </p>
    <p>
      PikPak only keeps user information in accordance with this policy. When our products or services are ceased, we
      will notify you in the form of notification, announcement, etc., and delete your personal information or anonymize
      it within 90 days, unless otherwise required by relevant law.
    </p>
    <p>
      PikPak strives to provide security for users&#39; information to prevent loss, improper use, unauthorized access
      or
      disclosure of information. PikPak will comprehensively use various security protection measures within a
      reasonable
      security level to ensure information security.
    </p>
    <h1>V. Marketing </h1>
    <p>
      PikPak would like to send you information about our Services that PikPak think you might like, as well as those of
      our partner companies.
    </p>
    <p>If you have agreed to receive marketing, you may always opt out at a later date.</p>
    <p>
      You have the right at any time to stop PikPak from contacting you for marketing purpose or giving your information
      to other members of the PikPak Group.
    </p>
    <p>
      If you no longer wish to be contacted for marketing purposes, please contact us at:
      Our email:<a
        href="mailto:support@mypikpak.com"
        target="_blank"
        class="url"
      >support@mypikpak.com</a>
    </p>
    <h1>VI. What are your data protection rights?</h1>
    <ul>
      <li>
        <strong>Right of Access:</strong> You have the right to obtain a confirmation about whether personal information
        concerning you are being processed; if that is the case, you have a right of
        access to the personal information.
      </li>
      <li>
        <strong>Right to Rectification:</strong> You have the right to obtain from us without undue delay the
        rectification of inaccurate personal information concerning you and, if
        applicable, to have incomplete information
        completed.
      </li>
      <li>
        <strong>Right to Erasure:</strong> You have the right to demand from us the erasure of personal information
        concerning you without undue delay.
      </li>
      <li>
        <strong>Right to Restriction of Processing: </strong>You have the right for the duration of the verification by
        the controller to demand the restriction of processing if
        one of the conditions applies, e.g. if you have objected
        to the processing.
      </li>
      <li>
        <strong>Right to Data Portability: </strong>You have the right to receive the personal information concerning
        you in a structured, commonly used and machine-readable format and
        to have these information transmitted to a
        third party, as long as the processing is based on consent or on a
        contract and the processing is carried out by
        means of automated procedures.
      </li>
      <li>
        <strong>Right to Object: </strong>If personal information are collected for the purposes of legitimate interests
        or in the public interest or in the exercise of official authority, you have
        the right to object to this
        processing, on grounds relating to your particular situation, at any time. We
        will no longer process the personal
        information unless there are demonstrable compelling legitimate grounds for
        this processing which override your
        interests, rights and freedoms or which serve in the establishment, exercise
        or defense of legal claims.
        Users can query or correct the user information and authorization after
        logging in to the personal center. When
        you withdraw your account, because there are high-value properties such as
        members and game equipment in the
        account and the cancellation is irreversible, in order to avoid the
        cancellation without the user&#39;s
        permission, such as robot operation or malicious cancellation, causing losses
        to the user, you need to provide ID
        number. The user information provided during account cancellation is only used
        to verify the security of the
        cancelled account. PikPak will keep it desensitized and confidential.
        If you make a request, PikPak will respond to you as soon as possible. If you
        would like to exercise any of these
        rights, please contact us at:
        Our email:<a
          href="mailto:support@mypikpak.com"
          target="_blank"
          class="url"
        >support@mypikpak.com</a>
      </li>
    </ul>
    <h1>VII. Cookies and similar technologies</h1>
    <p>
      Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior
      information. When you use PikPak Services, PikPak may collect information from you automatically through cookies
      or
      similar technology.
    </p>
    <p>
      PikPak uses cookies and similar technologies in a range of ways to improve your experience when you use our
      Services, including:
    </p>
    <ul>
      <li>Keeping you signed in.</li>
      <li>Understanding how you use PikPak Services.</li>
      <li>Conduct relevant data statistics</li>
    </ul>
    <p>PikPak uses cookies and similar technologies for below purpose:</p>
    <ul>
      <li>
        Functionality – PikPak uses these cookies or similar technologies so that we recognize you when you use PikPak
        Services and remember your previously selected preferences. These could include what language you prefer and
        location you are in. A mix of first-party and third-party cookies or similar technologies are used.
      </li>
      <li>
        Advertising – PikPak uses these cookies or similar technologies to collect information about your visit to our
        Services, the content you viewed, the links you followed and information about your device, and your IP address.
        PikPak sometimes shares some limited aspects of these information with third parties for advertising purposes.
        PikPak may also share online information collected through cookies or similar technologies with our advertising
        partners. This means that when you visit another website, you may be shown advertising based on your browsing
        patterns on our Services.
      </li>
    </ul>
    <p>
      Please note that cookie-based opt-outs are not effective on mobile applications. However, you may opt out of
      personalized advertisements on some mobile applications by following the instructions for [Android], [iOS], and
      [others].
    </p>
    <p>
      Please note you must separately opt out in each browser and on each device, in a few cases, some of PikPak
      Services
      features may not function as a result.
    </p>
    <h1>VIII. Privacy policy for minors </h1>
    <p>
      PikPak does not knowingly collect or solicit personal information from anyone under the age of 13 or order (or
      equivalent minimum age in the relevant jurisdiction) ("Minor") or knowingly allow such persons to use our
      Services.
      If you are a Minor, please do not send any information about yourself to PikPak, including your name, address,
      telephone number, or email address. No Minor may provide any personal information to PikPak. In the event that
      PikPak learns that it has collected personal information from Minor, PikPak will delete that information as
      quickly
      as possible. If you believe that PikPak might have any information from or about Minor, please contact us at:
      Our email: <a
        href="mailto:support@mypikpak.com"
        target="_blank"
        class="url"
      >support@mypikpak.com</a>
    </p>
    <h1>IX. Do not sell your personal information</h1>
    <p>
      In case our processing of personal information within the scope of the California Consumer Privacy Act ("CCPA"),
      PikPak makes the following additional commitments to you. PikPak will retain, use, or disclose personal
      information
      for any purpose other than for the purposes set out in this Privacy Policy and as permitted under the CCPA,
      including under any "sale" exemption. In no event will PikPak sell any such personal information.
    </p>
    <h1>X. Transfer of personal information outside of your country</h1>
    <p>
      PikPak Services connect you to the world. To make that possible,<strong>your personal information may be
        transferred to or accessed by entities
        around the world.</strong>PikPak complies
      with laws on the transfer of
      personal data between countries to help ensure your information is protected, wherever it may be.
    </p>
    <h1>XI. Privacy policies of other websites</h1>
    <p>
      PikPak Services contains links to other websites or application. This Privacy Policy applies only to PikPak
      Services, so if you click on a link to another website or application, you should read their privacy policy.
    </p>
    <h1>XII. Application and Renewal of this Policy </h1>
    <p>PikPak keeps its Privacy Policy under regular review and places any updates on this web page. </p>
    <h1>XIII. How to contact us</h1>
    <p>
      If you have any questions about PikPak's Privacy Policy, the information we hold on you, or you would like to
      exercise one of your data protection rights, please do not hesitate to contact us at:
      Email us at: <a
        href="mailto:support@mypikpak.com"
        target="_blank"
        class="url"
      >support@mypikpak.com</a>
    </p>
    <p>If PikPak could not response your question, you may refer your complaint to the applicable regulator. </p>
  </div>
</template>

<script>
export default {
  created() {
    document.title = 'PrivacyPolicy';
  }
}
</script>

<style scoped>
.policy {
  font-size: 16PX;
  padding: 8PX;
}

h1 {
  margin: 10px 0;
}

p {
  line-height: 1.4em;
  margin: 16px 0;
}

strong {
  font-weight: bold;
}
</style>