<template>
  <div class="ip-blocked">
    <div class="content">
      <svg
        width="241"
        height="163"
        viewBox="0 0 241 163"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          cx="120.891"
          cy="123.027"
          rx="120"
          ry="39.4839"
          fill="url(#paint0_radial_2405_18122)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M150.043 102.759C160.067 101.763 167.895 93.3051 167.895 83.0194V39.8154H161.259V86.3296C161.259 93.804 156.609 100.192 150.043 102.759Z"
          fill="#D8DFEF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M65.7363 22.1201H82.3262C82.937 22.1201 83.6682 22.6153 83.9594 23.2261L91.5818 39.816C89.9228 39.816 74.9722 39.816 72.78 39.816L65.1576 23.2261C64.8664 22.6153 65.1255 22.1201 65.7363 22.1201Z"
          fill="#D3D9E4"
        />
        <path
          d="M152.539 63.3327C152.539 63.3327 173.054 66.4946 182.499 54.1105C191.945 41.7264 185.915 31.7499 185.915 31.7499"
          stroke="#D3D9E4"
          stroke-width="5.51042"
          stroke-dasharray="17.63 1.1"
        />
        <path
          d="M172.677 19.3841C171.215 24.8045 174.428 30.3697 179.853 31.8141C185.278 33.2586 190.861 30.0354 192.322 24.6149C193.784 19.1945 190.571 13.6294 185.146 12.1849"
          stroke="#D3D9E4"
          stroke-width="5.51042"
          stroke-linecap="round"
        />
        <path
          d="M75.6397 59.427C75.6397 59.427 66.5302 57.9583 58.2577 68.18C49.9851 78.4017 55.8807 90.6006 55.8807 90.6006"
          stroke="#D3D9E4"
          stroke-width="5.51042"
          stroke-dasharray="17.63 1.1"
        />
        <path
          d="M70.2104 103.382C71.672 97.9611 68.4589 92.396 63.0339 90.9515C57.6089 89.507 52.0261 92.7302 50.5646 98.1507C49.103 103.571 52.316 109.136 57.741 110.581"
          stroke="#D3D9E4"
          stroke-width="5.51042"
          stroke-linecap="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M72.7799 39.8164H167.895V91.837C167.895 97.9237 162.961 102.858 156.874 102.858H83.8007C77.7141 102.858 72.7799 97.9237 72.7799 91.837V39.8164Z"
          fill="#ECF0F9"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M173.711 57.5117H157.121C156.51 57.5117 155.779 57.0165 155.488 56.4057L147.434 39.8159C148.54 39.8159 165.774 39.8159 167.895 39.8159L174.29 56.4057C174.581 57.0165 174.322 57.5117 173.711 57.5117Z"
          fill="#D3D9E4"
        />
        <rect
          x="102.642"
          y="51.9814"
          width="8.84793"
          height="17.6959"
          rx="4.42396"
          fill="#1E3559"
        />
        <rect
          x="129.186"
          y="51.9814"
          width="8.84793"
          height="17.6959"
          rx="4.42396"
          fill="#1E3559"
        />
        <path
          d="M140.245 9.00577C140.245 13.9796 136.213 18.0117 131.24 18.0117H129.028L126.026 21.0137L123.024 18.0117H120.812C115.838 18.0117 111.806 13.9796 111.806 9.00577V9.00577C111.806 4.03194 115.838 -0.000152707 120.812 -0.000152707L131.24 -0.000152707C136.213 -0.000152707 140.245 4.03194 140.245 9.00577V9.00577Z"
          fill="#D3D9E4"
        />
        <path
          d="M122.55 5.53027L130.292 12.1662"
          stroke="white"
          stroke-width="1.10208"
        />
        <path
          d="M130.292 5.53027L122.55 12.1662"
          stroke="white"
          stroke-width="1.10208"
        />
        <defs>
          <radialGradient
            id="paint0_radial_2405_18122"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(120.891 88.0294) rotate(90) scale(48.8084 405.327)"
          >
            <stop stop-color="#F2F1F6" />
            <stop
              offset="1"
              stop-color="#DDE3F1"
              stop-opacity="0.01"
            />
          </radialGradient>
        </defs>
      </svg>
      <h1>Sorry, PikPak is not available in the current region / 对不起，PikPak在当前地区不可用。</h1>
      <div class="detail">
        <p>
          PikPak is currently unable to provide services to users in mainland China. Your current IP address
          <template v-if="ip">
            ({{ ipAddress }})
          </template>
          is located in mainland China.<br>
          If you think the location is wrong, you can give us feedback through the customer support group.
        </p>
        <p>
          PikPak目前无法对中国大陆用户提供服务，您的当前IP地址
          <template v-if="ip">
            （{{ ipAddress }}）
          </template>
          被定位于中国大陆。<br>
          如果您认为位置判断有误，可以通过客户支持群向我们反馈。
        </p>
      </div>
      <div class="support">
        Customer support / 客户支持:&nbsp;
        <a
          href="https://t.me/pikpak_userservice"
          target="_blank"
        >Telegram</a>
        &nbsp;
        <a
          href="https://chat.whatsapp.com/BXvB38TYZb04yGjjfQiB18"
          target="_blank"
        >WhatsApp</a>
      </div>
    </div>
  </div>
</template>

<script>
import { isForbiddenIP } from '@/api';
export default {
	name: "Forbidden",
  props: {
    userIP: {
      type: String,
      default: '',
    }
  },
	data() {
		return {
			ip: '-',
		}
	},
	mounted() {
		this.userIP || this.isForbiddenIP();
	},
  computed: {
    ipAddress() {
      const ip = this.userIP || this.ip;
      const result = ip.match(/(.+\.)\d/i)?.[1];
      return result ? `${result}*` : '-';
    }
  },
	methods: {
    /**
     *  问题: access_area接口返回的结果与CDN判定的结果不一致, 所以会存在
     *  403 --> download cdn --> 403 的死循环页面刷新
     * 
     *  解决方案: 记录当前跳转fallbackLink时间, 如果记录有上次的fallbackLInk时间,
     *  并且小于4s的, 不做自动跳转
     */
		async isForbiddenIP() {
			try {
				const { data } = await isForbiddenIP();
				const ip = data.data?.ip || '-';
				this.ip = ip;

        const FALLBACK_TIMESTAMP = 'fallback_timestamp';
        const fallbackLink = document.referrer || 'https://mypikpak.com';
        const { hash } = new URL(window.location.href);
        if (hash && hash.startsWith('#redirect')) {
          if (data.result === 'ACCEPTED') {
            const ft = window.localStorage.getItem(FALLBACK_TIMESTAMP);
            if (ft && Date.now() - Number(ft) < 4000) {
              return;
            }
            window.localStorage.setItem(FALLBACK_TIMESTAMP, Date.now());
            window.location.href = fallbackLink;
          }
        } else {
          const newURL = `${window.location.href}#redirect`;
          window.location.replace(newURL);
        }

			} catch(err) {
				console.error('[isForbiddenIP: ]', err);
			}
		}
	}
}
</script>

<style lang="less" scoped>
.ip-blocked {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
	font-size: 14PX;
}

.content {
  padding: 0 10PX;
  line-height: 25PX;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-weight: 400;
    font-size: 18PX;
  }

  .detail {
    margin-top: 16PX;
    color: #666;

    p + p {
      margin-top: 4PX;
    }
  }

  > div {
    margin-top: 16PX;
  }

	a {
		color: #306eff;
	}

}

@media screen and (max-width: 320PX) {
  svg {
    height: 81PX;
  }
}

</style>
