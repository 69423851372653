<template>
	<div 
		class="enter-invite-code-page"
		v-if="visible"
	>
		<div class="wrapper" v-if="step === 'enterCode'">
			<div class="close" @click="handleClose"></div>
			<div class="title">{{ $t('invitation.ny_8N0IxY2SvAk66kOawb') }}</div>
			<div 
				class="input-box"
			>
				<input 
					type="text" 
					autofocus
					ref="inputDom"
					v-model="inviteCode"
					:placeholder="$t('invitation.ryRwlaLw0D2JxMpecnw0h')" 
				>
				<div 
					v-if="showClearButton"
					class="icon-clear"
					@click="handleClearInviteCode"
				></div>
				<div class="error-message">{{ errorText }}</div>
			</div> 
			<div 
				class="order-button"
				:class="{
					'disable': !canOrder
				}"
				@click="handleOrderVip"
			>{{ $t('invitation.now08ek267KShO7vBUpOv') }}</div>
		</div>
		<div class="success-wrapper" v-if="step === 'success' ">
			<div class="header-pic">
				<img src="../../assets/images/invitation/success.png" alt="success">
			</div>
			<div class="content">
				<div class="title">{{ $t('invitation.sPh3hjWiKhDoScsF8Krp', [awardDays]) }}</div>
				<div class="subtitle">{{ $t('invitation.8XAhTbae4We_4dzOt75nZ') }}</div>
			</div>
			<div class="bottom-box">
				<div class="enter-button" @click="handleEnter">{{ $t('invitation.aWlkrn8DckQmySqf6nNux') }}</div>
			</div>
		</div>
		<div class="traded-wrapper" v-if="step === 'traded'">
			<div class="title">{{ $t('invitation.zm6rO5J8c4pR0qR0FoIyN') }}</div>
			<div class="subtitle">{{ $t('invitation.hB7uwO3Acv3A6kCdWigzJ', [inviterCode]) }}</div>
			<div class="enter-button" @click="handleEnter">{{ $t('invitation.aWlkrn8DckQmySqf6nNux') }}</div>
		</div>
	</div>
</template>

<script>
import { orderInviteCode } from '../../api';

export default {
	name: 'EnterInviteCode',
	props: {
		language: {
			type: String,
			default: 'en-US',
		},
		userInfo: {
			type: Object,
			default: () => ({}),
		},
		inviterCode: {
			type: String,
			default: '',
		},
		autoInputCode: {
			type: String,
			default: '',
		}
	},
	data() {
		return {
			inviteCode: '',
			visible: false,
			errorText: '',
			step: 'enterCode',
			awardDays: 7,
		}
	},
	computed: {
		canOrder() {
			return this.inviteCode?.trim() !== '';
		},
		showClearButton() {
			return this.canOrder;
		}
	},
	watch: {
		autoInputCode(code) {
			if (code) {
				this.inviteCode = code;
				this.show();
			}
		}
	},
	methods: {
		show() {
			this.visible = true;
			// 数据上报
			if (this.step === 'enterCode') {
				this.$stat("external_js", "spread_redeem_code_pop_show", {});
				// 如果是输入验证码, 自动帮它聚焦
				this.$nextTick(() => {
					this.$refs['inputDom']?.focus();
				});
			}
			if (this.step === 'traded') {
				this.$stat("external_js", "spread_already_bind_pop_show", {});
			}
		},
		hide() {
			this.visible = false;
			this.inviteCode = '';
			this.errorText = '';
			if (!['success', 'traded'].includes(this.step)) {
				this.$stat("external_js", "spread_redeem_code_pop_click", {
					button: 'close',
				});
			}
		},
		// 关闭输入邀请码弹框
		handleClose() {
			this.hide();
		},
		// 清空输入的inviteCode内容
		handleClearInviteCode() {
			this.inviteCode = '';
			this.errorText = '';
			this.$stat("external_js", "spread_redeem_code_pop_click", {
				button: 'clear',
			});
		},
		// 点击邀请码兑换会员
		async handleOrderVip() {
			this.errorText = '';
			if (!this.canOrder) { return }
			try {
				this.$stat("external_js", "spread_redeem_code_pop_click", {
					button: 'redeem',
				});
				const { accessToken, language, uuid } = this.userInfo;
				const { data } = await orderInviteCode({
					code: this.inviteCode,
					deviceId: uuid,
					token: accessToken,
					language,
				});
				this.awardDays = data?.add_days || 5;
				this.step = 'success';
				this.$stat("external_js", "spread_redeem_code_result", {
					result: 'success',
					invite_code: this.inviteCode || '',
					error: '',
					errorcode: '',
				});	
			} catch(err) {
				console.error('[handleOrderVip:]', err);
				this.errorText = err?.response?.data.error_description || this.$t('invitation.3AJqq9tNfua7ObBOgoKt');
				this.$stat("external_js", "spread_redeem_code_result", {
					result: 'fail',
					invite_code: this.inviteCode || '',
					error: err?.response?.error || '',
					errorcode: this.errorText,
				});	
			}
		},
		// 点击确认
		handleEnter() {
			this.hide();
		},
		changeStepFlow(step) {
			if (!['enterCode', 'success', 'traded'].includes(step)) {
				return;
			}
			this.step = step;
		}
	}
}
</script>

<style lang="less" scoped>
.enter-invite-code-page {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background: rgba(0, 0, 0, 0.6);


	.wrapper {
		position: relative;
		width: 960px;
		min-height: 642px;
		background: #fff;
		text-align: center;
		border-radius: 24px;
		padding: 48px;
		box-sizing: border-box;

		div.close {
			content: '';
			position: absolute;
			width: 72px;
			height: 72px;
			top: 36px;
			right: 36px;
			background: url('../../assets/images/invitation/close.png') no-repeat center/cover;
		}
	}

	.title {
		font-size: 51px;
		padding-right: 50px;
	}

	.input-box {
		position: relative;
		display: flex;
		justify-content: center;
		margin: 60px 0;

		input {
			display: block;
			width: 864px;
			height: 162px;
			font-size: 51px;
			border-radius: 24px;
			border: none;
			background: #F2F3F4;
			padding: 0 48px;
			box-sizing: border-box;

			&::-webkit-input-placeholder {
				color: #98A2AD;
			}
		}

		.error-message {
			position: absolute;
			left: 0;
			bottom: -50px;
			font-size: 33px;
			color: #FF663B;
		}
	}

	.icon-clear::after {
		content: '';
		position: absolute;
		top: 50%;
		right: 57px;
		width: 48px;
		height: 48px;
		transform: translateY(-50%);
		cursor: pointer;
		background: url('../../assets/images/invitation/clear.png') no-repeat center/cover;
	}

	.order-button {
		width: 864px;
		height: 162px;
		line-height: 162px;
		font-size: 51px;
		text-align: center;
		color: #fff;
		margin-top: 20px;
		background: #306EFF;
		border-radius: 24px;
		cursor: pointer;

		&.disable {
			opacity: 0.5;
			cursor: auto;
		}	
	}
}

.success-wrapper {
	position: relative;
	width: 960px;
	height: 672px;
	background: #fff;
	border-radius: 24px;

	.header-pic {
		position: relative;
		padding-top: 160px;
		box-sizing: border-box;

		img {
			position: absolute;
			width: 468px;
			height: 318px;
			top: -160px;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	.content {
		text-align: center;
		margin-top: 84px;

		.title {
			font-size: 51px;
		}

		.subtitle {
			font-size: 39px;
			color: #98A2AD;
		}
	}

	.bottom-box {
		position: absolute;
		bottom: 0;
		width: 100%;
		display: flex;
		font-size: 45px; 
		line-height: 150px;
		text-align: center;
		border-top: 1px solid #E4E4E4;
		box-sizing: border-box;
		cursor: pointer;

		.enter-button {
			flex: 1;
			height: 150px;
			text-align: center;
			color: #306EFF;
		}
	}
}

.traded-wrapper {
	position: relative;
	width: 960px;
	height: 480px;
	border-radius: 24px;
	text-align: center;
	background: #fff;
	box-sizing: border-box;

	.title {
		margin-top: 72px;
		font-size: 51px;
		font-weight: 600;
	}

	.subtitle {
		font-size: 39px;
		color: #98A2AD;
		margin-top: 48px;
	}
	
	.enter-button {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 150px;
		line-height: 150px;
		border-top: 1PX solid #E4E4E4;
		font-size: 45px;
		color: #306EFF;
		cursor: pointer;
	}
}
</style>