<template>
<div class="bot-subscription-item-wrapper">
	<div 
		class="bot-subscription-item" 
		v-for="item, index in subItems" 
		:key="index"
		:style="{opacity: statusActivity ? 1 : 0.5}"
	>
		<div class="left-box">
			<svg t="1648784318622" class="icon-subscribe"  :fill="statusActivity ? '#306EFF' : '#306EFF'" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4137" width="128" height="128"><path d="M810.666667 149.333333a64 64 0 0 1 64 64v597.333334a64 64 0 0 1-64 64H213.333333a64 64 0 0 1-64-64V213.333333a64 64 0 0 1 64-64h597.333334z m0 64H213.333333v597.333334h597.333334V213.333333zM373.333333 640a53.333333 53.333333 0 1 1 0 106.666667 53.333333 53.333333 0 0 1 0-106.666667zM341.333333 480c121.685333 0 220.714667 97.024 223.914667 217.941333L565.333333 704h-64a160 160 0 0 0-154.816-159.914667L341.333333 544v-64z m21.333334-192c215.573333 0 390.784 172.842667 394.602666 387.498667L757.333333 682.666667h-64c0-180.373333-144.426667-326.997333-323.925333-330.602667L362.666667 352v-64z" p-id="4138"></path></svg>
		</div>
		<a :href="`pikpakapp://mypikpak.com/app/action_view?msg=${item.channel_link}`" class="center-box">
			<div class="center-top-box">{{ item.channel_name }}</div>
			<div class="center-bottom-box">{{ item.channel_link }}</div>
		</a>
		<div class="right-box" @click="handleActionSubscribe(item)">
			<svg v-if="statusActivity" t="1648784481437" class="icon-del" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3859" width="128" height="128"><path d="M830.08 554.794667a18.901333 18.901333 0 0 0-22.058667 15.402666 322.901333 322.901333 0 0 1-318.464 266.410667c-178.389333 0-323.498667-145.109333-323.498666-323.498667a323.413333 323.413333 0 0 1 591.701333-180.906666 319.829333 319.829333 0 0 1 50.261333 123.818666 19.072 19.072 0 0 0 37.461334-6.656A356.949333 356.949333 0 0 0 789.333333 310.912a361.301333 361.301333 0 0 0-299.733333-159.36A361.429333 361.429333 0 0 0 128 513.109333c0 199.381333 162.218667 361.557333 361.557333 361.557334a360.874667 360.874667 0 0 0 355.925334-297.770667 19.029333 19.029333 0 0 0-15.36-22.101333" p-id="3860" fill="#333333"></path><path d="M284.16 512a20.138667 20.138667 0 0 0 0 40.277333H720.213333a20.138667 20.138667 0 0 0 0-40.277333H284.16z" p-id="3861" fill="#333333"></path></svg>
			<svg v-else t="1648784511073" class="icon-add" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4052" width="128" height="128"><path d="M871.722667 572.501333a19.968 19.968 0 0 0-23.381334 16.298667 320.64 320.64 0 0 1-316.16 264.533333C354.986667 853.333333 210.901333 709.290667 210.901333 532.138667A321.152 321.152 0 0 1 532.138667 210.944a321.066667 321.066667 0 0 1 316.202666 264.448 20.181333 20.181333 0 0 0 39.68-7.082667 357.504 357.504 0 0 0-56.192-138.368A361.301333 361.301333 0 0 0 532.138667 170.666667 361.386667 361.386667 0 0 0 170.666667 532.138667C170.666667 731.477333 332.842667 893.610667 532.138667 893.610667a360.917333 360.917333 0 0 0 355.882666-297.728 20.181333 20.181333 0 0 0-16.298666-23.381334" p-id="4053"></path><path d="M531.712 784.341333a21.333333 21.333333 0 0 0 21.333333-21.333333v-209.493333h209.536a21.333333 21.333333 0 0 0 0-42.666667h-209.493333V301.226667a21.333333 21.333333 0 0 0-42.666667 0v209.578666H300.8a21.333333 21.333333 0 0 0 0 42.666667h209.578667v209.536a21.333333 21.333333 0 0 0 21.333333 21.333333" p-id="4054"></path></svg>
		</div>
	</div>
</div>
</template>

<script>
import {
	addChannelDescribe,	
	cancelChannelDescribe,
} from '../../api';

export default {
	name: 'SubscriptionItem',
	props: {
		subItems: {
			type: Array,
			default: () => ([]),
		},
		statusActivity: {
			type: Boolean,
			default: true,
		}
	},
	methods: {
		async handleActionSubscribe(item) {
			try {
				const requestBody = {
					type: item.type || '',
					channel_link: item.channel_link || '',
				}
				if (item.status.toLowerCase() === 'ok') {
					await cancelChannelDescribe(requestBody);
					this.$toast(this.$t('middleware.r11_3mtlafj3N4iV1Wox'));
				} else {
					await addChannelDescribe(requestBody);
					this.$toast(this.$t('middleware.mRmMgaDspmpJkpBo7mdl8'));
				}
				this.$emit('refresh-list');
			} catch(err) {
				console.error('[handleActionSubscribe: ]', err);
			}
		},
	}
}
</script>

<style lang="less" scoped>
.bot-subscription-item {
	display: flex;
	margin: 0 16Px;
	align-items: center;
	height: 60Px;
	border-bottom: 1px solid #bfbfbf;

	.center-box {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		margin: 0 auto 0 16Px;
		color: #666;
		overflow: hidden;

		.center-top-box {
			color: #333;
			width: calc(100vw - 120Px);
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.center-bottom-box {
			overflow: hidden;
			width: calc(100vw - 120Px);
			white-space: nowrap;
			text-overflow: ellipsis;
			font-size: 14Px;
		}
	}
	
	.icon-subscribe {
		width: 40Px;
		height: 40Px;
	}

	.icon-subscribe__disable {
		color: rebeccapurple!important;
	}

	.icon-add {
		width: 32Px;
		height: 32Px;
	}
	
	.icon-del {
		width: 32Px;
		height: 32Px;

	}
}
</style>