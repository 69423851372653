<template>
	<div class="invited-methods-pane">
		<div class="invited-methods-pane__title">
			<i class="icon icon-plane"></i>
			<span>{{ $t('invitation.more-method-to-invite') }}</span>
		</div>
		<van-collapse class="invited-content" v-model="activeName" accordion>
			<van-collapse-item :title="$t('invitation.g4HnOjI94Mv8zQy8IqrGe')" name="1">
				<InvitePipeline :pipelineStages="installLinkData" />
				<div class="apple-invite-tips">{{ $t('invitation.iosInviteTips') }}</div>
				<InviteButton class="mb-20 mt-14" @click="handleLinkInvite">{{ $t('invitation.3Je8aKdiynlFeQa_0ghh6') }}</InviteButton>
			</van-collapse-item>
			<van-collapse-item class="new-tag" :title="$t('invitation.bFm47Z76KfqeSznR1x1Nm')" name="2">
				<InvitePipeline :pipelineStages="shareInviteData" />
				<InviteButton class="mb-20 mt-32" @click="handleShareInvite">{{ $t('invitation.wLl4vetKaCvzRdGlfGqv') }}</InviteButton>
			</van-collapse-item>
			<van-collapse-item :title="$t('invitation.stDuM9Vxbjk33cf8KcGq0')" name="3">
				<InvitePipeline :pipelineStages="inviteCodeData" />
				<div class="my-invite-code">
					<span class="title">{{ $t('invitation.4kS5Bpf_2Q5aixlBzqn0Z') }}</span>
					<span class="invite-code">{{ inviteCode }}</span>
				</div>
				<div class="button-box mb-20">
					<InviteButton @click="handleShareToFriend">{{ $t('invitation.u10KVi1oM1ofcDg6z5iEg') }}</InviteButton>
					<InviteButton plain="true" @click="handleCopyInviteCode">{{ $t('invitation.wXUwHoCjXqksk75d2XPp') }}</InviteButton>
				</div>
			</van-collapse-item>
		</van-collapse>

		<invitation-popup ref="invitationPopup" :shareH5Link="shareH5Link" />
		<UpdateNotify ref="updateNotify" @click="handleUpdateNotifyClick" />
	</div>
</template>

<script>
import { parse } from 'qs';
import { Toast } from 'vant';
import { callSystemShare, closeWebview, toFileBackCurrentPage } from '../../utils/jsBridge';
import { copyLinkToClipboard } from '../../views/activity/invitation.common';
import InviteButton from './invite-button.vue';
import InvitePipeline from './invite-pipeline.vue'
import InvitationPopup from './invitation-popup.vue';
import UpdateNotify from './update-notify.vue';
import { isIOS } from '../../utils/util';

export default {
	name: "InvitedMethods",
	components: {
		InvitePipeline,	
		InviteButton,
		InvitationPopup,
		UpdateNotify,
	},
	props: ['userInfo', 'inviteCode', 'invitedNum'],
	data() {
		return {
			activeName: '1',
			installLinkData: [
				{
					iconName: 'invite-link',
					title: this.$t("invitation.shareToFriend"),
				},
				{
					iconName: 'invite-pikpak',
					title: this.$t("invitation.friendInstall"),
				},
				{
					iconName: 'invite-success',
					title: this.$t("invitation.inviteSuccessful"),
				},
			],
			shareInviteData: [
				{
					iconName: 'invite-share',
					title: this.$t('invitation.49ATeBXcmc21mJrg1GaFq'),
				},
				{
					iconName: 'invite-pikpak',
					title: this.$t('invitation.h6M5kFr2SxJlBy0usxCzT'),
				},
				{
					iconName: 'invite-success',
					title: this.$t("invitation.inviteSuccessful"),
				},
			],
			inviteCodeData: [
				{
					iconName: 'invite-pikpak',
					title: this.$t('invitation.78GfAohy4Ms7xV5AaCqXw'),
				},
				{
					iconName: 'invite-code',
					title: this.$t('invitation.z9IPdSzpJxOmt6g1EbGrm'),
				},
				{
					iconName: 'invite-success',
					title: this.$t("invitation.inviteSuccessful"),
				},
			],
			shareH5Link: '',
		}
	},
	watch: {
		userInfo() {
			const versionCode = this.userInfo?.versionCode;
			if (!versionCode) { return; }
			// 判断是否为问题版本
			if (versionCode >= 10049 && versionCode <= 10052) {
				const preNotifyTime = parseInt(window.localStorage.getItem('updateNotify') || '0');
				if (Date.now() - preNotifyTime < 24 * 60 * 60 * 1000) {
					return;
				}
				this.$refs['updateNotify'].show();
				window.localStorage.setItem('updateNotify', `${Date.now()}`);
				return;
			}
		}
	},
	mounted() {
		try {
			const { index } = this.getSearchParams();
			if (index && index > 0 && index < 4) {
				this.activeName = `${index}`;
			}
		} catch(err) {
			console.error('on mounted parse params error: ', err);
		}
	},
	methods: {
		getSearchParams() {
			try {
				return parse(window.location.search?.slice(1));	
			} catch(err) {
				console.error('[parseSearchParams: ]', err);
				return {};
			}
		},
		// 立即邀请(链接邀请)
		async handleLinkInvite() {
			try {
				if (!this.inviteCode) {
					return;
				}
				let link = `https://toapp.mypikpak.com/activity/invited?code=${this.inviteCode}`;
				if (this.isGPPackage) {
					link += '&g=1';
				}
				this.shareH5Link = link;
				const result = copyLinkToClipboard(link);
				if (result) {
					this.$refs['invitationPopup'].show();
				}
				// 数据上报
				this.$stat("external_js", "spread_invite_page_click", {
					button: 'share',
					invited_num: this.invitedNum || 0,
				});
			} catch (err) {
				console.error('[handleLinkInvite:]', err);
			}
		},
		// 通过分享文件邀请	
		async handleShareInvite() {
			const versionCode = this.userInfo?.versionCode || 0;
			if (!isIOS) {
				if (versionCode >= 10049 && versionCode <= 10052) {
					await copyLinkToClipboard('https://mypikpak.com');
					Toast({
						message: this.$t('invitation.yjz7Yev6UufY8tyVxTj1E'),
						position: 'bottom',
					});
					return;
				}
				if (versionCode < 10066) {
					Toast({
						message: this.$t('invitation.jlXz6iCidsDMf5wK6PnJ'),
						position: 'bottom',
					});
					return;
				}
			}
			const params = this.getSearchParams();
			// 没有from参数, 默认为个人tab进入
			if (!params.from) {
				const a = document.createElement('a');
				a.href = 'pikpakapp://mypikpak.com/xpan/main_tab?tab=2';
				if (isIOS) {
					toFileBackCurrentPage({}, () => {
						a.click();
					});
				} else {
					a.click();
				}
				return;
			}
			if (params.from === 'popup') {
				closeWebview();
				return;
			}
		},
		// 复制邀请码
		async handleCopyInviteCode() {
			const result = copyLinkToClipboard(this.inviteCode);
			if (result) {
				Toast({
					message: this.$t('invitation.k_6MjGjxI9BrmYaJMeiYa'),
					position: 'bottom',
				});
				// 数据上报
				this.$stat("external_js", "spread_invite_link_pop_click", {
					button: 'ok',
				});
			}
		},
		// 分享给好友
		async handleShareToFriend() {
			try {
				const link = "https://toapp.mypikpak.com/activity/invited?code=" + this.inviteCode;
				const message = this.$t('invitation.jwniqgQ0ndGisnxEzft6s', [this.inviteCode, link]);
				callSystemShare({
					msg: message
				});
				// 数据上报
				this.$stat("external_js", "spread_invite_link_pop_click", {
					button: 'share',
				});
			} catch(err) {
				console.error('[handleShareToFriend:]', err);
			}
		},
		async handleUpdateNotifyClick() {
			// 复制官网链接到粘贴板
			await copyLinkToClipboard('https://mypikpak.com');
			Toast({
				message: this.$t('invitation.k_6MjGjxI9BrmYaJMeiYa'),
				position: 'bottom',
			});
		}
	}
}
</script>

<style lang="less" scoped>
.invited-methods-pane {
	margin-top: 72px;

	.apple-invite-tips {
		font-size: 33px;
		margin-top: 14Px;
		color: #98A2AD;
		text-align: center;
	}

	.mt-14 {
		margin-top: 14PX;
	}

	.mb-20 {
		margin-bottom: 60px;
	}

	.mt-32 {
		margin-top: 96px;
	}

	&__title {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 51px;
		margin-bottom: 36px;
		font-weight: 600;

		.icon {
			display: block;
			width: 96px;
			height: 96px;
			margin-right: 24px;
		}	
	}

	.invited-content {
		width: 980px;
		margin: 0 auto;
		border-radius: 48px;
		padding: 0 48px;
		box-sizing: border-box;
		background: #fff;
		overflow: hidden;	
	}

	.my-invite-code {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 60px;

		.title {
			font-size: 33px;
			color: #98A2AD;
		}

		.invite-code {
			font-size: 60px;
			color: #000;
			font-weight: 600;
			margin-left: 36px;
		}
	}

	.button-box {
		margin-top: 60px;

		div:last-child {
			margin-top: 36px;
		}
	}

	.icon-plane {
		background: url('../../assets/images/invitation/plane.png') no-repeat center/cover;
	}
}
</style>
<style lang="less">
.invited-content {	
	.new-tag {
		.van-cell__title span {
			position: relative;

			&::after {
				content: 'New';
				position: absolute;
				width: 105px;
				height: 51px;
				z-index: 10;
				top: 50%;
				right: -120px;
				transform: translateY(-50%);
				background: rgba(48, 110, 255, 0.1);
				border-radius: 27px;
				font-size: 33px;
				color: #306EFF;
				font-weight: 600;
				line-height: 51px;
				text-align: center;
			}
		}
	}

	.van-collapse-item {
		& > .van-cell {
			padding: 60px 0;		
		}

		.van-cell--clickable:active {
			background: transparent;
		}

		.van-cell__title {
			font-size: 45px;
			font-weight: 800;	
		}	

		&::after {
			display: none!important;
		}

		&__content {
			padding: 0;
		}
	}

	.van-collapse-item + .van-collapse-item {
		.van-cell {
			border-top: 1.5px dashed rgba(152, 162, 173, 0.8);
		}
	}

	.van-collapse-item__title--expanded::after {
			display: none!important;
	} 
}
</style>