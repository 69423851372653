<template>
	<div v-if="visible" class="toast" :style="{ bottom: count * 60 + 'px' }">
		{{ message}}
	</div>
</template>

<script>
	export default {
		name: 'Toast',
		data() {
			return {
				visible: true,
			}
		},
		mounted() {
			this.timer = setTimeout(() => {
				this.visible = false;
				clearTimeout(this.timer);
				this.timer = null;
				this.reduce();
			}, 3000);
		},
	}
</script>

<style lang="less" scoped>
.toast {
	position: fixed;
	z-index: 12;
	left: 50%;
	bottom: 300px;
	transform: translateX(-50%);
	padding: 0 60px;
	height: 126px;
	line-height: 126px;
	font-family: Microsoft YaHei;
	font-style: normal;
	font-weight: normal;
	font-size: 39px;
	text-align: center;
	color: #FFFFFF;
	background: #222222;
	mix-blend-mode: normal;
	opacity: 0.9;
	border-radius: 72px;
	white-space: nowrap;
}
</style>