<template>
  <div class="update-guide">
      <div class="content-box">
          <img src="@/assets/images/notices/error-banner.png" alt="error-banner" class="banner">
          <div class="content-tips">
            <div class="describe">
              {{ $t('notices.nqu0Lz8AXfIwhzkjfTx2t') }}
            </div>
            <div>
              {{ $t('notices.update-step') }}
            </div>
            <ul>
              <li>{{ $t('notices.update-step1') }}</li>
              <li>{{ $t('notices.update-step2') }}</li>
              <li>{{ $t('notices.update-step3') }}</li>
            </ul>
            <div 
              class="describe-other" 
              v-html="$t('notices.update-other-tip', {'0': `<a href='pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Fmobile-app%20'>${$t('notices.goto-official-site')}</a>`})"
            >
            </div>
          </div>
      </div>
      <div class="join-group-button" @click="handleJoinGroup">{{ $t('notices.yrIer2T5zwR5eRzeB6Nq') }}</div>
  </div>
</template>

<script>
export default {
  name: "PaymentError",
  mounted() {
      document.title = this.$t('notices.update-guide-title');
  },
  methods: {
      handleJoinGroup() {
          window.location.href = 'pikpakapp://mypikpak.com/xpan/setting';
      },
      gotoMobileapp() {
        window.location.href = 'pikpakapp://mypikpak.com/app/action_view?msg=https%3A%2F%2Fmypikpak.net%2Fmobile-app%20'
      }
  }
}
</script>

<style lang="less">
.update-guide {
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .content-box {
      margin-bottom: 120Px;
  }

  .banner {
      display: block;
      width: 154Px;
      height: 110Px;
      margin: 32Px auto;
  }

  .content-tips {
    padding: 0 13Px;
    line-height: 20Px;
    font-size: 13Px;
    color: #000;
  }

  .describe {
    margin-bottom: 12Px;

    &-other {
      margin-top: 20Px;
    }
  }

  .join-group-button {
      position: fixed;
      bottom: 32Px;
      width: calc(100vw - 32Px);
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 54Px;
      background: #306EFF;
      font-size: 17Px;
      color: #fff;
      cursor: pointer;
      border-radius: 8Px;
  }

  .date-info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-right: 16Px;
  }

  a {
    color: #306EFF;
  }
}
</style>