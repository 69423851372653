<template>
	<div class="ping-wrapper">
		<div class="animation-wrapper">
			<div class="animation-box">
				<div class="bg"></div>
				<div class="circle" v-if="pinging"></div>
			</div>
			<div class="status-text" v-if="pinging">{{ $t('ping.zUOuajK9Vrj7wRdNtyr') }}...</div>
			<div class="status-text" v-else>{{ networkInfo.result }}</div>
		</div>
		<div class="network-info">
			<div class="network-info__item">
				<div class="title">{{ $t('ping.c662JNupxRu5DtUHwAw0') }}</div>
				<div 
					class="value"
					:class="{
						'empty': !networkInfo.ip,
					}"
				>
					{{ networkInfo.ip || '--'}}
				</div>
			</div>
			<div class="network-info__item">
				<div class="title">{{ $t('ping.1r9dIqr2zIviPoOq2Aztc') }}</div>
				<div 
					class="value"
					:class="{
						'empty': !networkInfo.status,
					}"
				>
					{{ networkInfo.status || '--' }}
				</div>
			</div>
			<div class="network-info__item">
				<div class="title">{{ $t('ping.tAdhSqcWdWeXzzSobsaZq') }}</div>
				<div 
					class="value"
					:class="{
						'empty': !networkInfo.time,
					}"
				>
					{{ networkInfo.time || '--' }}
				</div>
			</div>
			<div class="network-info__item">
				<div class="title">{{ $t('ping.5_1K79Hqnu9TKruUjoNn') }}</div>
				<div 
					class="value"
					:class="{
						'empty': !networkInfo.stable,
					}"
				>
					{{ networkInfo.stable || '--' }}
				</div>
			</div>
		</div>
		<div class="describe-box">
			<section class="describe-text">{{ $t('ping.vkcGxcTjpTxToo35QMcdt') }}</section>
			<section class="describe-text">{{ $t('ping.wwzBWipPz3nkVivzStLf') }}</section>
			<section class="describe-text">{{ $t('ping.zHemiM0V6bl0gEOcwbfXc') }}</section>
			<section class="describe-text">{{ $t('ping.xVfFbaIBtklnflZxR7e8') }}</section>
		</div>
		<div class="button-box">
			<div 
				class="commit-button" 
				:class="{
					'commit-button--disable': pinging
				}"
				@click="handlePingServer('retest')"
			>{{ pinging ? $t('ping.zUOuajK9Vrj7wRdNtyr') : $t('ping.lskdjocwlkalskALFJKD') }}</div>
			<div class="button-tips" @click="toFeedback">{{ $t('ping.vEkphatLOtyJjGhNDyPw') }}</div>
		</div>
	</div>
</template>

<script>
import { getNetworkInfo } from '../api/index';
import { updateConnectivity, reportByPikPak } from '../utils/jsBridge';
import { $t } from '../main';

// 网络状态枚举
const STATUS_MAPPING = {
	'NONE': $t('ping.gnFxDuY5Zh2Cdm8Kb7cXn'),
	'NOT_SURE': $t('ping.fGsuBJTa5Iw3berR8JHv'),
	'NORMAL': $t('ping.1qGde72PyCdLgTt7GFi5R'),
	'GOOD': $t('ping.ce4RtjiBqWjlpXpUYnRej'),
	'WONDERFUL': $t('ping.yMhJqwmMdfGnl0Qj3xkz'),
	'TIMEOUT': $t('ping.gRtOuwToeU0c32k1R909'),
}
// 数据上报status的key映射
const REPORT_STATUS_MAPPING = {
	'NOT_SURE': 'uncertain',
	'NORMAL': 'ordinary',
	'GOOD': 'better',
	'WONDERFUL': 'fine',
}
export default {
	name: "ping",
	data() {
		return {
			networkInfo: {
				ip: '',
				status: '',
				result: '',
				time: '',
				stable : '',
			},
			pinging: false,
			reportData: {
				state: '',
				time_consuming: '',
				long_term_stability: '',
				button: 'feedback'
			}
		}
	},
	mounted() {
		getNetworkInfo();
		this.handlePingServer();
		const currentHTMLFontSize = parseInt(window.getComputedStyle(document.querySelector('html'))['fontSize']);
		if (currentHTMLFontSize >= 95) {
			document.querySelector('html').style.fontSize = '40px';
		}
	},
	beforeMount() {
		document.title = this.$t('ping.0lDDftiEmUpQi4wovbKf');
	},
	methods: {
		resetNetworkInfo() {
			this.networkInfo = {
				ip: '',
				status: '',
				result: '',
				time: '',
				stable : '',
			}
		},
		// ms: 毫秒
		formatTimes(ms) {
			return `${parseInt(ms)}ms`;
		},
		getNetworkStatus(ms) {
			ms = parseFloat(ms);
			if (ms <= 300) {
				return 'WONDERFUL';
			}	
			if (ms > 300 && ms <= 1000) {
				return 'GOOD';
			}	
			// 超时时间为5s
			if (ms > 5000) {
				return 'NOT_SURE';
			}
			if (ms > 1000) {
				return 'NORMAL';
			}
			return 'NOT_SURE';
		},
		// 获取平均响应时间
		async getPingResult() {
			try {
				const requestStartTime = Date.now();
				const { data: networkInfo } = await getNetworkInfo();
				const requestDurationTime = Date.now() - requestStartTime;
				return {
					type: 'success',
					info: {
						...networkInfo.data,
						time: requestDurationTime,
						stable: networkInfo.data.stable === 'true',
					},
				}
			} catch(err) {
				console.error('[getPingResult]', err);
				return {
					type: 'failed',
					info: {},
				}
			}
		},
		async handlePingServer(type) {
			try {
				if (this.pinging) {
					return;
				}
				this.resetNetworkInfo();
				// 取三次结果
				this.pinging = true;
				const PING_TIMES = 3;
				const promiseList = [...new Array(PING_TIMES)].map(() => this.getPingResult());
				const results = await Promise.all(promiseList);
				// 过滤掉不成功的结果
				const successResult = results.filter(({ type }) => type !== 'failed');	
				// 计算平均请求时间
				let totalTime = 0;
				for (let { info: { time }} of successResult) {
					totalTime += time;
				}
				const coverageTime = this.formatTimes(totalTime / successResult.length / 2);
				const lastSuccessResponseData = successResult.pop()?.info;
				// 请求全部失败
				if (successResult.length === 0) {
						let timer = setTimeout(() => {
							this.pinging = false;
							this.networkInfo = {
								result: this.$t('ping.gRtOuwToeU0c32k1R909'),
								ip: this.$t('ping.ltpWhJb7kpeZyTkdn7gm'),
								time: this.$t('ping.ltpWhJb7kpeZyTkdn7gm'),
								status: this.$t('ping.ltpWhJb7kpeZyTkdn7gm'),
								stable : this.$t('ping.ltpWhJb7kpeZyTkdn7gm'),	
							};
							clearTimeout(timer);
							timer = null;
						}, 3000);
						// 数据上报
						reportByPikPak({
							hubbleEventId: 'android_my_tab',
							hubbleAttribute: 'connectivity_page_show',
							hubbleExData: {
								state: 'unknown',
								time_consuming: parseInt(coverageTime),
								long_term_stability: lastSuccessResponseData.stable ? 'stable' : 'uncertain'
							}
						});
						updateConnectivity({
							connectivity: 'NONE',
						});	
						return;
				}
				const statusKey = this.getNetworkStatus(coverageTime);
				// 通知客户端在tab栏中展示网络检测状态
				updateConnectivity({
					connectivity: lastSuccessResponseData.stable ? statusKey : 'NOT_SURE',
				});	
				// 数据上报
				const reportData = {
					hubbleEventId: 'android_my_tab',
					hubbleAttribute: !type ? 'connectivity_page_show' : 'connectivity_page_click',
					hubbleExData: {
						state: REPORT_STATUS_MAPPING[statusKey] || 'unknown',
						time_consuming: parseInt(coverageTime),
						long_term_stability: lastSuccessResponseData.stable ? 'stable' : 'uncertain'
					}
				}
				this.reportData = {
					...this.reportData,
					state: REPORT_STATUS_MAPPING[statusKey],
					time_consuming: parseInt(coverageTime),
					long_term_stability: lastSuccessResponseData.stable ? 'stable' : 'uncertain'	
				}
				if (type) {
					reportData.hubbleExData.button = type;
				}
				reportByPikPak(reportData);
				const resultInfo = STATUS_MAPPING[statusKey];
				let timer = setTimeout(() => {
					this.pinging = false;
					this.networkInfo = {
						...this.networkInfo,
						ip: lastSuccessResponseData?.ip || '',
						time: coverageTime,
						// stable为false: 大陆ip, true: 非大陆ip
						stable: lastSuccessResponseData?.stable ? this.$t('ping.lVsZIiC9CuHtV7x1yIxB') : this.$t('ping.fGsuBJTa5Iw3berR8JHv'),
						status: this.$t('ping.qCjpYehQzSHuku4ThHzm'),
						result: lastSuccessResponseData.stable ? resultInfo : this.$t('ping.fGsuBJTa5Iw3berR8JHv'),
					}
					clearTimeout(timer);
					timer = null;
				}, 3000);
			} catch(err) {
				console.error('[handlePingServer: ]', err);
				let timer = setTimeout(() => {
					this.pinging = false;
					this.networkInfo = {
						result: this.$t('ping.gRtOuwToeU0c32k1R909'),
						ip: this.$t('ping.ltpWhJb7kpeZyTkdn7gm'),
						time: this.$t('ping.ltpWhJb7kpeZyTkdn7gm'),
						status: this.$t('ping.ltpWhJb7kpeZyTkdn7gm'),
						stable : this.$t('ping.ltpWhJb7kpeZyTkdn7gm'),	
					};
					updateConnectivity({
						connectivity: 'NONE',
					});	
					clearTimeout(timer);
					timer = null;
				}, 3000);
			}
		},
		toFeedback() {
			// 数据上报
			reportByPikPak({
				hubbleEventId: 'android_my_tab',
				hubbleAttribute: 'connectivity_page_click',
				hubbleExData: this.reportData,
			});
			window.location.replace('https://mypikpak.com/drive/feedback');
		}
	}
}
</script>

<style lang="less" scoped>
.ping-wrapper {
	max-width: 948PX;
	margin: 0 auto;
	padding: 48px;
	padding-bottom: 360px;
	font-size: 40px;

	.animation-wrapper {
		position: relative;
		display: flex;
		margin: 50px 0;
		justify-content: center;
	}
	.animation-box {
		width: 500px;
		height: 500px;
		animation: rotate 2s infinite forwards linear;

		.bg {
			position: absolute;
			width: 100%;
			height: 100%;
			background: url("../assets/images/ping/background.webp") no-repeat
				center/cover;
		}

		.circle {
			position: absolute;
			z-index: 2;
			width: 100%;
			height: 100%;
			background: url("../assets/images/ping/circle.webp") no-repeat
				center/cover;
		}
	}

	@keyframes rotate {
		from {
			transform: rotate(0);
		}
		to {
			transform: rotate(360deg);
		}
	}

	.status-text {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		top: 50%;
		transform: translateY(-50%);
		width: 280px;
		height: 160px;
		font-size: 39px;
		text-align: center;
	}

	.network-info {
		margin: 60px 0;

		&__item {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 18px;

			.title {
				flex: 1;
				text-align: right;
				color: #ABABAB;
			}

			.value {
				flex: 1;
				padding-left: 20px;
			}

			.empty {
				color: #ABABAB;
			}
		}
	}

	.describe-box {
		margin-top: 120px;
		.describe-text {
			margin-top: 36px;
			line-height: 60px;
		}	
	}

	.button-box {
		position: fixed;
		width: 100%;
		max-width: 948PX;
		padding: 48px;
		background: #fff;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);
		box-sizing: border-box;
		.commit-button {
			width: 100%;
			height: 126px;
			line-height: 126px;
			border-radius: 12px;
			text-align: center;
			color: #fff;
			background: #306eff;
			font-size: 42px;

			&--disable {
				opacity: 0.5;
			}
		}
		.button-tips {
			font-size: 33px;
			color: #306eff;
			text-align: center;
			margin-top: 39px;
		}
	}
}
</style>