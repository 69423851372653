<template>
	<div class="content-wrapper">
			<div class="content-box">
					<img src="@/assets/images/notices/error-banner.png" alt="error-banner" class="banner">
	
					<div class="content-item">
						사랑스로운 픽팍 한국 사용자 여러분:
					</div>
					<div class="content-item">
						2022년 8월 픽팍이 한국 시장에 진입한 지 1주년이 되었고, 픽팍의 전 세계 사용자 수도 500만 명을 넘었습니다. 픽팍은 처음에는 몇 명의 한국 회원만 있었지만 지금은 많은 한국 사용자들의 신뢰를 얻게 되어, 한국 사용자들과 가볍게 가까이 소통을 할 수 있어 기쁩니다. 픽팍에 대한 성심한 전문적인 피드백을 언제든지 듣고 있습니다. 이는 우리에게 매우 소중합니다.
					</div>
					<div class="content-item">
						지난 1년 동안 여러분의 지원에 감사드리며, 픽팍은 한국 사용자들에게만 전용 20% 할인 구매를 제공하기로 결정했습니다. 귀하는 2023년 8월 25일까지 매우 낮은 가격으로 픽팍의 글로벌 연간 프리미엄 서비스를 구매할 수 있습니다.여러분 즐길 수 있으면 좋겠습니다.
					</div>
					<div class="content-item">
						픽팍은 항상 사용자의 만족스러운 체험을 제공하기 위해 노력하고 있으며, 다시 한번 귀하의 지원과 동행에 감사드립니다. 계속 함께 걸어갈 수 있기를 바랍니다. 감사합니다!
					</div>
					<div class="content-item">
						픽팍 전체 직원
						<br/><br/>
						여러분 모두 행복하세요.
					</div>
			</div>
			<div class="join-group-button" @click="handleButtonClick">20% 설레 할인 받기</div>
	</div>
</template>

<script>
export default {
	mounted() {
			document.title = '20% 빅세일 기념 행사 설명'; 
	},
	methods: {
			handleButtonClick() {
					window.location.href = 'pikpakapp://mypikpak.com/account/pay_activity?refer_from=ko-anniversary';
			}
	}
}
</script>

<style lang="less" scoped>
.content-wrapper {
	display: flex; 
	flex-direction: column;
	align-items: center;
	overflow-y: scroll;
	height: 100vh;

	.content-box {
		height: calc(100vh - 100Px);
		overflow-y: scroll;
	}

	.banner {
			display: block;
			width: 154Px;
			height: 110Px;
			margin: 32Px auto;
	}

	.content-item {
			font-size: 17Px;
			padding: 0 16Px;
			margin-bottom: 24Px;
	}

	.join-group-button {
			position: fixed;
			bottom: 32Px;
			width: calc(100vw - 32Px);
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: 54Px;
			background: #306EFF;
			font-size: 17Px;
			color: #fff;
			cursor: pointer;
			border-radius: 8Px;
	}

	.date-info {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			padding-right: 16Px;
	}
}
</style>