<template>
	<div class="introduce-wrap">
		<div class="introduce-box" @click="callApp('collect')">
			<img class="introduce-box__icon" src="@/assets/images/toapp/collection.webp" alt="collection" />
			<div class="introduce-box__text">
				{{ $t("middleware.collectionPlatform") }}
			</div>
		</div>
		<div class="introduce-box" @click="callApp('collect')">
			<img class="introduce-box__icon" src="@/assets/images/toapp/ten-T.webp" alt="collection" />
			<div class="introduce-box__text">
				{{ $t("middleware.supportSpace") }}
			</div>
		</div>
		<div class="introduce-box" @click="callApp('short_video')">
			<img class="introduce-box__icon" src="@/assets/images/toapp/short-video.webp" alt="collection" />
			<div class="introduce-box__text">
				{{ $t("middleware.shortVideoMode") }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'IntroduceWrapper',
	props: {
		callApp: {
			type: Function,
			required: true
		}
	},
}
</script>

<style lang="less" scoped>
.introduce-wrap {
	margin: 0 12PX;
	margin-top: 65PX;
	display: flex;	
	justify-content: center;


	.introduce-box {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		max-width: 130PX;
		height: 138PX;
		background: #F4F8FF;
		border-radius: 7PX;
		margin: 0 5PX;

		&__icon {
			width: 54PX;
			height: 54PX;
		}

		&__text {
			margin-top: 24PX;
			font-size: 12PX;	
			color: #8695B9;
		}
	}
}
</style>