<template>
	<div class="agreement">
    <p>&nbsp;</p>
    <h1><strong>PikPak</strong> <strong>User Agreement</strong></h1>
    <p>Update date: 2023-01-28</p>
    <br>
    <p>Important Notes.</p>
    <p>
      <strong>A. PIKPAK HEREBY SPECIFICALLY REMINDS USERS TO CAREFULLY READ THIS PIKPAK CLOUD DRIVE SERVICE AGREEMENT
        (HEREINAFTER REFERRED TO AS &quot;AGREEMENT&quot;), INCLUDING THE DISCLAIMERS THAT EXCLUDE OR LIMIT
        PIKPAK&#39;S LIABILITY AND THE LIMITATIONS ON USERS&#39; RIGHTS.</strong> Please review and accept or
      reject this Agreement (<strong>YOU MUST BE AT LEAST 13, OR OLDER WHICH DEPENDING ON WHERE YOU LIVE</strong>).
      Unless you accept the terms of this Agreement, you are not authorized to use the services related to this
      Agreement. <strong>YOUR REGISTRATION AND USE WILL BE DEEMED ACCEPTANCE OF THIS AGREEMENT AND YOU AGREE TO BE
        BOUND BY THE TERMS OF THIS AGREEMENT.</strong> This Agreement is an agreement between the User
      and PikPak regarding the User&#39;s registration as a user of PikPak Cloud Drive and the use of PikPak&#39;s
      related software and services (collectively as "Services"). This Agreement describes the rights and obligations
      between PikPak and the User with respect to the licensing of the Services and related aspects. &quot;User&quot;
      or &quot;You&quot; means the individual or single entity that obtains a license and/or service for the Services
      through the means provided by PikPak.
    </p>
    <p>
      <strong>B.</strong> <strong>PIKPAK SERVICES PROVIDES USERS WITH PRIVATE INFORMATION STORAGE SPACE SERVICES,
        PRIVATE INFORMATION STORAGE SPACE INCLUDING DATA STORAGE, SYNCHRONIZATION, MANAGEMENT
        AND OTHER SERVICES, PIKPAK SERVICES ITSELF DOES NOT DIRECTLY UPLOAD OR PROVIDE
        CONTENT, AND DOES NOT MAKE ANY CHANGES OR EDITS TO THE CONTENT TRANSMITTED BY USERS.
        AS A PROVIDER OF INFORMATION STORAGE SPACE SERVICES, PIKPAK SERVICES HAS NO CONTROL
        OVER THE CONTENT TRANSMITTED VIA THIS SERVICE AND CANNOT GUARANTEE THE LEGALITY,
        ACCURACY, INTEGRITY AND AUTHENTICITY OF THE CONTENT.</strong>
    </p>
    <p>
      <strong>C.</strong> <strong>IF THE USER VIOLATES THIS AGREEMENT, PIKPAK WILL TAKE ALL NECESSARY MEASURES
        INCLUDING BUT NOT LIMITED TO CHANGING OR DELETING THE CONTENT OR INFORMATION,
        FREEZING OR CLOSING THE ACCOUNT, SUSPENDING OR TERMINATING THE USER&#39;S USE OF THE
        SERVICE, ETC. WITHOUT GIVING THE USER ANY COMPENSATION OR INDEMNITY.</strong>
    </p>
    <p v-if="isApplePlatform"><strong>D.</strong> <strong>YOU ACKNOWLEDGE THAT THIS AGREEMENT IS CONCLUDED BETWEEN YOU AND PIKPAK ONLY, AND NOT
        WITH APPLE, AND UNDER NO CIRCUMSTANCES WILL APPLE BE RESPONSIBLE FOR THE SERVICES AND THE CONTENT IN PIKPAK. IN
        THE EVENT THERE HAVE ANY CONFLICT BETWEEN THIS AGREEMENT AND THE APPLE MEDIA SERVICES TERMS AND CONDITIONS AS OF
        THE EFFECTIVE DATE, THE APPLE MEDIA SERVICES TERMS AND CONDITIONS SHALL PREVAIL.</strong>
    </p>
    <p />
    <h1>1. Confirmation and Acceptance </h1>
    <p>
      1.1 The Services are only available to individuals aged 13 years or older (or equivalent minimum age in the
      relevant jurisdiction). We do not knowingly collect, use or disclose personal information from children under 13,
      or equivalent minimum age in the relevant jurisdiction, without verifiable parental consent. Parents and
      guardians should also remind any minors that conversing with strangers on the Internet can be dangerous and take
      appropriate precautions to protect children, including monitoring their use of the Services.
    </p>
    <p>
      1.2 Upon your confirmation and acceptance of this Agreement, PikPak agree to grant you a limited, non-exclusive,
      non-transferable and revocable license during the term and under the restrictions listed in the respective terms
      of services to use the Services. The Services provided by PikPak shall be strictly enforced in accordance with
      this Agreement. The User shall be bound by this Agreement and the individual software and service related user
      agreements when enjoying the Services. By entering the registration process, the User and PikPak have agreed to
      voluntarily accept all of the contents of these terms of service.
    </p>
    <p>
      1.3 The ownership and operation of the Services belong to PikPak. PikPak has the right to request the member to
      correct its inappropriate behavior and hold him/her responsible.
    </p>
    <p>
      1.4 When User uses each individual service of PikPak, there may be separate terms of service governing these
      services, which the User is requested to understand and confirm separately when using the relevant service, and
      the User&#39;s use is deemed to be his or her agreement to the terms of service of that individual service and to
      the various announcements issued by PikPak in that individual service.
    </p>
    <h1>2. Account Registration and Usage</h1>
    <p>
      2.1 Users who complete the registration process through the PikPak registration system and obtain a PikPak
      account are official users of PikPak, i.e., they are granted all permissions that PikPak stipulates for the basic
      functions that users should enjoy; PikPak has the right to make changes to the design of its official user
      permissions. When the User applies for the membership service and fills out the registration information, the
      User must ensure that the information filled out is true and valid. PikPak is not responsible for any problems
      caused by untrue registration information and for the consequences arising from the occurrence of the problem.
      Users are not allowed to use the following contents as their account name when registering their account, and if
      a User&#39;s account is found to contain indecent words or inappropriate names, PikPak reserves the right to
      cancel their user status:
    </p>
    <p>
      2.1.1 Please do not register under the real name, stage name or pseudonym of leaders or other social
      celebrities.
    </p>
    <p>2.1.2 Please do not register under the name of a government agency or other institution.</p>
    <p>
      2.1.3 Please do not register accounts with uncivilized or unhealthy names, or accounts containing discriminatory,
      insulting or obscene words.
    </p>
    <p>
      2.1.4 Please do not register accounts that are susceptible to ambiguity, misunderstanding or otherwise do not
      comply with laws, regulations, rules and other provisions.
    </p>
    <p>
      2.2 The PikPak account and all virtual assets in the account are owned by PikPak, and the User gets the limited
      right to use the PikPak account after completing the application registration procedure.
    </p>
    <p>
      2.3 <strong>THE RIGHT TO USE THE PIKPAK ACCOUNT BELONGS TO THE INITIAL APPLICANT REGISTRANT ONLY AND IS
        PROHIBITED TO BE GIVEN, BORROWED, RENTED, TRANSFERRED, SHARED OR SOLD TO ANY THIRD PARTY. THE SAME
        ACCOUNT CAN ONLY BE USED ON 6 DEVICES, AND NO MORE THAN 3 DEVICES CAN BE USED AT THE SAME TIME. IF
        PIKPAK DISCOVERS THAT THE USER IS NOT THE INITIAL REGISTRANT OF THE ACCOUNT, PIKPAK RESERVES THE
        RIGHT TO RECALL THE ACCOUNT WITHOUT NOTICE AND WITHOUT LIABILITY TO THE ACCOUNT USER, AND THE USER
        SHALL BE RESPONSIBLE FOR ANY RESULTING DAMAGES, INCLUDING AND NOT LIMITED TO THE DELETION OF THE USER&#39;S
        DATA, ETC.</strong> PikPak prohibits the user from privately transferring the account and its virtual
      items for compensation or without compensation, in order to avoid disputes arising from the account. PikPak
      reserves the right to pursue legal liability for any loss incurred as a result of violating this requirement.
    </p>
    <p>
      2.4 The User assumes responsibility for the safekeeping of the PikPak account and password and is solely
      responsible for all activities under the account and password. Users are responsible for password protection of
      their PikPak accounts and public email addresses. The User agrees to notify PikPak immediately of any
      unauthorized use of his/her account.
    </p>
    <p>
      2.5 In the event that a User&#39;s PikPak account is lost or forgotten, the User shall follow PikPak&#39;s
      complaint channels to request the recovery of the account in a timely manner. Users shall continuously provide
      password protection information that increases the security of their account. PikPak&#39;s password recovery
      mechanism is only responsible for identifying the correctness of the information provided on the complaint form
      and the information recorded in the system, and cannot identify whether the complainant is the real user of the
      account. PikPak is not responsible for any loss caused by the user&#39;s impersonation of another person, and the
      user is aware that the responsibility for keeping the PikPak account and password rests with the user, and PikPak
      does not promise that the user will be able to retrieve the account through a complaint if the PikPak account is
      lost or forgotten.
    </p>
    <p>
      2.7 In order to effectively use PikPak account resources and protect the rights and interests of more legitimate
      users, the system has the right to recall PikPak accounts that are registered with malicious intent or have not
      been logged in for more than twelve consecutive months, and the user shall be responsible for any resulting
      losses.
    </p>
    <p>
      2.8 PikPak reserves the right to interrupt or terminate the Services provided to the User without notice in the
      event of any of the following circumstances.
    </p>
    <p>
      (1) The user is required by law to submit true information, and the personal information provided by the user is
      not true, or is inconsistent with the information at the time of registration and fails to provide reasonable
      proof.
    </p>
    <p>(2) The user violates the relevant laws and regulations or the agreement of this Agreement.</p>
    <p>(3) In accordance with the provisions of the law or the requirements of the competent authorities.</p>
    <p>
      (4) For security reasons or other necessary circumstances. Upon termination of the Services provided to the User,
      PikPak reserves the right to permanently delete the data stored by the User on the server and is not obliged to
      return the data to the User.
    </p>
    <p />
    <h1>3. User Usage Rules</h1>
    <p>
      3.1 Some of the Services provided by PikPak are paid network services, and users are required to pay certain fees
      to PikPak for using the paid network services. For paid network services, PikPak will give clear instructions to
      the user before the user uses the service, and the user may use the paid network services only if the user
      confirms that he/she is willing to pay the relevant fees according to the instructions. If the user refuses to
      pay the relevant fees, PikPak reserves the right not to provide the user with such paid network services.
    </p>
    <p>
      3.2 The User understands that PikPak only provides the Services, except for the equipment related to the Services
      (such as personal computers, cell phones, and other devices related to access to the Internet or mobile networks)
      and the costs required (such as telephone and Internet access fees paid for access to the Internet and cell phone
      fees paid for the use of mobile networks), which shall be borne by the User.
    </p>
    <p>
      3.3 <strong>USERS MAY USE PIKPAK&#39;S SERVICES IN ACCORDANCE WITH THIS AGREEMENT SUBJECT TO COMPLIANCE WITH THE
        LAW AND THIS AGREEMENT. USERS ARE NOT AUTHORIZED TO DO ANY RESTRICTED ACTS, INCLUDING BUT NOT LIMITED
        TO</strong>:
    </p>
    <p>
      3.3.1 <strong>REMOVE ALL INFORMATION, CONTENT REGARDING COPYRIGHT ON PIKPAK&#39;S SOFTWARE AND OTHER
        COPIES.</strong>
    </p>
    <p>
      3.3.2 <strong>REVERSE ENGINEERING, REVERSE ASSEMBLY, REVERSE COMPILATION, ETC. OF PIKPAK&#39;S SOFTWARE.</strong>
    </p>
    <p>
      3.3.3 <strong>FOR INFORMATION RELATED TO PIKPAK&#39;S SERVICES, PERFORM THE FOLLOWING ACTS INCLUDING BUT NOT
        LIMITED TO: CRAWLING, USING, COPYING, MODIFYING, LINKING, REPRODUCING, COMPILING, PUBLISHING,
        ESTABLISHING MIRROR SITES, DEVELOPING DERIVATIVE PRODUCTS, WORKS AND SERVICES RELATED TO PIKPAK&#39;S
        SOFTWARE WITHOUT PIKPAK'S PRIOR PERMISSION.</strong>
    </p>
    <p>
      3.3.4 <strong>ENGAGE IN ANY CONDUCT THAT JEOPARDIZES THE SECURITY OF THE COMPUTER NETWORK, INCLUDING BUT NOT
        LIMITED TO: USING UNAUTHORIZED DATA OR ACCESSING UNAUTHORIZED SERVERS/ACCOUNTS; ACCESSING THE
        PUBLIC COMPUTER NETWORK OR ANOTHER PERSON&#39;S COMPUTER SYSTEM WITHOUT PERMISSION AND DELETING,
        MODIFYING OR ADDING STORED INFORMATION AND PIKPAK SECURITY MEASURES; ATTEMPTING TO PROBE, SCAN,
        TEST THE VULNERABILITY OF THE SOFTWARE SYSTEM OR NETWORK OR OTHERWISE COMMITTING ACTS THAT
        UNDERMINE ATTEMPT TO INTERFERE WITH OR DISRUPT THE NORMAL OPERATION OF PIKPAK&#39;S SOFTWARE SYSTEM
        OR WEBSITE; INTENTIONALLY SPREAD MALICIOUS PROGRAMS OR VIRUSES, OR OTHERWISE DISRUPT OR INTERFERE
        WITH NORMAL NETWORK INFORMATION SERVICES; FORGE TCP/IP PACKET NAMES OR PARTIAL NAMES; OTHER ACTS
        THAT ENDANGER THE SECURITY OF COMPUTER INFORMATION NETWORKS.</strong>
    </p>
    <p>3.3.5 <strong>USE PIKPAK&#39;S SERVICES TO MISLEAD OR DECEIVE OTHERS;</strong></p>
    <p>3.3.6 <strong>INTERFERE IN ANY WAY WITH PIKPAK&#39;S SERVICES.</strong></p>
    <p>
      3.4 <strong>PIKPAK SHALL NOT BE LIABLE TO USER FOR ANY LOSSES ARISING OUT OF OR RELATING TO USER'S FAILURE TO
        COMPLY WITH ITS OBLIGATIONS SET FORTH IN PARAGRAPH 3.3.</strong>
    </p>
    <p>
      3.5 <strong>USERS MUST USE PIKPAK&#39;S SERVICES IN ACCORDANCE WITH THE REGULATIONS AND OTHER RELEVANT NATIONAL
        AND REGIONAL LEGAL PROVISIONS AND THE RELEVANT PROVISIONS OF INTERNATIONAL LAW. USERS MAY NOT USE
        PIKPAK&#39;S SERVICES TO CREATE, PUBLISH, REPRODUCE, TRANSMIT, DISTRIBUTE, OR STORE INFORMATION
        THAT</strong>
    </p>
    <p><strong>(A) VIOLATE THE LAW.</strong></p>
    <p>
      <strong>(B) ENDANGERING NATIONAL SECURITY, REVEALING STATE SECRETS, SUBVERTING STATE POWER, AND UNDERMINING
        NATIONAL UNITY.</strong>
    </p>
    <p><strong>(C) DAMAGE TO NATIONAL HONOR AND INTERESTS.</strong></p>
    <p><strong>(D) INCITING ETHNIC HATRED, ETHNIC DISCRIMINATION AND UNDERMINING NATIONAL UNITY.</strong></p>
    <p>
      <strong>(E) UNDERMINING NATIONAL RELIGIOUS POLICIES AND PROMOTING EVIL CULTS AND FEUDAL SUPERSTITIONS.</strong>
    </p>
    <p><strong>(F) THOSE WHO SPREAD RUMORS TO DISTURB THE SOCIAL ORDER AND DESTABILIZE THE SOCIETY.</strong></p>
    <p><strong>(G) DISSEMINATION OF OBSCENITY, PORNOGRAPHY, GAMBLING, VIOLENCE, TERROR OR ABETTING CRIME.</strong></p>
    <p><strong>(H) INSULTING OR DEFAMING OTHERS AND INFRINGING ON THEIR LEGITIMATE RIGHTS AND INTERESTS.</strong></p>
    <p>
      <strong>(I) INCITING ILLEGAL ASSEMBLIES, ASSOCIATIONS, PROCESSIONS, DEMONSTRATIONS, AND GATHERINGS TO DISRUPT
        SOCIAL ORDER.</strong>
    </p>
    <p><strong>(J) THOSE OPERATING UNDER THE NAME OF AN ILLEGAL CIVIL ORGANIZATION.</strong></p>
    <p>
      <strong>(K) INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, TRADE SECRETS OR OTHER LEGAL RIGHTS OF OTHERS.</strong>
    </p>
    <p><strong>(L) CONTAINING OTHER CONTENT PROHIBITED BY LAWS AND ADMINISTRATIVE REGULATIONS.</strong></p>
    <p>
      3.6 The User shall be legally responsible for his or her actions in the course of using the PikPak Services.
      User&#39;s
      legal liability shall take the form of, but not limited to, compensation to those who have been aggrieved, as
      well as an equal amount of compensation to PikPak after PikPak has first assumed liability for administrative
      penalties or tort damages resulting from User&#39;s actions. The User understands that if PikPak discovers that
      its website transmits information that clearly belongs to one of the contents listed in paragraph 3.5 above,
      PikPak is obliged by law to immediately stop the transmission, to keep records of it, to report it to the
      supervisory authorities, and to remove the address or directory containing the content or to shut down the
      server.
    </p>
    <p>
      3.7 User&#39;s use of the PikPak Services to post information is also subject to this Agreement. If a User&#39;s
      conduct does not comply with this Agreement, PikPak may, at its sole discretion, immediately cancel the User&#39;s
      account for the Services.
    </p>
    <p>
      3.8 Any other software derived from PikPak software that is not developed and officially released by PikPak or
      authorized by PikPak is illegal, and downloading, installing or using such software may lead to unpredictable
      risks, and all legal responsibilities and disputes arising therefrom are irrelevant to PikPak. Users should not
      download, install or use such software lightly, otherwise, PikPak has the right to unilaterally terminate the
      user&#39;s PikPak account without prior notice to the user.
    </p>
    <p>
      3.9 PikPak reserves the right to provide the User with replacement, modified, or upgraded versions of the
      Services at any time. In order to continue your use of the Services, such updates may be automatically downloaded
      and installed onto your device. These updates may include bug fixes, feature enhancements or improvements, or
      entirely new versions of the Services.
    </p>
    <h1>4. Protection of Intellectual Property Rights</h1>
    <p>
      4.1 User has and retains exclusive ownership of all its uploaded content on PikPak's Services and all
      intellectual property and proprietary rights therein. PikPak has and retains exclusive ownership of the PikPak
      Services and all intellectual property and proprietary rights therein.
    </p>
    <p>
      4.2 User should ensure that the content complies with intellectual property laws and regulations when
      distributing content via the internet. PikPak is not responsible for the legality of any links or content
      obtained directly or indirectly through PikPak Services, and PikPak cannot and will not screen such links or
      content; you understand and agree that access to and use of content for which others have intellectual property
      rights is entirely at your own discretion and risk, and PikPak has no responsibility for it.
    </p>
    <p>
      4.3 All contents uploaded by Users on the Services does not reflect and does not represent the opinion of any
      PikPak. PikPak does not control the content posted via the Services, nor does it guarantee the accuracy,
      integrity or quality of such content. You understand and agree that your use of the Services and any content is
      solely at your own risk.
    </p>
    <p>
      4.4 To the avoidance of doubt, User has no obligation to provide its suggestion to PikPak; however, if User
      suggests, or provides its findings, inventions, improvements, discoveries, or ideas or know-how in any form
      (collectively the "Suggestions") to PikPak, then PikPak, at its sole option, has the right to post and use it.
      For such Suggestions, User warrants that 1) they will not be subject to a license requiring PikPak to license
      anything to third parties; and 2) they are owned or otherwise controlled by User and not subject to any
      third-party rights (including any personality or publicity rights). User (and anyone providing Suggestions
      through User) hereby irrevocably and perpetually grants to PikPak and its affiliates a worldwide, non-exclusive,
      fully paid-up, royalty-free, transferable, sub-licensable right and license to make, use, reproduce, prepare
      derivative works based upon, distribute, publicly perform, publicly display, transmit, and otherwise
      commercialize the Suggestions (including by combining or interfacing products, services or technologies that
      depend on or incorporate Suggestions with other products, services or technologies of PikPak or others), without
      attribution in any way and for any purpose. Any further development conducted by PikPak solely or jointly with
      User based on the Suggestions will be and remain the sole property of PikPak and may be used, sold, licensed, or
      otherwise provided by PikPak to third parties, or published or otherwise publicly disclosed, in PikPak's sole
      discretion without notice, attribution, payment of royalties, or liability to User.
    </p>
    <p>4.5 PikPak owns all anonymized statistical information regarding User's access of the Services. </p>
    <p>
      4.6 PikPak handles notices of claimed infringement that comply with intellectual property laws. Any entity or
      individual who believes that a link or content shared by a User may be suspected of infringing its legal rights
      and interests should provide written feedback to PikPak in a timely manner, and should also provide proof of
      identity, proof of ownership and proof of detailed infringement. Please send the feedback to us through:
    </p>
    <p>&nbsp;</p>
    <p>
      Our email address: <a href="mailto:support@mypikpak.com" target="_blank" class="url">support@mypikpak.com</a>
    </p>
    <p>&nbsp;</p>
    <h1>5. Intellectual Property Indemnifications</h1>
    <p>
      5.1 PikPak shall have no liability for, and User will indemnify PikPak, its affiliates, and their respective
      directors, officers, employees, and agents, against any claim arising from (a) any content you submit, post,
      transmit, or otherwise make available through the Services; (b) your use of the Services; (c) any violation by
      you of this Agreement; (d) any action taken by PikPak as part of its investigation of a suspected violation of
      this Agreement or as a result of its finding or decision that a violation of this Agreement has occurred; or (e)
      your violation of any rights of another. This means that you cannot sue PikPak, its affiliates, subsidiaries,
      directors, officers, employees, agents, partners, contractors, and licensors as a result of its decision to
      remove or refuse to process any information or content, to warn you, to suspend or terminate your access to the
      Services, or to take any other action during the investigation of a suspected violation or as a result of
      PikPak's conclusion that a violation of this Agreement has occurred. This waiver and indemnity provision applies
      to all violations described in or contemplated by this Agreement. This obligation shall survive the termination
      or expiration of this Agreement and/or your use of the Services. You acknowledge that you are responsible for all
      use of the Services using your account, and that this Agreement applies to any and all usage of your account. You
      agree to comply with this Agreement and to defend, indemnify and hold harmless PikPak from and against any and
      all claims and demands arising from usage of your account, whether or not such usage is expressly authorized by
      you.
    </p>
    <h1>6. Representation and Warranties; Disclaimer </h1>
    <p>
      6.1 The User expressly agrees that the use of the PikPak Services is at the User&#39;s own risk and the express
      warranties and express representations of PikPak set forth in this Agreement are in lieu of, and PikPak expressly
      disclaims any and all other warranties, conditions, or representations (express or implied, oral or written),
      with respect to the Services hereunder any part of the foregoing, including any and all implied warranties or
      conditions of merchantability, fitness for a particular purpose and non-infringement, and PikPak does not warrant
      that the Services will meet the User&#39;s requirements or that the Services will be uninterrupted. PikPak does
      not guarantee the timeliness, security, authenticity, or the occurrence of errors in the Services.
    </p>
    <p>
      6.2 PikPak will endeavor to maintain the security and convenience of all Services on the PikPak account, but will
      not be responsible for the deletion or storage failure of information (including, but not limited to, information
      posted by the User) that occurs on the Services. In addition, we reserve the right to determine whether a
      User&#39;s
      conduct complies with the Agreement, and PikPak reserves the right to discontinue Services to the User&#39;s
      PikPak account if the user violates the provisions of the Agreement.
    </p>
    <p>
      6.3 If any legislation states that there is a guarantee in relation to any Services supplied by PikPak in
      connection with this Agreement, then PikPak's liability for such failure is limited (at the PikPak's election) to
      PikPak supplying the Services again or paying the cost of having the Services supplied again.
    </p>
    <p>
      6.4 Certain content, components or features of the Services may include materials from third parties and/or
      hyperlinks to other web sites, resources or content. Because PikPak may have no control over such third party
      sites and/or materials, you acknowledge and agree that PikPak is not responsible for the availability of such
      sites or resources, and does not endorse or warrant the accuracy of any such sites or resources, and shall in no
      way be liable or responsible for any content, advertising, products or materials on or available from such sites
      or resources. You further acknowledge and agree that PikPak shall not be responsible or liable in any way for any
      damages you incur or allege to have incurred, either directly or indirectly, as a result of your use and/or
      reliance upon any such content, advertising, products or materials on or available from such sites or
      resources.
    </p>
    <h1>7. Legal responsibility and exemption from liability</h1>
    <p>
      7.1 <strong>EXCEPT WITH RESPECT TO USER'S ACCESS TO OR DISCLOSURE OF THE SERVICES OTHER THAN IN COMPLIANCE WITH
        THE LICENSE GRANTED IN THIS AGREEMENT, NEITHER PARTY WILL BE LIABLE TO THE OTHER PARTY (NOR TO ANY
        PERSON CLAIMING RIGHTS DERIVED FROM THE OTHER PARTY'S RIGHTS) FOR INCIDENTAL, INDIRECT,
        CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES OF ANY KIND (INCLUDING LOST PROFITS, LOSS OF
        OR DAMAGE TO DATA, LOSS OF BUSINESS, OR OTHER ECONOMIC DAMAGE), WHETHER ARISING IN CONTRACT, TORT
        (INCLUDING NEGLIGENCE) OR OTHERWISE, AND REGARDLESS OF WHETHER THE PARTY LIABLE OR ALLEGEDLY LIABLE
        WAS ADVISED, HAD OTHER REASON TO KNOW, SHOULD HAVE ANTICIPATED, OR IN FACT KNEW OF THE POSSIBILITY
        THEREOF. IN NO EVENT SHALL EITHER PARTY BE LIABLE FOR COSTS OF PROCUREMENT OF SUBSTITUTE GOODS BY
        ANYONE</strong>.
    </p>
    <p>
      7.2 <strong>EXCEPT WITH RESPECT TO USER'S ACCESS TO OR DISCLOSURE OF THE SERVICES OTHER THAN IN COMPLIANCE WITH
        THE LICENSE GRANTED IN THIS AGREEMENT, IN NO EVENT SHALL PIKPAK'S AGGREGATE LIABILITY TO THE USER
        (INCLUDING LIABILITY TO ANY PERSON OR PERSONS WHOSE CLAIM OR CLAIMS ARE BASED ON OR DERIVED FROM A
        RIGHT OR RIGHTS CLAIMED BY OR THROUGH SUCH PARTY), WITH RESPECT TO ANY CLAIM ARISING FROM OR RELATED
        TO THE SUBJECT MATTER OF THIS AGREEMENT, IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), OR
        OTHERWISE, EXCEED THE FEES PAID UNDER THIS AGREEMENT DURING THE 12 MONTHS IMMEDIATELY PRECEDING THE
        ACT GIVING RISE TO SUCH CLAIM.</strong>
    </p>
    <p>
      7.3 Each party acknowledges that the provisions of this Agreement were negotiated, as a material part of the
      agreement memorialized herein, to reflect an informed, voluntary allocation between them of all risks (both known
      and unknown) associated with the transactions involved with this Agreement. The warranty disclaimers and
      limitations in this Agreement are intended, and have as their essential purpose, to limit the circumstances of
      liability. The remedy limitations and the limitations of liability are separately intended, and have as their
      essential purpose, to limit the forms of relief available to the parties.
    </p>
    <h1>8. Prohibition of Commercialization of Services</h1>
    <p>
      8.1 The User&#39;s right to use the various PikPak Services is personal. Except as expressly authorized in
      writing by PikPak, the User may only be a single individual and not a company or entity of a commercial nature.
      The User undertakes not to use the PikPak Services for sales or other commercial purposes without the consent of
      PikPak.
    </p>
    <h1>9. Change and Revision of the Agreement</h1>
    <p>
      9.1 PikPak reserves the right to amend this Agreement and the relevant terms of each individual service as
      necessary. When enjoying the individual service, the User shall promptly review the modified contents and
      consciously comply with this Agreement and the relevant terms of the individual service.
    </p>
    <p>
      9.2 PikPak reserves the right to modify or discontinue the Services at any time without notice to the User. The
      User accepts that PikPak shall not be liable to the User or to third parties for the exercise of PikPak&#39;s
      right to modify or discontinue the Service.
    </p>
    <h1>10. Confidentiality </h1>
    <p>
      8.1 It is a fundamental policy of PikPak to respect the privacy of its users. PikPak shall not disclose, edit or
      disclose or transfer its registration data and non-public content stored in the PikPak Services without the
      User&#39;s
      authorization, unless PikPak believes in good faith that disclosure or transfer of such information are
      necessary:
    </p>
    <p>
      8.1.1 Comply with the relevant laws and regulations and disclose the personal information of users when
      authorized by law or the authority, litigation or mandatory requirements of government authorities.
    </p>
    <p>
      8.1.2 In the event of an acquisition, merger, reorganization, or bankruptcy involving the transfer of personal
      information, PikPak will require the new legal entity or organization holding your personal information to
      continue to be bound by this contract, or we will require that legal entity or organization to seek your
      authorized consent again.
    </p>
    <p>8.1.3 There are reasonable grounds to disclose the User&#39;s personal information to the public.</p>
    <h1>11. Security, Privacy, and data protection</h1>
    <p>
      11.1 PikPak shall comply with the Privacy Policy and shall use reasonable efforts to cause any subcontractor or
      agent having access to or maintaining any personal data to do so. To better understand your rights on your
      personal data, please kindly check our Privacy Policy. <a href="/policy/privacy-policy" target="_blank"
        class="url">https://mypikpak.com/policy/privacy-policy</a>
    </p>
    <h1>12. Governing Law and Jurisdiction</h1>
    <p>
      12.1 The laws of Singapore shall apply to the interpretation and validity of this Agreement and the resolution of
      disputes. In the event of any dispute or controversy between the User and PikPak, the dispute or controversy
      shall first be resolved by amicable negotiation, and if such negotiation fails, the User hereby fully agrees to
      submit the dispute or controversy to the jurisdiction of the Singapore courts only. Provided however, if you
      reside in a country with laws that give consumers the right to bring disputes in their local courts, this
      paragraph doesn't affect those requirements.
    </p>
    <p>12.2 United Nations Convention on the International Sale of Goods does not applicable for this Agreement.</p>
    <h1>13. Other Provisions</h1>
    <p>
      13.1 Neither party shall be liable for any failure to perform its obligations under this Agreement if such
      failure arises, directly or indirectly, out of causes reasonably beyond the direct control of such party and not
      due to such party's own fault or negligence or that of its contractors or representatives or other persons acting
      on its behalf, and which cannot be overcome by the exercise of due diligence and which could not have been
      prevented through commercially reasonable measures, including acts of God, acts of terrorists or criminals, acts
      of domestic or foreign governments, change in any law or regulation, fires, floods, explosions, epidemics,
      disruptions in communications, power, or other utilities, strikes or other labor problems, riots, or
      unavailability of supplies, computer viruses, hacker attacks, system instability, user shutdown and any other
      network, technology, communication lines shutdown, etc.
    </p>
    <p>
      13.2 This Agreement will be binding upon and inure to the benefit of the parties and their successors and assigns
      permitted by this Agreement.
    </p>
    <p>
      13.3 Except as otherwise expressly set forth herein, nothing in this Agreement is intended to confer, nor must
      anything herein confer, upon any person other than you, any rights, remedies, obligations, or liabilities
      whatsoever.
    </p>
    <p>
      13.4 This Agreement constitutes the entire agreement between the parties concerning the subject matter hereof.
      You understand no prior or contemporaneous representations, inducements, promises, or agreements, oral or
      otherwise, between the parties with reference thereto will be of any force or effect.
    </p>
    <p>
      13.5 You may use our Services only as permitted by applicable law, including export control laws and regulations. Especially, you must represent and warrant that (i) you are not located in a country or region that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country, the above countries or regions include but not limited to Crimea, Cuba, So-called Donetsk People's Republic (DNR) and Luhansk People's Republic (LNR), Iran, North Korea, and Syria; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties; (iii) other counties or regions that we are currently not providing Services to, such as the People's Republic of China.
    </p>
    <p>
      13.6 The headings of the sections used in this Agreement are included for convenience only and are not to be used
      in construing or interpreting this Agreement.
    </p>
    <p>
      13.7 If any provision of this Agreement is ruled wholly or partly invalid or unenforceable by a court or other
      body of competent jurisdiction, then (i) the validity and enforceability of all provisions of this Agreement not
      ruled to be invalid or unenforceable will be unaffected; (ii) the effect of the ruling will be limited to the
      jurisdiction of the court or other body making the ruling; (iii) the provision held wholly or partly invalid or
      unenforceable shall be deemed amended, and the court or other body is authorized to reform the provision, to the
      minimum extent necessary to render them valid and enforceable in conformity with the parties' intent as
      manifested herein; and (iv) if the ruling or the controlling principle of law or equity leading to the ruling
      subsequently is overruled, modified, or amended by legislative, judicial, or administrative action, then the
      provision in question as originally set forth in this Agreement shall be deemed valid and enforceable to the
      maximum extent permitted by the new controlling principle of law or equity.
    </p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
import platform from 'platform';
export default {
	created() {
		document.title = 'UserAgreement';
	},
	data() {
		return {
			isApplePlatform: ['iPhone', 'iPad'].includes(platform.product) || /os x/i.test(platform.os.family),
		}
	},
}
</script>

<style scoped>
.agreement {
  font-size: 16PX;
  padding: 8PX;
}

h1 {
  margin: 10px 0;
}

p {
  line-height: 1.4em;
}

strong {
  font-weight: bold;
}
</style>