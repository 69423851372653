<template>
	<div class="popup" v-if="isPC && !isIPad">
		<canvas ref="canvas"></canvas>
		<div class="describe">{{ $t("middleware.qrCode") }}</div>
	</div>
</template>

<script>
import { isPC, isIPad } from "../../utils/util";

export default {
	name: "QRCode",
	data() {
		return {
			isPC: isPC(),
			isIPad,
		}
	},
	methods: {
		renderQrCode() {
			try {
				const QRCode = require('qrcode');
				QRCode.toCanvas(
					this.$refs['canvas'],
					location.href,
					function (error) {
						error && console.error(error);
					}
				);
			} catch(err) {
				console.error('[render qr code error: ]', err);
			}
    },
	},
	mounted() {
		try {
			this.isPC && this.renderQrCode();
		} catch(err) {
			console.error('mounted error', err);
		}
	}
}
</script>

<style lang="less" scoped>
.popup {
	position: fixed;
	top: 50%;
	right: 40PX;
	transform: translateY(-50%);
	font-size: 14PX;
	padding: 10PX 0;
	background: #fff;
	border-radius: 6PX;
	overflow: hidden;

	.describe {
		width: 100%;
		max-width: 164PX;
		padding: 0 10PX;
		margin: 0 auto;
		box-sizing: border-box;
	}
}
</style>