import { render, staticRenderFns } from "./ServiceCompensation.vue?vue&type=template&id=e9338bfa&scoped=true"
import script from "./ServiceCompensation.vue?vue&type=script&lang=js"
export * from "./ServiceCompensation.vue?vue&type=script&lang=js"
import style0 from "./ServiceCompensation.vue?vue&type=style&index=0&id=e9338bfa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9338bfa",
  null
  
)

export default component.exports