<template>
	<div class="install-channel">
		<div class="pc-box">
			<img 
				v-if="!iosPlatform && !iPadPlatform"
				:src="channels.googlePlayPng" 
				@click="toGooglePlay('googlePlay')"
			>
			<img 
				v-if="!androidPlatform"
				:src="channels.appStorePng" 
				@click="toAppStore"
			>
		</div>
		<img 
			v-if="showDownloadAPKButton"
			:src="channels.androidPng"
			@click="downloadAPK('download')"
		>
	</div>
</template>

<script>
import installChannels from '@/utils/installChannels';
import { i18n } from '@/main.js';
import { isIOS, isPC, isIPad, isAndroid, isPikPakClient } from '@/utils/util';
import { getDeepLink, getChannelApkLink } from '@/utils/toapp';
import { parseUrlParams } from '../../utils/toapp';

export default {
	name: "InstallChannel",
	props: {
		dataReport: {
			type: Function,
			required: false,
		}
	},
	computed: {
		channels() {
			return installChannels[i18n.locale];
		},
		iosPlatform() {
			return isIOS;
		},
		androidPlatform() {
			return isAndroid;
		},
		iPadPlatform() {
			return isIPad;
		},
		pcPlatform() {
			return isPC();
		},
		showDownloadAPKButton() {
      if (this.pcPlatform) {
        return false;
      }
			const { __source: source } = parseUrlParams();
      const from = decodeURIComponent(location.search).match(
        /from=([^&]+)/i
      )?.[1];
      if (from) {
        return from.split("/").shift() !== "null" && !this.iosPlatform;
      }
      return !!source && !this.iosPlatform;
    },
		showInstallChannels() {
			return !(this.androidPlatform && isPikPakClient())
		}
	},
	methods: {
		toGooglePlay(clickType) {
      clickType && this.dataReport?.(clickType);
      if (this.pcPlatform) {
        try {
          let deepLink = btoa(getDeepLink())
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=/g, "");
          window.location.href = `http://play.google.com/store/apps/details?id=com.pikcloud.pikpak&referrer=${deepLink}`;
        } catch (err) {
          console.error("[toGooglePlay] ", err);
          window.location.href = "http://play.google.com/store/apps/details?id=com.pikcloud.pikpak";
        }
        return;
      }
      let deepLink = btoa(getDeepLink())
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "");
      window.location.href = `http://play.google.com/store/apps/details?id=com.pikcloud.pikpak&referrer=${deepLink}`;
    },
		async downloadAPK(clickType) {
      clickType && this.dataReport?.(clickType);
      // 将__add_url的值写入粘贴板
      try {
        const params = parseUrlParams()
        const url = params?.__add_url;
        url && await this.$copyText(url);
      } catch (err) {
        console.error("写入粘贴板失败");
      }
      const downloadBtn = document.createElement("a");
      downloadBtn.href = getChannelApkLink();
      downloadBtn.download = "PikPak.apk";
      document.body.appendChild(downloadBtn);
      downloadBtn.click();
      let timer = setTimeout(() => {
        document.body.removeChild(downloadBtn);
        clearTimeout(timer);
        timer = null;
      }, 3000);
    },
		toAppStore() {
			try {
				let link = `https://apps.apple.com/app/apple-store/id1616861537?pt=124220499&mt=8`
				const { __source, from } = parseUrlParams();
				if (__source) {
					link = link + `&ct=${ __source }`;
				} else if (from) {
					const source = decodeURIComponent(from)?.split('/')?.[0];
					if (source) {
						link = link + `$ct=${ source }`;
					}
				}
				const a = document.createElement('a');
				a.href = link;
				a.target = '_blank';
				a.click();
			} catch(err) {
				console.error('to app store error: ', err);
			}
		}
	}
}
</script>

<style lang="less" scoped>
.install-channel {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 40PX;

	img {
		width: 323PX;
		margin-top: 12PX;
		cursor: pointer;
	}
}

.pc-box {
	display: flex;

	img {
		width: 280PX;
		margin-top: 12PX;
		cursor: pointer;
	}

	img + img {
		margin-left: 20PX;
	}
}

@media screen and (max-width: 768PX) {
	.install-channel {
		img {
			width: 182PX;	
		}
	}
}
</style>