<template>
	<div class="invited-page">
		<div class="invited-page-content">
			<div class="inviter-info">
				<img src="../../assets/images/invitation/avatar.png" alt="avatar" class="avatar">
				<div class="inviter-info__title">
					<div class="your-friend">{{ $t('invitation.yourFriend') }} {{ inviterName }} </div>
					<div class="introduce-pikpak">
						{{ $t("invitation.introduceApp") }} <span class="pikpak-name">PikPak</span>
					</div>
				</div>
			</div>
			<div class="invited-info-block">
				<div class="invited-info-block-item">
					<div class="icon"></div>
					<div class="invited-info-block-item__title">{{ $t("invitation.collectMorePlatform") }}</div>
				</div>
				<div class="invited-info-block-item">
					<div class="icon"></div>
					<div class="invited-info-block-item__title">{{ $t("invitation.supportTenT") }}</div>
				</div>
				<div class="invited-info-block-item">
					<div class="icon"></div>
					<div class="invited-info-block-item__title">{{ $t("invitation.secondSaveAndPressPlay") }}</div>
				</div>
			</div>
			<div class="banner">
				<div class="banner-title">{{ $t('invitation.takeYouTenDayVip') }}</div>
				<div class="banner-subtitle" v-if="clientType === 'ios'" v-html="$t('invitation.quickInstallIos', [inviteCode])"></div>
				<div class="banner-subtitle" v-else>{{ $t('invitation.quickInstall') }}</div>
			</div>
			<div
				class="install-channel-box"
			>
				<img
					v-if="clientType === 'ios'"
					class="apple-image"
					:src="channelIcons.appStorePng"
					@click="toAppStore"
				/>
				<template v-else>
					<img
						class="googleplay-image"
						:src="channelIcons.googlePlayPng"
						alt="googlePlay"
						@click="callGooglePlay"
					/>
					<img
						v-if="showAPKDownload && showInstallChannels"
						class="apk-image"
						:src="channelIcons.androidPng"
						@click="downloadChaApk"
					/>
				</template>
			</div>
			<div class="user-comment" ref="user-comment">
				<div class="comment-block" :class="{'show-comment': page === 0, 'hide-comment': page !== 0}">
					<div class="user-comment-item" v-for="({name, comment}) in userComments.slice(0, 3)" :key="comment">
						<div class="user-comment-item__name">{{ name }}</div>
						<div class="user-comment-item__content">{{ comment }}</div>
					</div>
				</div>
				<div class="comment-block" :class="{'show-comment': page === 1, 'hide-comment': page !== 1}">
					<div class="user-comment-item" v-for="({name, comment}) in userComments.slice(3, 5)" :key="comment">
						<div class="user-comment-item__name">{{ name }}</div>
						<div class="user-comment-item__content">{{ comment }}</div>
					</div>
				</div>
				<div class="comment-block" :class="{'show-comment': page === 2, 'hide-comment': page !== 2}">
					<div class="user-comment-item" v-for="({name, comment}) in userComments.slice(5)" :key="comment">
						<div class="user-comment-item__name">{{ name }}</div>
						<div class="user-comment-item__content">{{ comment }}</div>
					</div>
				</div>
			</div>			
		</div>
		<QRPopup></QRPopup>
		<div v-if="forbidden" class="page-forbidden">
			<Forbidden :userIP="userIP"></Forbidden>
		</div>
	</div>
</template>

<script>
import platform from 'platform';
import { getChaApkDownloadLink, getInviterInfo, isForbiddenIP } from '../../api/index.js';
import QRPopup from '../../components/qrCode';
import qs from 'qs';
import Forbidden from '../Forbidden.vue';
import installChannels from '../../utils/installChannels';
import { i18n } from '../../main';
import { enableIosSmartAppBanner, isPikPakClient } from '../../utils/util';

export default {
	name: "InvitedPage",
	components: {
    QRPopup,
    Forbidden,
},
	data() {
		return {
			forbidden: false,
			isGPPackage: true,
			inviterName: '',
			avatar: '',
			inviteCode: '',
			language: navigator.language,
			page: 0,
			userComments: [
				{
					name: this.$t('invitation.commentName1'),
					comment: this.$t('invitation.commentText1'),
				},
				{
					name: this.$t('invitation.commentName2'),
					comment: this.$t('invitation.commentText2'),
				},
				{
					name: this.$t('invitation.commentName3'),
					comment: this.$t('invitation.commentText3'),
				},
				{
					name: this.$t('invitation.commentName4'),
					comment: this.$t('invitation.commentText4'),
				},
				{
					name: this.$t('invitation.commentName5'),
					comment: this.$t('invitation.commentText5'),
				},
				{
					name: this.$t('invitation.commentName6'),
					comment: this.$t('invitation.commentText6'),
				},
				{
					name: this.$t('invitation.commentName7'),
					comment: this.$t('invitation.commentText7'),
				},
			],
			userIP: '',
		}
	},
	beforeCreate() {
		document.title = this.$t('invitation.invitedPageTitle');
	},
	beforeMount() {
		clearInterval(this.commentTimer);
		this.commentTimer = null;
		// ios下开启smart app banner
		enableIosSmartAppBanner();
	},
	async mounted() {
		this.init();
		// 页面根据API进行访问限制(禁止大陆IP访问)
		if (!/^dev-/.test(location.host)) {
			await this.ipLimit();
		}
	},
	computed: {
		channelIcons() {
			return installChannels[i18n.locale || 'en-US'];
		},
		isEnglishEnv() {
			return ['en', 'en-US'].includes(navigator.language);
		},
		showAPKDownload() {
			return !this.isGPPackage;
		},
		clientType() {
      if (/android/i.test(platform.os.family)) {
        return "android";
      }
      if (/OS X/i.test(platform.os.family)) {
        return "mac";
      }
      if (/iOS/i.test(platform.os.family)) {
        return "ios";
      }
      return "pc";
    },
		showInstallChannels() {
			return !(this.clientType === 'android' && isPikPakClient())
		}
	},
	methods: {
		async init() {
			try {
				document.querySelector('html').style.fontSize = window.innerWidth >= 650 ? '60px' : '10vw';
				window.addEventListener('resize', () => {
					document.querySelector('html').style.fontSize = window.innerWidth >= 650 ? '60px' : '10vw';
				});
				this.parseParams();
				this.changeUserCommon();
				await this.getInviterInfo(this.inviteCode);

				this.$stat("external_h5", "spread_share_page_show", {
					invite_code: this.inviteCode,
					client_type: this.clientType,
					s_download_btn_show: this.showAPKDownload ? 'yes' : 'no',
					user_agent: navigator.userAgent,
				});
			} catch(err) {
				console.error('[init error: ]', err);
			}
		},
		async ipLimit() {
			try {
				const { data: { result, data } } = await isForbiddenIP();
        this.userIP = data.ip;
				if (result !== 'ACCEPTED') {
					this.forbidden = true;
				}
			} catch(err) {
				console.error('[ipLimit: ]', err);
			}
		},
		// 通过inviteCode获取邀请者信息
		async getInviterInfo(inviteCode) {
			try {
				const { data: { name, avatar } } = await getInviterInfo({ inviteCode, language: this.language });
				this.avatar = avatar;
				this.inviterName = name;
			} catch (err) {
				console.error('[getInviterInfo:]', err);
			}
		},
		// 解析url参数
		parseParams() {
			try {
				const params = qs.parse(location.search.substring(1));
				if ('key' in params) {
					const { gpPackage, inviteCode } = JSON.parse(atob(decodeURIComponent(params.key)));
					this.isGPPackage = gpPackage;
					this.inviteCode = inviteCode;
				} else {
					const { code, g } = params;
					this.isGPPackage = !!g;
					this.inviteCode = code;
				}
			} catch(err) {
				console.error('[parseParams:]', err);
			}
		},
		// 从url参数中获取指定apk包下载链接
		async getChaApkDownloadURL(invitedId) {
			try {
				const { data: { apk_url } } = await getChaApkDownloadLink(invitedId);
				return apk_url;
			} catch (err) {
				console.error('[getChaApkDownloadURL:]', err);
			}
		},
		async downloadChaApk() {
			try {
				const url = await this.getChaApkDownloadURL(this.inviteCode);
				// 下载逻辑
				if (!url) {
					return;	
				}
				const a = document.createElement('a');
				a.href = url;
				a.download = 'PikPak.apk';
				document.body.appendChild(a);
				a.click();
				let timer = setTimeout(() => {
					document.body.removeChild(a);
					clearTimeout(timer);
					timer = null;
				}, 1000);
				this.$stat("external_h5", "spread_share_page_click", {
					invite_code: this.inviteCode,
					client_type: this.clientType,
					s_download_btn_show: this.showAPKDownload ? 'yes' : 'no',
					button: 'download',
				});
			} catch(err) {
				console.error('[downloadChaApk:]', err);	
			}
		},
		callGooglePlay() {
			try {
				let deepLink = `/xpan/main_tab?tab=0&from=spread/normal/${this.inviteCode}`;
				deepLink = btoa(deepLink)
            .replace(/\+/g, "-")
            .replace(/\//, "_")
            .replace(/=/g, "");

				this.$stat("external_h5", "spread_share_page_click", {
					invite_code: this.inviteCode,
					client_type: this.clientType,
					s_download_btn_show: this.showAPKDownload ? 'yes' : 'no',
					button: 'GooglePlay'
				});

				location.href = `intent://details?id=com.pikcloud.pikpak&referrer=${deepLink};#Intent;scheme=market;package=com.pikcloud.pikpak;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.pikcloud.pikpak&referrer=${deepLink};end;`;
				let timer = setTimeout(() => {
					if (document.hidden) {
						clearTimeout(timer);
						timer = null;
						return;
					}
					location.href = `https://play.google.com/store/apps/details?id=com.pikcloud.pikpak&referrer=${deepLink}`;
				}, 3000);
			} catch(err) {
				console.error('[call googlePlay: ]', err);
			}
		},
		changeUserCommon() {
			this.commentTimer = setInterval(() => {
				let x = this.page + 1;
				this.page = x % 3;
			}, 6000);
		},
		toAppStore() {
			const url = `https://apps.apple.com/app/apple-store/id1616861537?pt=124220499&ct=spread&mt=8`;
			const a = document.createElement('a');
			a.target = '_blank';
			a.href = url;
			a.click();
		}
	}
}
</script>

<style lang="less" scoped>
@import url('./normal.less');

.page-forbidden {
	position: fixed;
	z-index: 99999!important;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #fff;
	overflow: hidden;
}

@media screen and (min-width: 520px) {
	.invited-page {
		&-content {
			padding: 0;
		}
	}	
}
</style>