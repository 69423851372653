<template>
	<div class="normal-banner">
		<div class="logo-box">
			<img
				v-show="loadedThumbnail"
				class="thumbnail"
				ref="thumbnailImg"
				:src="resourceThumbnail"
				alt="logo"
			/>
			<img
				v-if="!loadedThumbnail"
				class="logo"
				src="@/assets/images/toapp/pikpak-logo.webp"
				alt="logo"
			/>
		</div>
		<div
			class="title"
			:class="{ 'small-title': resourceName }"
			:title="resourceName || 'PikPak'"
		>
			{{ resourceName || "PikPak" }}
		</div>
		<div class="saving-tips">
			{{ $t("middleware.savingTips") }}
		</div>
	</div>
</template>

<script>
import { isPC } from '@/utils/util'
export default {
	name: 'NormalBanner',	
	data() {
		return {
			isPC: isPC(),
      loadedThumbnail: false,
		}
	},
	props: {
		resourceThumbnail: {
			type: String,
			required: true
		},
		resourceName: {
			type: String,
			required: true
		}
	},
	watch: {
		resourceThumbnail(thumbnail) {
			thumbnail && this.listenThumbnailLoaded();
		}
	},
	methods: {
		listenThumbnailLoaded() {
      this.$refs["thumbnailImg"].addEventListener("load", () => {
        this.loadedThumbnail = true;
      });
    },
	}
}
</script>

<style lang="less" scoped>
.normal-banner {
	text-align: center;

	.thumbnail {
		width: 300PX;
		object-fit: contain;
		border-radius: 10PX;
	}

	.logo-box {
		display: flex;
		justify-content: center;
		margin-top: 80PX;

		.logo {
			width: 178PX;
			height: 178PX;
		}
	}

	.title {
		width: 100%;
		max-width: 600Px;
		margin: 0 auto;
		margin-top: 20PX;
		font-size: 42PX;
		font-weight: 500;
		text-align: center;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.saving-tips {
		color: #2C2C2C;
		font-size: 20PX;
		margin-top: 38px;
	}
}

@media screen and (max-width: 768PX) {
	.normal-banner {
		.logo-box {
			margin-top: 60PX;

			.logo {
				width: 100PX;
				height: 100PX;
			}
		}

		.title {
			font-size: 24PX;
			padding: 0 10PX;
			box-sizing: border-box;
		}
	}
}
</style>