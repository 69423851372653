<template>
	<div class="content">
		<div ref="helpPage" @scroll="handleScroll" class="how-to-get-file">
			<div class="banner-box">
				<img class="banner-box__img" src="../../assets/images/help/banner.webp" alt="banner">
				<div class="banner-box__title">{{$t('help.pageTitle')}}</div>
			</div>
			<div class="top-describe">
				<div class="top-describe__main">
					{{$t('help.topDescribe1')}} 	
				</div>
				<div class="top-describe__sub">
					{{$t('help.topDescribe2')}} 	
				</div>
			</div>
			<div class="switch-tab-box"
				:class="{shadow: isShowShadow}"
				ref="switchTab"
			>
				<div
					@click="handleClickTab(0)"
					class="switch-tab-box__left" 
					:class="{focus: switchFocusIndex === 0}"
				>
					{{ isIOS ? $t('help.uhvko8xJ5E0QiP6z69yyf') : $t('help.copyLink')}}
				</div>
				<div 
					v-if="!isIOS"
					@click="handleClickTab(1)"
					class="switch-tab-box__center" 
					:class="{focus: switchFocusIndex === 1, 'id-switch': $i18n.locale == 'id'}"
				>
					{{$t('help.systemShare')}}
				</div>
				<div
					v-else
					class="switch-tab-box__center" 
					:class="{focus: switchFocusIndex === 1, 'id-switch': $i18n.locale == 'id'}"
					@click="handleClickTab(1)"
				>{{ $t('help.telegramBot') }}</div>
				<div
					@click="handleClickTab(2)"
					class="switch-tab-box__right" 
					:class="{focus: switchFocusIndex === 2}"
				>
					{{ isIOS ? $t('help.pP4OtIiSRh5GFjbCbu74') : $t('help.telegramBot')}}
				</div>
			</div>
			<div class="title-line" ref="scrollFlag1">
				<div class="anchor" id="0"></div>
				<div class="title-line__index">01</div>
				<div v-if="isIOS" class="title-line__text">{{ $t('help.uhvko8xJ5E0QiP6z69yyf') }}</div>
				<div v-else class="title-line__text">{{$t('help.copyLink')}}</div>
			</div>
			<div class="introduce-block">
				<div class="describe" v-if="!isIOS">
					<div>{{$t('help.copyLinkDescribe1')}}</div>
				</div>
				<div v-if="isIOS" class="step-box">
					<div class="step-box-item">
						<div class="top">
							<div class="step-box-item__index">
								{{$t('help.step1')}}
							</div>
							<div class="step-box-item__title">
								{{ $t('help.9sK2ydm60NjgkUzJm8i2d') }}
							</div>
						</div>
						<div class="bottom">
							<img width="100%" src="../../assets/images/help/upload-1.png" alt="copy-link">
						</div>
					</div>
					<div class="step-box-item">
						<div class="top">
							<div class="step-box-item__index">
								{{$t('help.step2')}}
							</div>
							<div class="step-box-item__title">
								{{ $t('help.zgdvyQprMoKuuXvWkNq6O') }}
							</div>
						</div>
						<div class="bottom">
							<img width="100%" src="../../assets/images/help/upload-2.png" alt="add-now">
						</div>
					</div>
					<div class="step-box-item">
						<div class="top">
							<div class="step-box-item__index">
								{{$t('help.step3')}}
							</div>
							<div class="step-box-item__title">
								{{ $t('help.mMvj85iuOMNi29BjT0Yg') }}
							</div>
						</div>
						<div class="bottom">
							<img width="100%" src="../../assets/images/help/upload-3.png" alt="add-now">
						</div>
					</div>
				</div>
				<div v-else class="step-box">
					<div class="step-box-item">
						<div class="top">
							<div class="step-box-item__index">
								{{$t('help.step1')}}
							</div>
							<div class="step-box-item__title">
								{{$t('help.copyLinkStep1')}}
							</div>
						</div>
						<div class="bottom">
							<img width="100%" src="../../assets/images/help/copy-link.webp" alt="copy-link">
						</div>
					</div>
					<div class="step-box-item">
						<div class="top">
							<div class="step-box-item__index">
								{{$t('help.step2')}}
							</div>
							<div class="step-box-item__title">
								{{$t('help.copyLinkStep2')}}
							</div>
						</div>
						<div class="bottom">
							<img width="100%" src="../../assets/images/help/add-now.webp" alt="add-now">
						</div>
					</div>
				</div>
			</div>
			<div v-if="!isIOS" class="title-line" ref="scrollFlag2">
				<div class="anchor" id="1"></div>
				<div class="title-line__index">02</div>
				<div class="title-line__text">{{$t('help.systemShare')}}</div>
			</div>
			<div class="introduce-block" v-if="!isIOS">
				<div class="describe">
					<div>{{$t('help.systemShareDescribe1')}}</div>
				</div>
				<div class="step-box">
					<div class="step-box-item">
						<div class="top">
							<div class="step-box-item__index">
								{{$t('help.step1')}}
							</div>
							<div class="step-box-item__title">
								{{$t('help.systemShareStep1')}}
							</div>
						</div>
						<div class="bottom">
							<img width="100%" src="../../assets/images/help/find-app.webp" alt="copy-link">
						</div>
					</div>
					<div class="step-box-item">
						<div class="top">
							<div class="step-box-item__index">
								{{$t('help.step2')}}
							</div>
							<div class="step-box-item__title">
								{{$t('help.systemShareStep2')}}
							</div>
						</div>
						<div class="bottom">
							<img width="100%" src="../../assets/images/help/add-now.webp" alt="add-now">
						</div>
					</div>
				</div>
			</div>
			<div class="title-line" ref="scrollFlag3">
				<div class="anchor" :id="isIOS ? 1 : 2"></div>
				<div class="title-line__index" v-if="isIOS">02</div>
				<div class="title-line__index" v-else>03</div>
				<div class="title-line__text">{{$t('help.telegramBot')}}</div>
			</div>
			<div class="introduce-block">
				<div class="describe">
					<div>{{$t('help.telegramBotDescribe1')}}</div>
					<div v-if="!isIOS">{{$t('help.telegramBotDescribe2')}}</div>
				</div>
				<div class="introduce-stage-title">
					{{$t('help.telegramBotTitle1')}}
				</div>
				<div class="step-box space-box">
					<div class="step-box-item">
						<div class="top">
							<div class="step-box-item__index">
								{{$t('help.step1')}}
							</div>
							<div class="step-box-item__title">
								{{$t('help.telegramBotStep1')}}
							</div>
						</div>
						<div class="bottom space-item">
							<img width="100%" src="../../assets/images/help/open-bot.webp" alt="copy-link">
						</div>
					</div>
					<div class="step-box-item">
						<div class="top">
							<div class="step-box-item__index">
								{{$t('help.step2')}}
							</div>
							<div class="step-box-item__title">
								{{$t('help.telegramBotStep2')}}
							</div>
						</div>
						<div class="bottom space-item">
							<img width="100%" src="../../assets/images/help/telegram-chat.webp" alt="add-now">
						</div>
					</div>
					<div class="step-box-item">
						<div class="top">
							<div class="step-box-item__index">
								{{$t('help.step3')}}
							</div>
							<div class="step-box-item__title">
								{{$t('help.telegramBotStep3')}}
							</div>
						</div>
						<div class="bottom space-item">
							<img width="100%" src="../../assets/images/help/auth-account.webp" alt="add-now">
						</div>
					</div>
				</div>
				<div class="introduce-stage-title">
					{{$t('help.telegramBotTitle2')}}
				</div>
				<div class="stage-describe">
					<div v-if="!isIOS">{{$t('help.telegramBotDescribe3')}}</div>
				</div>
				<div class="step-box">
					<div class="step-box-item">
						<div class="top" style="height: 25px;">
							<div class="step-box-item__index">
								{{$t('help.forwardInTelegram')}}
							</div>
						</div>
						<div class="bottom">
							<img width="100%" src="../../assets/images/help/forward-telegram.webp" alt="copy-link">
						</div>
					</div>
					<div class="step-box-item">
						<div class="top" style="height: 25px;"></div>
						<div class="bottom">
							<img width="100%" src="../../assets/images/help/forward-pikpak.webp" alt="add-now">
						</div>
					</div>
				</div>
				<div class="step-box">
					<div class="step-box-item">
						<div class="top" style="height: 25px;">
							<div class="step-box-item__index">
								{{$t('help.forwardInOtherPlatform')}}
							</div>
						</div>
						<div class="bottom">
							<img width="100%" src="../../assets/images/help/forward-other.webp" alt="copy-link">
						</div>
					</div>
					<div class="step-box-item">
						<div class="top" style="height: 25px;"></div>
						<div class="bottom">
							<img width="100%" src="../../assets/images/help/forward-pikpak.webp" alt="add-now">
						</div>
					</div>
				</div>
				<div class="step-box" v-if="!isIOS">
					<div class="step-box-item">
						<div class="top" style="height: 25px;">
							<div class="step-box-item__index">
								{{$t('help.officialGroupIntroduce')}}
							</div>
						</div>
						<div class="describe" :style="{marginTop: '20px'}">
							<div>{{$t('help.officeGroupDescribe1')}}</div>
						</div>
						<div class="official-group">
							<div class="official-group-item">
								<img src="../../assets/images/help/group.webp" alt="group-icon" class="official-group-item__icon">
								<div class="official-group-item__name">
									{{$t('help.officeGroupName')}}
								</div>
								<a href="https://t.me/pikpak_userservice" target="_blank" class="official-group-item__button">
									{{$t('help.addNow')}}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<template v-if="isIOS">
				<div class="title-line" ref="scrollFlag2">
					<div class="anchor" id="2"></div>
					<div class="title-line__index">03</div>
					<div class="title-line__text">{{ $t('help.pP4OtIiSRh5GFjbCbu74') }}</div>
				</div>
				<div class="introduce-block">
					<div class="describe">
						<div>{{ $t('help.hImPkZSjqIgvHaYImtVss') }}</div>
					</div>
					<div class="official-group show-border">
						<div class="official-group-item">
							<img src="../../assets/images/help/icon-web.png" alt="group-icon" class="official-group-item__icon">
							<div class="official-group-item__name">
								{{ $t('help.pikpakWebsite') }}
							</div>
							<div @click="handleOpenPikPakWeb" target="_blank" class="official-group-item__button">
								{{ $t('help.zskyHf6wTdGS4BznCbjv') }}
							</div>
						</div>
					</div>
					<div class="describe">
						<div>{{$t('help.officeGroupDescribe1')}}</div>
					</div>
					<div class="official-group">
						<div class="official-group-item">
							<img src="../../assets/images/help/group.webp" alt="group-icon" class="official-group-item__icon">
							<div class="official-group-item__name">
								{{$t('help.officeGroupName')}}
							</div>
							<a href="https://t.me/pikpak_userservice" target="_blank" class="official-group-item__button">
								{{$t('help.addNow')}}
							</a>
						</div>
					</div>
				</div>
			</template>
		</div>
	</div>	
</template>

<script> 
import { getAppMetaData, openPikPakWeb } from '../../utils/jsBridge';
import { isIOS } from '../../utils/util';
export default {
	name: 'HowToGetFile',
	data() {
		return {
			scrollFlagList: [],
			switchFocusIndex: 0,
			isShowShadow: false,
			timer: null,
			isSwitchScrolling: false,
			isIOS,
		}
	},
	mounted() {
		const wrapper = document.querySelector('.how-to-get-file');
		if (wrapper) {
			wrapper.style.scrollBehavior = 'smooth';
		}
		this.init();
		const currentHTMLFontSize = parseInt(window.getComputedStyle(document.querySelector('html'))['fontSize']);
		if (currentHTMLFontSize >= 95) {
			document.querySelector('html').style.fontSize = '95px';
		}
	},
	beforeUpdate() {
		const hash = parseInt(this.$route.hash?.split('#')[1] || 0);
		if (isIOS) {
			this.$refs['switchTab'].style.top = hash === 2 ? '18px' : '0px';
		}
	},
	methods: {
		handleClickTab(index) {
			window.location.replace(`#${index}`);
			if (isIOS) {
				this.$refs['switchTab'].style.top = index === 2 ? '18px' : '0px';
			}
		},
		init() {
			// 获取pikpak客户端信息
      getAppMetaData(null, (data) => {
				data = typeof data === 'object' ? data : JSON.parse(data || '{}');
        const locale = data.language;
				this.$i18n.locale = locale || 'en-US';
				document.title = this.$t('help.pageTitle');
      });
			// 控制页面滚动, 如果有query参数tab
			const hash = parseInt(this.$route.hash?.split('#')[1] || '0');
			if (hash && (hash > -1 || hash < 3)) {
				setTimeout(() => {
					this.handleClickTab(hash);
				}, 400);
			}
		},
		handleTabSwitch() {
			try {
				this.$nextTick(() => {
					this.isShowShadow = this.$refs['switchTab'].getBoundingClientRect().y <= 0;
					const index = [
						this.$refs['scrollFlag1'].getBoundingClientRect().y,
						this.$refs['scrollFlag2'].getBoundingClientRect().y,
						this.$refs['scrollFlag3'].getBoundingClientRect().y
					].filter(i => i < 100).length - 1;
					if (this.isIOS) {
						let observer = new IntersectionObserver((x) => {
							if (x[0]?.isIntersecting) {
								this.switchFocusIndex = 2;
							} else {
								this.switchFocusIndex = Math.max(index, 0);
							}
						}, {
							root: document.body,
							rootMargin: '0px',
							threshold: 0,
						});
						observer.observe(this.$refs['scrollFlag2']);
					} else {
						this.switchFocusIndex = Math.max(index, 0);
					}
				});
			} catch(err) {
				console.error('handle tab switch error: ', err);
			}
		},
		handleScroll() {
			if (this.isSwitchScrolling) {
				return;
			}
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				this.handleTabSwitch();
			}, 200);
		},
		handleOpenPikPakWeb() {
			openPikPakWeb({}, () => {});
		}
	}
}
</script>

<style lang="less" scoped>
.content {
	height: 100vh;
	overflow: hidden;

	a {
		text-decoration: none;
	}

	div {
		text-align: left;
	}

}
.how-to-get-file {
	margin: auto;
	width: 1080px;
	height: 100vh;
	padding-bottom: 50px;
	overflow-x: hidden;
	overflow-y: scroll;

	.banner-box {
		position: relative;
		display: block;
		height: 498px;
		margin-bottom: 40px;

		&__img {
			display: block;
			width: 100%;
		}

		&__title {
			position: absolute;
			right: 110px;
			top: 50%;
			transform: translateY(-50%);
			font-family: Helvetica-Bold;
			font-size: 74px;
			color: #FFFFFF;
			font-weight: 700;
		}
	}
	.top-describe {
		width: 1008px;
		margin: 0 auto;
		font-family: PingFangSC;
		font-size: 42px;
		color: #586890;
		font-weight: 400;	
		background: #F5F8FF;
		border-radius: 15px;
		padding-top: 50px;
		box-sizing: border-box;
		padding-bottom: 50px;

		&__main {
			width: 931px;
			margin: auto;
			margin-bottom: 44px;
		}
		&__sub {
			width: 931px;
			margin: auto;
		}
	}
	.switch-tab-box {
		position: sticky;
		top: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 74PX;
		z-index: 10;
		background: #fff;
		display: flex;
		justify-content: center;
		font-family: PingFangSC-Medium;
		font-size: 39px;
		color: #7AA2FF;
		font-weight: 500;
		user-select: none;

		.id-switch {
			display: flex;
			line-height: 1.2;
			align-items: center;
		}

		&.shadow {
			box-shadow: 0 9px 12px 0 #F2F4F8;
		}


		& > div {
			width: 326px;
			height: 126px;
			text-align: center;
			line-height: 126px;
			text-decoration: none;
			color: #306EFF;
			transition: background 0.3s linear;
		}

		&__left {
			background: url('../../assets/images/help/left-blur.webp') no-repeat center/cover;
		}
		&__left.focus {
			color: #fff;
			background: url('../../assets/images/help/left-focus.webp') no-repeat center/cover;
		}

		&__center {
			background: url('../../assets/images/help/center-blur.webp') no-repeat center/cover;
			margin-left: 10px;
		}
		&__center.focus {
			background: url('../../assets/images/help/center-focus.webp') no-repeat center/cover;
			color: #fff;
			margin-left: 10px;
		}

		&__right {
			background: url('../../assets/images/help/right-blur.webp') no-repeat center/cover;
			margin-left: 10px;
		}
		&__right.focus {
			color: #fff;
			background: url('../../assets/images/help/right-focus.webp') no-repeat center/cover;
			margin-left: 10px;
		}
	}
	.title-line {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 60px auto;
		margin-top: 120px;
		width: 960px;
		height: 90px;	
		background: url('../../assets/images/help/title-line.webp') no-repeat center/cover;

		.anchor {
			position: absolute;
			width: 100vw;
			height: 240px;
			top: -240px;
			pointer-events: none;
		}

		&__index {
			font-family: Roboto-Bold;
			font-size: 60px;
			color: #306EFF;
			letter-spacing: -1.8px;
			line-height: 90px;
			font-weight: 700;
		}

		&__text {
			margin-left: 20px;
			font-family: PingFangSC-Medium;
			font-size: 54px;
			color: #323232;
			font-weight: 500;
		}
	}
	
	.introduce-block {
		font-family: PingFangSC;
		font-size: 42px;
		color: #586890;
		font-weight: 400;
		padding: 0 45px;

		.introduce-stage-title {
			height: 67px;
			font-family: PingFangSC-Medium;
			font-size: 48px;
			color: #323232;
			font-weight: 500;
			margin-top: 90px;
			background: left bottom / 549px 33px no-repeat url('../../assets/images/help/title-line.webp');
		}

		.stage-describe {
			margin-top: 45px;
		}

		.step-box {
			display: flex;
			margin-top: 70px;

			&-item {
				flex: 1;

				&:first-child {
					margin-right: 20px;
				}
				&:last-child {
					margin-left: 20px;
				}

				.top {
					height: 180px;
				}

				.bottom {
					display: flex;
					justify-content: space-between;
					margin-top: 20px;
				}

				.bottom.space-item {
					margin-top: 80px;
				}

				&__index {
					background: left / 32px 21px no-repeat url('../../assets/images/help/title-triangle.webp');
					padding-left: 40px;
					margin-bottom: 22px;
					font-family: Helvetica-Bold;
					font-size: 42px;
					color: #306EFF;
					font-weight: 700;
				}
			}
		}

		.official-group {
			&-item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 184px;
				background: #F5F8FF;
				border-radius: 12px;
				margin: 40px auto;
				padding: 30px;
				box-sizing: border-box;

				&__icon {
					display: block;
					width: 72px;
					height: 72px;
				}
				&__name {
					flex-grow: 2;
					font-family: PingFangSC-Medium;
					font-size: 42px;
					color: #586890;
					font-weight: 400;
					padding-left: 27px;
				}
				&__button {
					display: flex;
					justify-content: space-between;
					align-items: center;
					min-width: 240px;
					height: 102px;
					line-height: 1.2;
					text-align: center;
					justify-content: center;
					background: #F5F8FF;
					border: 3px solid #306EFF;
					border-radius: 12px;
					font-family: PingFangSC-Medium;
					font-size: 36px;
					color: #306EFF;
					font-weight: 500;
					cursor: pointer;
					padding: 0 32px;
					box-sizing: border-box;
					user-select: none;
				}
			}
		}
	}

	.show-border .official-group-item {
		border: 3Px solid #306EFF;
		box-sizing: border-box;
	}
}
</style>