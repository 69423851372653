<template>
	<div class="button-box">
		<div v-if="language === 'en-US'" class="my-button">
			<a 
				xla-on="click" 
				xla-category="vip_js" 
				xla-action="vip_js_limit_time_free_page_show" 
				xla-extdata="{ button: 'group_Telegram' }"
				@click="handleOpenFacebook"
				target="_blank">
				<button class="telegram-button postfix">
					<i class="my-button__icon icon-facebook"></i>
					<span>Join PikPak Facebook Group</span>
				</button>
			</a>
		</div>
		<div v-else class="my-button">
			<a 
				xla-on="click" 
				xla-category="vip_js" 
				xla-action="vip_js_limit_time_free_page_show" 
				xla-extdata="{ button: 'group_Telegram' }"
				@click="handleOpenTelegram"
				target="_blank">
				<button class="telegram-button postfix">
					<i class="my-button__icon icon-telegram"></i>
					<span>{{$t('activity.officialGroup')}}</span>
				</button>
			</a>
		</div>
		<div v-if="language === 'ko'" class="my-button">
			<a
				xla-on="click" 
				xla-category="vip_js" 
				xla-action="vip_js_limit_time_free_page_show" 
				xla-extdata="{ button: 'group_KaKao' }"
				@click="handleOpenKaKao"
				src="javascript:;"
			>
				<button class="kakao-talk-button postfix">
					<i class="my-button__icon icon-kakao-talk"></i>
					<span>{{ $t('activity.bjsDoxBxeZtpzWyMc5baN') }}</span>
				</button>
			</a>
		</div>
		<div v-else class="my-button">
			<a 
				xla-on="click" 
				xla-category="vip_js" 
				xla-action="vip_js_limit_time_free_page_show" 
				xla-extdata="{ button: 'group_WhatsApp' }"
				@click="handleOpenWhatsApp"
				target="_blank">
				<button class="whats-app-button postfix">
					<i class="my-button__icon icon-whats-app"></i>
					<span>{{$t('activity.whatsApp')}}</span>
				</button>
			</a>
		</div>
	</div>
</template>

<script>
import { getAppMetaData } from '@/utils/jsBridge';
import { reportByPikPak } from '../../../utils/jsBridge';
import { isIOS } from '../../../utils/util';
export default {
	name: 'OtherAppBox',
	data() {
		return {
			language: 'en-US',
		}
	},
	computed: {
		officialGroup() {
			const ps = new URLSearchParams(window.location.search);
			if (/zh/i.test(ps.get('country'))) {
				return 'https://t.me/pikpak_userservice';
			}
			if (/ko/i.test(ps.get('country'))) {
				return 'https://t.me/PikPak_Korea';
			}
			return 'https://t.me/pikpak_userservice_en';
		},
	},
	mounted() {
		getAppMetaData(null, data => {
			data = data !== null && typeof data === 'object' ? data : JSON.parse(data || '{}');
			this.language = data.language || 'en-US';
		});
	},
	methods: {
		parseParams() {
			try {
				const res = location.search
					.split('?')
					.pop()
					.split('&')
					.map(v => v.split('='))
					.reduce((p, n) => (p[n[0]] = n[1], p), {});
				return res;
			} catch(err) {
				console.error(err);
				return {};
			}
		},
		handleOpenKaKao() {
			const params = this.parseParams();
			reportByPikPak({
				hubbleEventId: isIOS ? 'ios_vip_pay' : 'android_vip_pay',
				hubbleAttribute: 'vip_js_limit_time_free_page_click',
				hubbleExData: { from: params.from, button: 'group_KaKao' },
			});
			window.location.href = 'pikpakapp://mypikpak.com/app/action_view?msg=https://open.kakao.com/o/gHWRsRue';
		},
		handleOpenTelegram() {
			const params = this.parseParams();
			reportByPikPak({
				hubbleEventId: isIOS ? 'ios_vip_pay' : 'android_vip_pay',
				hubbleAttribute: 'vip_js_limit_time_free_page_click',
				hubbleExData: { from: params.from, button: 'group_Telegram' },
			});
			window.location.href = `pikpakapp://mypikpak.com/app/action_view?msg=${this.officialGroup}`;
		},
		handleOpenFacebook() {
			const params = this.parseParams();
			reportByPikPak({
				hubbleEventId: isIOS ? 'ios_vip_pay' : 'android_vip_pay',
				hubbleAttribute: 'vip_js_limit_time_free_page_click',
				hubbleExData: { from: params.from, button: 'group_Facebook' },
			});
			window.location.href = `pikpakapp://mypikpak.com/app/action_view?msg=${encodeURIComponent('https://www.facebook.com/pik.pak.10/')}`;
		},
		handleOpenWhatsApp() {
			const params = this.parseParams();
			reportByPikPak({
				hubbleEventId: isIOS ? 'ios_vip_pay' : 'android_vip_pay',
				hubbleAttribute: 'vip_js_limit_time_free_page_click',
				hubbleExData: { from: params.from, button: 'group_WhatsApp' },
			});
			window.location.href = 'pikpakapp://mypikpak.com/app/action_view?msg=https://chat.whatsapp.com/BXvB38TYZb04yGjjfQiB18';
		}
	}
}
</script>

<style lang="less" scoped>
.button-box {
	display: flex;
	flex-direction: column;

	.my-button {
		height: 162px;
		margin-top: 24px;
		box-sizing: border-box;

		button {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			height: 100%;
			margin-top: 24px;
			border: none;
			font-family: Roboto-Regular;
			font-size: 51px;
			color: #FFFFFF;
			letter-spacing: -1.13px;
			text-align: center;
			line-height: 66px;
			border-radius: 24px;
			padding: 0 24Px;
			box-sizing: border-box;
		}

		.postfix span {
			margin-right: auto;
		}

		.postfix::after {
			content: '';
			position: absolute;
			width: 6Px;
			height: 6Px;
			top: 50%;
			right: 24Px;
			transform: translateY(-50%);
			background: #2E88CD;
			border-radius: 50%;
		}

		.telegram-button {
			position: relative;
			background: #fff;
			font-family: Roboto-Regular;
			font-size: 51px;
			color: #2E88CD;
			letter-spacing: -1.13px;
			text-align: center;
			line-height: 66px;
		}


		.whats-app-button {
			position: relative;
			background: #fff;
			font-family: Roboto-Regular;
			font-size: 51px;
			color: #30D158;
			letter-spacing: -1.13px;
			text-align: center;
			line-height: 66px;

			&::after {
				background: #30D158;
			}
		}

		.kakao-talk-button {
			position: relative;
			background: #fff;
			font-family: Roboto-Regular;
			font-size: 51px;
			color: #3B1C1C;
			letter-spacing: -1.13px;
			text-align: center;
			line-height: 66px;	

			span {
				margin-right: 30px;
			}

			&::after {
				background: #F9E000;
			}
		}	

		&__icon {
			display: block;
			flex-shrink: 0;
			width: 23Px;
			height: 24Px;
			margin-right: 48px;
		}
		&__icon.whats-app {
			height: 66px;
		}
	}
}

.icon-telegram {
	background: url('../../../assets/images/cdKey/telegram.png') no-repeat center/cover;
}

.icon-facebook {
	background: url('../../../assets/images/cdKey/facebook.png') no-repeat center/cover;
}

.icon-whats-app {
	background: url('../../../assets/images/cdKey/whatsapp.webp') no-repeat center/cover;
}

.icon-kakao-talk {
	background: url('../../../assets/images/cdKey/kakao-talk.png') no-repeat center/cover;
}
</style>