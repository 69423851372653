<template>
    <div class="payment-error">
        <div class="content-box">
            <img src="@/assets/images/notices/error-banner.png" alt="error-banner" class="banner">
            <div class="error-describe">
                {{ $t('notices.4DjJjMxsc2wYsO0X7Zb6') }}
            </div>
            <div class="error-describe">
                {{ $t('notices.a0bnHthk6JcLafodUtzb') }}
            </div>
            <div class="error-describe" v-html="$t('notices.bVIsA7nv5pB0ShXPaCa34')"> </div>
            <div class="error-describe" v-html="$t('notices.zaMfDDd5WwCnLnHskDtK5', [encodeURIComponent(downloadAPKUrl)])"> </div>
            <div class="error-describe" v-html="$t('notices.qk0fcFJv6iSRsc9K75YFj')"> </div>
            <div class="error-describe" v-html="$t('notices.qc6hRy8u13nuzMmoiVjBo')">
            </div>
        </div>
        <div class="join-group-button" @click="handleJoinGroup">{{ $t('notices.yrIer2T5zwR5eRzeB6Nq') }}</div>
    </div>
</template>

<script>
import { getUpdateLink } from '../../api/index.js';
import { getUserInfo } from '../../utils/jsBridge.js';

export default {
    name: "PaymentError",
    data() {
        return {
            downloadAPKUrl: 'https://download.mypikpak.net/app/official_PikPak.apk',
        }
    },
    async mounted() {
        try {
            document.title = this.$t('notices.error-description-title');
            getUserInfo({}, async (data) => {
                const metaData = typeof data === 'string' ? JSON.parse(data) : data;
                const { userID, channel, versionCode, versionName, language } = metaData; 

                const result = await getUpdateLink({
                    "data": {
                        "userid": userID,
                        "channel": channel,
                        "versionCode": versionCode,
                        "versionName": versionName,
                        "language": language
                    },
                    "client": "android"
                });
                
                const downloadLink = result?.data?.values?.client_version?.downloadURL;
                if (downloadLink) {
                    this.downloadAPKUrl = downloadLink;
                }
            });
        } catch(err) {
            console.error('mounted error: ', err);
        }
    },
    methods: {
        handleJoinGroup() {
            window.location.href = 'pikpakapp://mypikpak.com/xpan/setting';
        }
    }
}
</script>

<style lang="less" scoped>
.payment-error {
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .content-box {
        margin-bottom: 120Px;
    }

    .banner {
        display: block;
        width: 154Px;
        height: 110Px;
        margin: 32Px auto;
    }

    .error-describe {
        font-size: 16Px;
        padding: 0 16Px;
        margin-top: 12Px;
    }

    .join-group-button {
        position: fixed;
        bottom: 32Px;
        width: calc(100vw - 32Px);
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 54Px;
        background: #306EFF;
        font-size: 17Px;
        color: #fff;
        cursor: pointer;
        border-radius: 8Px;
    }

    .date-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 16Px;
    }
}
</style>