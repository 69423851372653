<template>
	<div class="cd-key-page">
		<div class="content">
			<div class="get-key">
				<div class="describe-function-box">
					<div class="text">{{$t('activity.tenTSpace')}}</div>
					<div class="text">{{$t('activity.noLimit')}}</div>
				</div>
				<div class="get-key__title">{{$t('activity.noCdKey')}}</div>
				<OtherAppBox />
			</div>
			<div class="cd-key">
				<div class="title">{{$t('activity.haveCdKey')}}</div>
				<div class="function-block">
					<div class="result-box" v-if="tradeSuccess">
						<span class="code">{{cdKey}}</span>
					</div>
					<div class="input-box" v-else>
						<input 
							class="cd-key-input" 
							@keydown.enter="handleSubmit" 
							@input="handleInput" 
							v-model="cdKey" 
							:placeholder="$t('activity.pleaseEnterCdKey')" 
						/>
						<i class="clear-icon" v-show="hasInputCdKey" @click="clearInput"></i>
					</div>
					<button 
						class="trade-button" 
						@click="handleSubmit" 
						type="primary" 
						:class="{disable: !hasInputCdKey || tradeSuccess}"
						xla-on="click" 
						xla-category="vip_js" 
						xla-action="vip_js_limit_time_free_page_show" 
						xla-extdata="{ button: 'exchange' }"
					>
						{{ tradeSuccess ? $t('activity.tradeSuccess') : $t('activity.tradeButton') }}
					</button>
				</div>
				<div class="error-message" :class="{visible: hasError}">{{ errorMessage }}</div>
			</div>
			<footer class="rules-info">
				<div class="rules-info__title">{{$t('activity.rulesTitle')}}</div>
				<div class="rules-info__content">
					{{$t('activity.rulesDetail')}}
				</div>
			</footer>
		</div>
	</div>
</template>

<script>
import { tradeCdKey } from '@/api/index';
import { getAppMetaData } from '@/utils/jsBridge';
import OtherAppBox from '../../components/activity/cdKey/OtherAppBox.vue';
import { reportByPikPak } from '../../utils/jsBridge';
import { isIOS } from '../../utils/util';
export default {
	name: "CdKey",
	components: {
		OtherAppBox,
	},
	data() {
		return {
			cdKey: '',
			hasError: false,
			tradeSuccess: false,
			language: 'en-US',
			errorMessage: 'error',
		}
	},
	computed: {
		hasInputCdKey() {
			return this.cdKey !== '';
		}
	},
	mounted() {
		this.autoInput();
		this.getLanguage();
		// 数据上报; 页面展示
		this.$stat('vip_js', 'vip_js_limit_time_free_page_show', {
			from: this.parseParams().from,
		});
		const params = this.parseParams();
		reportByPikPak({
			hubbleEventId: isIOS ? 'ios_vip_pay' : 'android_vip_pay',
			hubbleAttribute: 'vip_js_limit_time_free_page_show',
			hubbleExData: { from: params.from },
		});
	},
	methods: {
		parseParams() {
			try {
				const res = location.search
					.split('?')
					.pop()
					.split('&')
					.map(v => v.split('='))
					.reduce((p, n) => (p[n[0]] = n[1], p), {});
				return res;
			} catch(err) {
				console.error(err);
				return {};
			}
		},
		getLanguage() {
			try {
				getAppMetaData(null, data => {
					data = data !== null && typeof data === 'object' ? data : JSON.parse(data || '{}');
					this.language = data?.language || 'en-US';
					// 设置页面标题
					document.title = this.$t('activity.pageTitle');
				});
			} catch(err) {
				console.error('get language error: ', err);
			}
		},
		clearInput() {
			this.cdKey = '';
			this.hasError = false;
		},
		autoInput() {
			const ps = this.parseParams();
			this.cdKey = ps.code || '';
		},
		handleInput() {
			this.hasError = false;
		},
		async handleSubmit() {
			try {
				const params = this.parseParams();
				reportByPikPak({
					hubbleEventId: isIOS ? 'ios_vip_pay' : 'android_vip_pay',
					hubbleAttribute: 'vip_js_limit_time_free_page_click',
					hubbleExData: { from: params.from, button: 'exchange' },
				});
				const { data } = await tradeCdKey({
					activation_code: this.cdKey
				}, {
					'Accept-Language': this.language,
				});
				if (!data.error) {
					this.tradeSuccess = true;
					// 兑换成功数据上报
					this.$stat('vip_js', 'vip_js_code_exchange_success', {
						from: this.parseParams().from,
					});
					const params = this.parseParams();
					reportByPikPak({
						hubbleEventId: isIOS ? 'ios_vip_pay' : 'android_vip_pay',
						hubbleAttribute: 'vip_js_code_exchange_success',
						hubbleExData: { from: params.from },
					});
				}
			} catch(err) {
				window.ex = err;
				const message = err.response.data 
					&& (err.response.data.error_description || err.response.data?.error);
				this.errorMessage = message;
				this.hasError = true;
				console.error('trad error: ', err);
			}
		},
	}
}
</script>

<style lang="less" scoped>
	.cd-key-page {
		a {
			text-decoration: none;
		}

		.content {
			.cd-key {
				& > .title {
					font-family: PingFangSC-Regular;
					font-size: 44px;
					color: #98A2AD;
					letter-spacing: -1.22px;
					text-align: center;
					line-height: 60px;
					margin-top: 48px;
				}

				.function-block {
					display: flex;
					justify-content: space-between;
					margin: 48px;
					margin-bottom: 0;
				}

				.input-box{
					display: flex;
					position: relative;
					align-items: center;
					overflow: hidden;
					width: 636px;
					height: 162px;
					border: 3px solid #F2F3F4;
					border-radius: 24px;
					padding-right: 120px;
					box-sizing: border-box;
				}
				.result-box {
					display: flex;
					position: relative;
					justify-content: center;
					align-items: center;
					width: 636px;
					height: 162px;
					box-sizing: border-box;

					.code {
						font-family: Roboto-Regular;
						font-size: 51px;
						color: #000000;
						letter-spacing: -1.13px;
						line-height: 66px;
					}
				}
				.error-message {
					color: #FF3333;
					text-align: left;
					padding-left: 48px;
					font-family: FZLTHJW--GB1-0;
					font-size: 36px;
					margin-top: 24px;
					visibility: hidden;

					&.visible {
						visibility: visible;
					}
				}
				.cd-key-input {
					display: block;
					outline: none;
					border: none;
					width: 100%;
					height: 140px;
					font-family: Roboto-Regular;
					font-size: 51px;
					color: #000;
					letter-spacing: -1.13px;
					line-height: 66px;
					padding-right: 0;
				}	
				.clear-icon {
					position: absolute;
					right: 48px;
					top: 50%;
					transform: translateY(-50%);
					display: block;
					width: 48px;
					height: 48px;
					background: url('../../assets/images/cdKey/clear.webp') no-repeat center/cover;
				}
				.trade-button {
					width: 300px;
					height: 162px;
					background: #306EFF;
					border-radius: 24px;
					font-family: Roboto-Regular;
					font-size: 51px;
					color: #FFFFFF;
					letter-spacing: -1.13px;
					text-align: center;
					line-height: 66px;
					border: none;
					user-select: none;
				}
				.trade-button.disable {
					pointer-events: none;
					background: #97B7FE;
					border-radius: 24px;
				}
			}

			.get-key {
				height: 1300px;
				padding: 48px;
				padding-bottom: 0;
				box-sizing: border-box;
				overflow: hidden;
				background: #306EFF;

				.describe-function-box {
					background: url('../../assets/images/cdKey/banner.png') no-repeat top center/948px 660px;
					height: 660px;
					font-family: PingFangSC-Semibold;
					font-size: 60px;
					color: #FFFFFF;
					letter-spacing: -1.88px;

					.text {
						display: flex;
						align-items: center;
						height: 330px;
						line-height: 1.2em;
						padding-left: 72px;
						padding-right: 400px;
					}
				}

				&__title {
					margin: 0 40px;
					margin-top: 42px;
					font-family: PingFangSC-Regular;
					font-size: 44px;
					color: #FFFFFF;
					letter-spacing: -1.22px;
					text-align: center;
					line-height: 60px;
				}	
			}

			.rules-info {
				margin: 24px 48px;
				margin-bottom: 0;
				font-family: PingFangSC-Regular;
				font-size: 39px;
				color: #98A2AD;
				letter-spacing: -1.22px;
				line-height: 60px;
			}
		}
	}
</style>