<template>
	<div class="content-wrapper">
			<div class="content-box">
					<img src="@/assets/images/notices/error-banner.png" alt="error-banner" class="banner">
	
					<div class="content-item">
						{{ $t('notices.c3TwRy4eaDZDRe1aVq') }}
					</div>
					<div class="content-item">
						{{ $t('notices.6is28jJXzGIp3do8DRm7I') }}
					</div>
					<div class="content-item">
						{{ $t('notices.sHrdY2G_7y5rwCSghdLzm') }}
					</div>
					<div class="content-item">
						{{ $t('notices.dNihJ0nwL4SfNs0Eo9Jmz') }}
					</div>
					<div class="content-item">
						{{ $t('notices.vG2m3U7PmnZOaX6bHgt') }}
					</div>
					<div class="content-item">
						{{ $t('notices.aLWys2StUquUwTrNlCk') }}
					</div>
			</div>
	</div>
</template>

<script>
export default {
	mounted() {
			document.title = this.$t('notices.oPmxxLqmqyZoxpvThkQr'); 
	},
	methods: {
			handleButtonClick() {
					window.location.href = 'pikpakapp://mypikpak.com/account/pay_activity?refer_from=ko-anniversary';
			}
	}
}
</script>

<style lang="less" scoped>
.content-wrapper {
	display: flex; 
	flex-direction: column;
	align-items: center;
	overflow-y: scroll;
	height: 100vh;

	.content-box {
		height: calc(100vh - 100Px);
		overflow-y: scroll;
	}

	.banner {
			display: block;
			width: 154Px;
			height: 110Px;
			margin: 32Px auto;
	}

	.content-item {
			font-size: 17Px;
			padding: 0 16Px;
			margin-bottom: 24Px;
	}

	.join-group-button {
			position: fixed;
			bottom: 32Px;
			width: calc(100vw - 32Px);
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: 54Px;
			background: #306EFF;
			font-size: 17Px;
			color: #fff;
			cursor: pointer;
			border-radius: 8Px;
	}

	.date-info {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			padding-right: 16Px;
	}
}
</style>