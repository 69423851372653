<template>
	<div class="bot-subscription-page">
	<div class="subscription-block" v-if="okSubscriptions.length > 0">
		<div class="subscription-block__title">{{ $t('middleware.cRBWqWeQvGxcRrdR9Wy1') }}</div>
		<BotSubscriptionItem 
			:sub-items="okSubscriptions" 
			:statusActivity="true"
			@refresh-list="getSubscribeChannelList"
		></BotSubscriptionItem>
	</div>
	<div class="subscription-block" v-if="invalidSubscriptions.length > 0">
		<div class="subscription-block__title">{{ $t('middleware.gIoZb0qKmePkmsxvzZb7d') }}</div>
		<BotSubscriptionItem 
			:sub-items="invalidSubscriptions" 
			:statusActivity="false"
			@refresh-list="getSubscribeChannelList"
		></BotSubscriptionItem>
	</div>
	<div class="empty-block" v-if="emptyStatus">
		{{ $t('middleware.yoIiwee5R7Wx5WYvCidQq') }}
	</div>
	<van-overlay 
		:custom-style="{
			background: 'rgba(0, 0, 0, 0.9)',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}" 
		:show="loading"
		:duration="0.8"
	>
		<van-loading type="spinner" color="#306EFF" size="50px" />
	</van-overlay>
</div>
</template>

<script>
import BotSubscriptionItem from '../components/bot-subscription/subscribe-item'
import { getSubscribeChannelList } from '../api';

export default {
	name: 'BotSubscription',
	components: {
		BotSubscriptionItem,
	},
	data() {
		return {
			okSubscriptions: [],
			invalidSubscriptions: [],
			loading: false,
		}
	},
	computed: {
		emptyStatus() {
			return !this.loading && this.okSubscriptions.length === 0 && this.invalidSubscriptions.length === 0;
		}
	},
	beforeMount() {
		document.title = this.$t('middleware.bOhEMhpoy5Jx7RwV8782v')
	},
	async mounted() {
		await this.getSubscribeChannelList();
	},
	methods: {
		async getSubscribeChannelList() {
			this.loading = true;
			try {
				const { data: { subscriptions } } = await getSubscribeChannelList();
				this.okSubscriptions = subscriptions.filter(({ status }) => status.toLowerCase() === 'ok');
				this.invalidSubscriptions = subscriptions.filter(({ status }) => status.toLowerCase() === 'cancel');
			} catch(err) {
				console.error('[getSubscribeChannelList: ]', err);
			}
			this.loading = false;
		}	
	}
}
</script>

<style lang="less" scoped>
.bot-subscription-page {
	font-size: 16PX;

	.subscription-block {
		margin-bottom: 24Px;

		&__title {
			margin: 0 16Px;
			font-size: 20Px;
			font-weight: bold;
			height: 50Px;
			line-height: 50Px;
		}
	}

	.empty-block {
		color: #333;
		font-size: 60px;
		text-align: center;
		margin-top: 100px;
	}
}
</style>