var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ping-wrapper"},[_c('div',{staticClass:"animation-wrapper"},[_c('div',{staticClass:"animation-box"},[_c('div',{staticClass:"bg"}),(_vm.pinging)?_c('div',{staticClass:"circle"}):_vm._e()]),(_vm.pinging)?_c('div',{staticClass:"status-text"},[_vm._v(_vm._s(_vm.$t('ping.zUOuajK9Vrj7wRdNtyr'))+"...")]):_c('div',{staticClass:"status-text"},[_vm._v(_vm._s(_vm.networkInfo.result))])]),_c('div',{staticClass:"network-info"},[_c('div',{staticClass:"network-info__item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('ping.c662JNupxRu5DtUHwAw0')))]),_c('div',{staticClass:"value",class:{
					'empty': !_vm.networkInfo.ip,
				}},[_vm._v(" "+_vm._s(_vm.networkInfo.ip || '--')+" ")])]),_c('div',{staticClass:"network-info__item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('ping.1r9dIqr2zIviPoOq2Aztc')))]),_c('div',{staticClass:"value",class:{
					'empty': !_vm.networkInfo.status,
				}},[_vm._v(" "+_vm._s(_vm.networkInfo.status || '--')+" ")])]),_c('div',{staticClass:"network-info__item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('ping.tAdhSqcWdWeXzzSobsaZq')))]),_c('div',{staticClass:"value",class:{
					'empty': !_vm.networkInfo.time,
				}},[_vm._v(" "+_vm._s(_vm.networkInfo.time || '--')+" ")])]),_c('div',{staticClass:"network-info__item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('ping.5_1K79Hqnu9TKruUjoNn')))]),_c('div',{staticClass:"value",class:{
					'empty': !_vm.networkInfo.stable,
				}},[_vm._v(" "+_vm._s(_vm.networkInfo.stable || '--')+" ")])])]),_c('div',{staticClass:"describe-box"},[_c('section',{staticClass:"describe-text"},[_vm._v(_vm._s(_vm.$t('ping.vkcGxcTjpTxToo35QMcdt')))]),_c('section',{staticClass:"describe-text"},[_vm._v(_vm._s(_vm.$t('ping.wwzBWipPz3nkVivzStLf')))]),_c('section',{staticClass:"describe-text"},[_vm._v(_vm._s(_vm.$t('ping.zHemiM0V6bl0gEOcwbfXc')))]),_c('section',{staticClass:"describe-text"},[_vm._v(_vm._s(_vm.$t('ping.xVfFbaIBtklnflZxR7e8')))])]),_c('div',{staticClass:"button-box"},[_c('div',{staticClass:"commit-button",class:{
				'commit-button--disable': _vm.pinging
			},on:{"click":function($event){return _vm.handlePingServer('retest')}}},[_vm._v(_vm._s(_vm.pinging ? _vm.$t('ping.zUOuajK9Vrj7wRdNtyr') : _vm.$t('ping.lskdjocwlkalskALFJKD')))]),_c('div',{staticClass:"button-tips",on:{"click":_vm.toFeedback}},[_vm._v(_vm._s(_vm.$t('ping.vEkphatLOtyJjGhNDyPw')))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }