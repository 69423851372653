<template>
	<div 
		class="invite-button" 
		:class="{ 'is-plain': plain }"
		@click="handleClick"
	>
		<slot />
	</div>
</template>

<script>
export default {
	name: "InviteButton",
	props: ['plain'],
	methods: {
		handleClick() {
			this.$emit('click');
		}
	}
}
</script>

<style lang="less" scoped>
.invite-button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 162px;
	line-height: 1.2em;
	color: #fff;
	font-size: 60px;
	text-align: center;
	background: linear-gradient(91.65deg, #306EFF 5.59%, #7059FF 110.96%);
	border-radius: 24px;
	padding: 0 40px;
	font-weight: 600;
}

.invite-button.is-plain {
	color: #306EFF;
	border: 3px solid #306EFF;
	background: transparent;
	box-sizing: border-box;
}
</style>