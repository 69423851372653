<template>
  <div class="middleware">
    <div class="main-content">
      <SubscribeBanner 
        v-if="isSubscribe" 
        :subscribeThumbnail="subscribeThumbnail"
        :subscribeChannelName="subscribeChannelName"
      />
      <NormalBanner 
        v-else 
        :resourceName="resourceName"
        :resourceThumbnail="resourceThumbnail"
      />

      <InstallChannel :dataReport="dataReport" />

      <div v-if="showSendFileTextButton" class="open-telegram-tips">
        {{ $t("middleware.authorized") }}
        <a
          :href="toTelegramLink"
          class="button"
          @click="dataReport('bot')"
        >
          {{ $t("middleware.sendFile") }}
        </a>
      </div>
      <div
        v-if="!isPC || isIPad"
        @click="callApp('downloaded')"
        class="downloaded-open"
        style="cursor: pointer"
      >
        {{ $t("middleware.googlePlay") }}
      </div>
    <IntroduceBlock :callApp="callApp" />
    </div>
    <div v-if="forbidden" class="forbidden-popup">
      <Forbidden :userIP="userIP"></Forbidden>
    </div>

    <QRCode />
  </div>
</template>

<script>
import { isAndroid, isPC, isIPad } from "../utils/util";
import platform from "platform";
import { getThumbnail, isForbiddenIP } from "../api/index";
import IntroduceBlock from "../components/toapp/IntroduceBlock.vue";
import Forbidden from "./Forbidden.vue";
import NormalBanner from "../components/toapp/NormalBanner.vue";
import SubscribeBanner from "../components/toapp/SubscribeBanner.vue";
import InstallChannel from "../components/toapp/InstallChannel.vue";
import QRCode from "../components/toapp/QRCode.vue";
import { 
  parseUrlParams, 
  getDeepLink, 
  subscribeInfoMapping, 
} from "../utils/toapp";
import { enableIosSmartAppBanner } from '../utils/util';

export default {
  name: "Middleware",
  components: {
    QRCode,
    Forbidden,
    IntroduceBlock,
    NormalBanner,
    SubscribeBanner,
    InstallChannel,
},
  data() {
    return {
      isIPad,
      timer: null,
      googlePlayLink: "",
      isPC: isPC(),
      resourceName: "",
      resourceThumbnail: "",
      // 用户来源标识, 用于上报
      fromParams: "",
      forbidden: false,
      userIP: '',
      subscribeThumbnail: '',
      subscribeChannelName: '',
    };
  },
  created() {
    this.isForbidden();
    if (this.isSubscribe) {
      const params = parseUrlParams();
      const source = params.__source || params.from;
      const subscribeInfo = subscribeInfoMapping[source];
      this.subscribeThumbnail = subscribeInfo.pic;
      this.subscribeChannelName = subscribeInfo.name;
    }
  },
  mounted() {
    isAndroid && this.callApp();
    this.getSourceInfo();

    try {
      const deepLinkHost = "pikpakapp://mypikpak.com";
      let deepLink = getDeepLink();
      const hostDeepLink = deepLinkHost + deepLink;
      // insert smart app banner
      enableIosSmartAppBanner(hostDeepLink);
    } catch(err) {
      console.error('on mounted error: ', err);
    }
  },
  computed: {
    showSendFileTextButton() {
      // 连接包含磁力链才显示发送文件文字链接
      return (
        /magnet:\?xt/i.test(decodeURIComponent(location.search)) && this.isPC
      );
    },
    toTelegramLink() {
      const { __add_url, __source: source } = parseUrlParams();
      // 如果带磁力链, 解析出其infoHash
      const hash = __add_url.match(/magnet:\?xt=urn:btih:([\w]+)/i)?.[1];
      if (hash && hash.length > 40) {
        alert("当前链接参数非法，无法打开");
      }
      let tgLink = `https://t.me/pikpak_bot?start=magnet_${hash}`;
      if (source) {
        tgLink = tgLink + "_" + source;
      }
      return tgLink;
    },
    clientType() {
      if (/android/i.test(platform.os.family)) {
        return "android";
      }
      if (/OS X/i.test(platform.os.family)) {
        return "mac";
      }
      if (/iOS/i.test(platform.os.family)) {
        return "ios";
      }
      return "pc";
    },
    isSubscribe() {
      const params = parseUrlParams();
      const channel = params.__source || params.from;
      return [
        ...new Array(7).fill(0).map((_, i) => `zy00${12 + i}`),
        ...new Array(9).fill(0).map((_, i) => `zy00${23 + i}`),
        'zy0033',
      ].includes(channel);
    }
  },
  methods: {
    async isForbidden() {
      try {
        const { data } = await isForbiddenIP();
        this.forbidden = data.result === "REJECTED";
        this.userIP = data.data.ip;
        // 界面展示数据上报
        this.dataReport();
      } catch (err) {
        console.error("[isForbidden]", err);
      }
    },
    async getSourceInfo() {
      const params = {};
      const search = decodeURIComponent(location.search);
      const addUrl = search.match(/add_url=([^&]+)/i)?.[1];
      const hash = search.match(/hash=([^&]+)/i)?.[1];
      if (hash) {
        params.hash = hash;
      }
      if (addUrl) {
        params.url = addUrl;
      } 
      if (/\w{32,40}/i.test(hash) && !addUrl) {
        params.url = `magnet:?xt=urn:btih:${hash}`;
      }
      try {
        const {
          data: { name, thumbnail },
        } = await getThumbnail(params);
        this.resourceName = name;
        this.resourceThumbnail = thumbnail; 
      } catch (err) {
        console.error("[get source info error: ]", err);
      }
    },
    dataReport(type = "show") {
      let data = {};
      try {
        data = parseUrlParams();
      } catch (err) {
        console.error("[dataReport]", err);
      }
      // 拼接from参数规则
      let from = data.__source ? `${data.__source}/page` : `other/page`;
      if (data.__campaign) {
        from = from + "/" + data.__campaign;
      }
      if (type === "show") {
        // 上报的数据
        const rData = {
          tguid: data.uid,
          token: data.token,
          type: data.type,
          from: decodeURIComponent(data.from || from),
          client_type: this.clientType,
          is_china_ip: this.forbidden ? "1" : "0",
          user_agent: navigator.userAgent,
        };
        if (data.result) {
          rData.result = data.result;
        }
        if (["android", "ios"].includes(this.clientType)) {
          rData.is_download_btn_show = this.showDownloadAPKButton
            ? "yes"
            : "no";
        }
        this.$stat("external_h5", "guid_install_app_page_show", rData);
      } else {
        const rData = {
          tguid: data.uid,
          token: data.token,
          type: data.type,
          from: decodeURIComponent(data.from || from),
          client_type: this.clientType,
          button: type,
          result: data.result,
        };
        if (data.result) {
          rData.result = data.result;
        }
        if (["android", "ios"].includes(this.clientType)) {
          rData.is_download_btn_click = this.showDownloadAPKButton
            ? "yes"
            : "no";
        }
        this.$stat("external_h5", "guid_install_app_page_click", rData);
      }
    },
    callApp(clickType) {
      // 数据上报
      clickType && this.dataReport(clickType);
      const deepLinkHost = "pikpakapp://mypikpak.com";
      let deepLink = getDeepLink();
      const hostDeepLink = deepLinkHost + deepLink;
      window.location.href = hostDeepLink;
    }, 
  },
};
</script>

<style scoped lang="less">
.middleware {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  border: 1px solid transparent;
  box-sizing: border-box;
  background: url('../assets/images/toapp/mobile-background.png') no-repeat center/cover;
  text-align: center;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  .open-telegram-tips {
    margin-top: 24PX;
    margin-left: -40PX;
    font-size: 18PX;
    text-align: center;
  
    a {
      position: relative;
      color: #306EFF;
  
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: -30PX;
        width: 24PX;
        height: 24PX;
        transform: translateY(-50%);
        background: url('../assets/images/toapp/telegram.png') no-repeat center/cover;
        z-index: 1;
      }
    }
  }

  .downloaded-open {
    font-size: 14PX;
    margin-top: 12PX;
    color: #306EFF;
  }
}

.forbidden-popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999 !important;
  background: #fff;
}
</style>