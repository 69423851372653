<template>
	<div v-if="visible" class="award-message">
		<div class="award-message-title">{{ $t("invitation.inviteAwardTitle") }}</div>
		<div class="award-message-content">
			<div class="award-message-icon"></div>
			<div>{{ awardDays }}{{ $t("invitation.day") }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AwardMessage',
	data() {
		return {
			awardDays: 0,
			visible: false,
		}
	},
	watch: {
		visible(n) {
			if (n) {
				this.timer = setTimeout(() => {
					this.visible = false;
					clearTimeout(this.timer);
					this.timer = null;
				}, 3000);
			}
		}
	},
	methods: {
		show(days) {
			if (days) {
				this.awardDays = days;
			}
			this.visible = true;
		},
		hide() {
			this.visible = false;
		}
	}
}
</script>

<style lang="less" scoped>
.award-message {
	position: fixed;
	z-index: 20;
	width: 458px;
	height: 458px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	padding-top: 60px;
	background: #222222;
	mix-blend-mode: normal;
	opacity: 0.9;
	border-radius: 30px;
	box-sizing: border-box;
	text-align: center;

	&-title {
		font-family: PingFang SC;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 18px;
		color: #FFFFFF;
	}

	&-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 80px;
		font-family: PingFang SC;
		font-style: normal;
		font-weight: 600;
		font-size: 51px;
		line-height: 51px;
		text-align: center;
		color: #D1AE6A;
	}

	&-icon {
		width: 125px;
		height: 125px;
		margin-bottom: 12px;
		background: url('../../assets/images/invitation/king.png') no-repeat center/cover;
	}
}
</style>