<template>
	<div class="invitation-page">
		<div class="invitation-page-content" style="overflow-x: hidden;">
			<div class="header">
				<div class="title">{{ $t("invitation.inviteAward") }}</div>
				<div class="subtitle">{{ $t("invitation.tenAndInfinite") }}</div>
			</div>
			<div class="banner">
				<img src="../../assets/images/invitation/banner.png" alt="banner" />
			</div>
			<!-- 邀请好友奖励介绍 -->
			<div class="gift-title">
				<img src="../../assets/images/invitation/gift.png" alt="gift">
				<span>{{ $t('invitation.mTNofZNzPUeAwncWpNPvL') }}</span>
			</div>
			<div class="invite-card-wrapper">
				<FirstInviteIntroduce v-if="isFirstInvite" />
				<NotFirstInviteIntroduce 
					v-else 
					:startSuccessNumCount="startSuccessNumCount"
					:startVipDayCount="startVipDayCount"
					:startSuccessDayCount="startSuccessDayCount"	
					:startVipNumCount="startVipNumCount"
					:invitationInfo="invitationInfo"
				/>
				<lottie-player 
					v-if="showGuideAnimation"
					:src="lottieDataSrc"  
					class="slide-to-invite"
					background="transparent"  
					speed="1"  
					loop 
					autoplay
				></lottie-player>
			</div>
			<!-- 查看邀请记录 -->
			<div @click="showInvitedList" class="check-invited-info">
				{{ $t('invitation.lookVipDataInfo') }} >
			</div>
			<!-- 多种邀请方式 -->
			<InvitedMethod 
				:userInfo="userInfo"
				:inviteCode="inviteCode"
				:invitedNum="invitationInfo.invited_nums"
			/>	
			<!-- 规则描述 -->
			<RulesDescribeBlock />
			<!-- 输入邀请码 -->
			<ExchangeInviteCode 
				:code="inviterCode"
				:auto-input-code="autoInputCode"
				:userInfo="userInfo"
				:inviterCode="inviterCode"	
				:invitedNum="invitationInfo.invited_nums"
			/>
			<div v-if="iosPlatform" class="apple-tips">{{ $t('invitation.appleDisclaimer') }}</div>
		</div>
		<award-message ref="awardMessage" />
		<invited-list :invitedList="invitedList" ref="InvitedList" @requestList="handleRequestList" />
	</div>
</template>

<script>
import dayjs from 'dayjs';
import { resize } from '../../utils/util.js';
import { getUserInfo, showTopBarShareIcon } from '../../utils/jsBridge.js';
import '@lottiefiles/lottie-player';
import { 
	queryUserInvitationInfo, 
	getInviteCode, 
	getInvitedList,
	queryInviterCode,
} from '../../api/index.js';
import { i18n } from '../../main';
import AwardMessage from '../../components/activity/award-message.vue';
import InvitedList from '../../components/activity/invited-list.vue';
import FirstInviteIntroduce from '../../components/activity/first-invite-introduce.vue';
import NotFirstInviteIntroduce from '../../components/activity/not-first-invite.vue';
import InvitedMethod from '../../components/activity/invited-methods.vue';
import ExchangeInviteCode from '../../components/activity/exchange-invite-code.vue';
import RulesDescribeBlock from '../../components/activity/rules-describe-block.vue';
import { 
	getInfoFromLocalStore, 
	saveInfoToLocalStore, 
} from './invitation.common';
import { isIOS } from '../../utils/util.js';

export default {
	name: "InvitationPage",
	components: {
		AwardMessage,
		FirstInviteIntroduce,
		NotFirstInviteIntroduce,
		InvitedMethod,
		InvitedList,
		ExchangeInviteCode,
		RulesDescribeBlock,
	},
	data() {
		return {
			startSuccessNumCount: 0,
			startSuccessDayCount: 0,
			startVipNumCount: 0,
			startVipDayCount: 0,
			// 判断是否为GP的包
			isGPPackage: true,
			invitationInfo: {
				invited_days: 0, // 因邀请成功获得的会员天数
				invited_nums: 0, // 邀请成功人数
				invited_join_days: 0, // 因邀请成功好友开通会员获得的天数
				join_vip_nums: 0, // 邀请成功的好友中开通会员的人数
			},
			invitedList: [],
			awardTypeMapping: {
				default: this.$t('invitation.vipDivided'),
				vip_rebate: this.$t('invitation.vipDivided'),
				invite_reward: this.$t('invitation.inviteAwardTitle'),
			},
			token: '',
			language: '',
			userInfo: {},
			inviteCode: '',
			shareCHALink: '',
			shareH5Link: '',
			// 邀请者的inviteCode
			inviterCode: '',
			showGuideAnimation: true,
			autoInputCode: '',
		}
	},
	computed: {
		iosPlatform() {
			return isIOS;
		},
		isFirstInvite() {
			return this.invitationInfo.invited_nums <= 0;
		},
		lottieDataSrc() {
			return `/lottie-slide/${i18n.locale}.json`
		},
	},
	async mounted() {
		document.title = this.$t('invitation.invitationPageTitle');
		const { code } = this.parseParams();
		this.autoInputCode = code;

		const currentHTMLFontSize = parseInt(window.getComputedStyle(document.querySelector('html'))['fontSize']);
		if (currentHTMLFontSize >= 95) {
			document.querySelector('html').style.fontSize = '40px';
		}

		const invitationPageDom = document.querySelector('.invitation-page');
		invitationPageDom.addEventListener('scroll', () => {
			if (invitationPageDom.scrollTop > 230) {
				this.showGuideAnimation = false;
			}
		});

		process.env.NODE_ENV === 'development' && showTopBarShareIcon({
			content: window.location.href,
		}, () => {});

		try {
			await this.getUserInfoWithJSBridge(),
			await this.init();
			this.queryInviterCode(),
			this.getInviteCode(),
			await this.handleRequestList(0);
		} catch(err) {
			console.error('on mounted network request error:', err);
		}
	},
	methods: {
		parseParams() {
			const ps = new URLSearchParams(window.location.search);
			const code = ps.get('code') || '';
			return { code };
		},
		async init() {
			try {
				resize();
				const { accessToken: token, language, userID, productFlavorGp } = await this.getUserInfoWithJSBridge();
				this.userId = userID;
				this.isGPPackage = isIOS ? false : productFlavorGp.toLowerCase() !== 'cha';
				this.token = token;
				this.language = language;

				const [
					invitationInfo, 
					invitedList
				] = await Promise.all([
					this.queryUserInvitationInfo({ token, language }),
					this.getInvitedList({ token, language })
				]);
				this.invitedList = invitedList;

				// 从localStorage中读取邀请历史信息
				const { invited_days, invited_nums, invited_join_days, join_vip_nums } = getInfoFromLocalStore();
				this.startSuccessNumCount = invited_nums;
				this.startSuccessDayCount = invited_days;
				this.startVipNumCount = join_vip_nums;
				this.startVipDayCount = invited_join_days;

				this.invitationInfo = Object.assign(this.invitationInfo, invitationInfo);
				saveInfoToLocalStore(this.invitationInfo);
				// 展示获得的会员天数
				const distanceSuccess = this.invitationInfo.invited_days - invited_days;
				const distanceVip = this.invitationInfo.invited_join_days - invited_join_days;
				if (distanceSuccess > 0 || distanceVip > 0) {
					const days = distanceSuccess + distanceVip;
					this.$refs['awardMessage'].show(days);
				}

				// 数据上报: 页面展示
				this.$stat("external_js", "spread_invite_page_show", {
					invited_num: invitationInfo.invited_nums || 0,
				});	
			} catch (err) {
				console.error('[init page: ]', err);
			}
		},
		getUserInfoWithJSBridge() {
			// 通过jsBridge获取android的accessToken, 及language信息
			return new Promise(resolve => {
				if (JSON.stringify(this.userInfo) !== '{}') {
					// 如果已经存在数据, 直接返回不需要调用jsBridge
					return resolve(this.userInfo);
				}
				getUserInfo(null, async (data) => {
					this.userInfo = typeof data === 'object' ? data : JSON.parse(data || '{}');
					document.title = this.$t('invitation.invitationPageTitle');
					resolve(this.userInfo);
				});	
			});
		},
		// 获取用户绑定关系中的邀请者code
		queryInviterCode() {
			return new Promise(resolve => {
				if (this.inviterCode) {
					return resolve(this.inviterCode);
				}
				queryInviterCode(this.userInfo.userID)
				.then(({ data: { code }}) => {
					this.inviterCode = code;
					resolve(code);
				});
			});
		},
		// 获取用户的邀请码
		async getInviteCode() {
			return new Promise((resolve, reject) => {
				try {
					if (this.inviteCode) {
						return resolve(this.inviteCode);
					}
					this.getUserInfoWithJSBridge().then(async data => {
						const { accessToken: token, language, uuid: deviceId } = data;
						const { data: { code } } = await getInviteCode({ deviceId, token, language });
						this.inviteCode = code;
					});
				} catch (err) {
					reject(err);
					console.error('[getInviteCode: ]', err);
				}
			});
		},
		// 获取服务端记录的邀请信息
		async handleRequestList(time) {
			try {
				const result = await this.getInvitedList({ 
					token: this.token, 
					language: this.language, 
					begin: time, 
				});
				const existKeys = this.invitedList.map(({ invited_user: iu, time }) => `${iu}${time}`);
				const newList = result.filter(item => {
					const { invited_user: iu, time } = item;
          const key = `${iu}${time}`;
          return !existKeys.includes(key);
				});
				this.invitedList = [...this.invitedList, ...newList];
			} catch(err) {
				console.error('[handleRequestList: ]', err);
			}
		},
		// 获取用户邀请信息
		async queryUserInvitationInfo({token, language}) {
			try {
				const { data } = await queryUserInvitationInfo({ token, language });
				return data; 	
			} catch(err) {
				console.error('[queryUserInvitationInfo: ]', err);	
				return {};
			}
		},
		// 获取用户邀请成功好友列表
		async getInvitedList({ token, language, begin }) {
			try {
				const { data } = await getInvitedList({ token, language, begin });
				return data.data.map((item) => {
					if (!item.invited_user) {
						item.invited_user = '-';
					}
					item.timeString = dayjs(item.time).format('YYYY.MM.DD');
					item.reward_type = this.awardTypeMapping[item.reward_source] || this.awardTypeMapping["default"];
					return item;
				});
			} catch(err) {
				console.error('[getInvitedList: ]', err);
				return [];
			}
		},
		// 查看成功邀请列表
		async showInvitedList() {
			try {
				this.$refs['InvitedList'].show();
				// 数据上报
				this.$stat("external_js", "spread_invite_page_click", {
					button: 'invitation_details',
					invited_num: this.invitationInfo.invited_nums || 0,
				});
			} catch(err) {
				console.error('[showInvitedList]', err);
			}
		},
	}
}
</script>

<style lang="less" scoped>
.invitation-page {
	max-width: 948PX;
	margin: 0 auto;
	height: 100vh;
	overflow-y: scroll;
	box-sizing: border-box;

	&-content {
		position: relative;
		padding: 90px 0;
		min-height: 100vh;
		background: #cde5ff url("../../assets/images/invitation/bg.png") no-repeat
			center/cover;
	}
	.header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		.title {
			padding: 0 20px;
			font-style: normal;
			font-weight: 600;
			font-size: 80px;
			line-height: 80px;
			color: #6776ff;
			margin: 0 auto;
			line-height: 1.1;
			text-align: center;
			box-sizing: border-box;
		}
		.subtitle {
			display: inline-block;
			background: linear-gradient(
				268.57deg,
				rgba(135, 62, 255, 0.9) -32.16%,
				rgba(67, 187, 255, 0.9) 57.46%,
				rgba(67, 115, 255, 0.9) 107.73%
			);
			border-radius: 60px;
			font-style: normal;
			font-weight: normal;
			font-size: 33px;
			line-height: 1.1;
			color: #fff;
			text-align: center;
			margin: 0 auto;
			margin-top: 21px;
			box-sizing: border-box;
			display: inline-block;
			padding: 18px 30px;
		}
	}

	.banner {
		margin-top: 36px;
		text-align: center;

		img {
			width: 775px;
			height: 530px;
		}
	}
	
	.gift-title {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 51px;
		margin: 30px;
		min-height: 96px;
		line-height: 96px;
		margin: 39px 0;
		font-weight: 600;

		img {
			width: 96px;
			height: 96px;
		}

		span {
			max-width: calc(100vw - 200px);
			line-height: 1.2em;
			margin-left: 27px;
		}
	}

	.invite-card-wrapper {
		position: relative;

		.slide-to-invite {
			position: fixed;
			z-index: 2;
			right: 60px;
			bottom: 150px;
			width: 600px;
			height: auto;
			pointer-events: none;
		}
	}

	.invite-info {
		width: 980px;
		border-radius: 48px;
		margin: 0 auto;
		margin-top: 30px;
		background: #fff;
		font-style: normal;
		font-weight: 600;
		font-size: 45px;
		line-height: 75px;
		text-align: center;
		padding: 60px 0;
		text-align: left;

		&__title {
			padding: 0 60px;
			font-size: 39px;
			line-height: 60px;
		}

		.divide-line {
			width: 840px;
			margin: 60px auto;
			border-top: 1px dashed rgba(0, 0, 0, 0.2);
		}

		&__subtitle {
			margin-top: 60px;
			font-style: normal;
			font-weight: normal;
			font-size: 33px;
			line-height: 75px;
			text-align: center;
			color: #98a2ad;
		}

		&__box {
			display: flex;
			height: 150px;
			margin-top: 48px;
			padding: 0 80px;
			padding-right: 60px;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			font-size: 38px;
			font-size: normal;
			line-height: 1.8em;
			font-weight: 400;

			.split {
				width: 112px;
				border-top: 0.5px dashed rgba(0, 0, 0, 0.2);
				transform: rotate(-60deg);
			}

			&-left {
				color: #98A2AD;
				flex-shrink: 0;
			}

			&-right {
				min-width: 450px;
			}

			&-left__title {
				font-size: 84px;
				margin-bottom: 8px;
				color: #306EFF;
			}

			.bold-blue {
				color: #306EFF;
			}

			& + .divide-line {
				margin: 60px auto;
			}
		}

		&__day {
			font-weight: 600;
			font-size: 96px;
			line-height: 75px;
			text-align: center;
			color: #306eff;
		}

		.invite-info-button {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-style: normal;
			width: 890px;
			height: 160px;
			border-radius: 24px;
			margin: 0 auto;
			background: linear-gradient(91.65deg, #306eff 5.59%, #7059ff 110.96%);
			padding: 24px 0;
			margin-top: 24px;
			box-sizing: border-box;

			&__title {
				font-weight: 600;
				font-size: 60px;
				line-height: 1em;
				text-align: center;
				color: #ffffff;
				margin-bottom: 10px;
			}

			&__subtitle {
				font-size: 33px;
				line-height: 1em;
				text-align: center;
				font-weight: normal;
				color: rgba(255, 255, 255, 0.6);
			}

			.lottie-point {
				pointer-events: none;
			}
		}
	}

	.check-invited-info {
		position: relative;
		font-size: 39px;
		color: #306EFF;
		text-align: center;
		margin-top: 24px;
	}	

	.apple-tips {
		margin-top: 24PX;
		font-size: 12PX;
		color: #666;
		text-align: center;
	}
}

@media screen and (min-width: 948PX) {
	.invite-card-wrapper {
		position: relative;

		.slide-to-invite {
			position: fixed;
			z-index: 2;
			left: calc(50% + 100PX);
			bottom: 120px;
			width: 600px;
			height: auto;
			pointer-events: none;
		}
	}	
}
</style>